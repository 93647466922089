import styled from "styled-components";

export const ResourcesNav = styled.div`
  display: flex;
  justify-content: space-between;
  justify-content: center;
  width: 100%;
`;

export const ResourcesRow = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: start;
    text-align: start;
    margin-top: 0vh;
    @media screen and (max-width: 1100px) {
        margin-inline: 1vw;
    }
    z-index: 1;
`;

export const ResourcesTitle = styled.div`
    color: #0071CE;
    font-size: 0.9vw;
    margin-bottom: 10%;
    &:hover {
        opacity: 0.7;
    }
`;

export const ResourcesBody = styled.div`
    color: #565656;
    font-size: 0.9vw;
    font-style: normal;
    font-weight: 400;
`;

export const ResourceCol = styled.div`
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: flex-start;
	align-content: stretch;
`;

export const ResourcesContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: start;
    background: #F0F0F0;
    border-radius: 15px;
    padding: 1%;
`;