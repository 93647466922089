import { useEffect } from "react";
import { PTextHead, PrivacyParagrapgh, PrivacyPolicyBackground, PrivacyTitle } from "../privacyPolicy/PrivacyPolicyElements";
import { HrefLink } from "../contactUs/ContactUsElement";

const CookiePolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return <PrivacyPolicyBackground>
        <PTextHead>
            Cookie Policy
        </PTextHead>
        <div>
            <PrivacyParagrapgh>
                Last Updated: 12 October, 2023
            </PrivacyParagrapgh>
            <PrivacyParagrapgh>
                1. Introduction
            </PrivacyParagrapgh>
            <PrivacyParagrapgh>
                Welcome to Potech ("we," "us," or "our"). This Cookie Policy explains how we use cookies and similar tracking technologies on our website. By using our website, you consent to the use of cookies as described in this policy.
            </PrivacyParagrapgh>
            <PrivacyParagrapgh>2. What Are Cookies?</PrivacyParagrapgh>
            <PrivacyParagrapgh>
                Cookies are small text files that are placed on your computer or device when you visit a website. They are widely used to make websites work or to work more efficiently, as well as to provide information to website owners.
            </PrivacyParagrapgh>
            <PrivacyParagrapgh>
                3. Types of Cookies We Use
            </PrivacyParagrapgh><PrivacyParagrapgh>
                We use the following types of cookies on our website:</PrivacyParagrapgh>

            <PrivacyParagrapgh>. Essential Cookies: These cookies are necessary for the website to function properly. They enable basic functions like page navigation and access to secure areas of the website. Our website cannot function properly without these cookies.</PrivacyParagrapgh>
            <PrivacyParagrapgh>. Analytical/Performance Cookies: These cookies allow us to analyze how visitors use our website, which helps us improve its performance. These cookies collect information in an anonymous form.</PrivacyParagrapgh>
            <PrivacyParagrapgh>. Functionality Cookies: These cookies enable us to remember your preferences and choices you make on our website (e.g., language or region you are in) and provide enhanced, more personalized features.</PrivacyParagrapgh>
            <PrivacyParagrapgh>. Targeting/Advertising Cookies: These cookies are used to deliver content and ads that are more relevant to you and your interests. They may also be used to limit the number of times you see an advertisement and measure the effectiveness of advertising campaigns.</PrivacyParagrapgh>

            <PrivacyParagrapgh>4. How We Use Cookies</PrivacyParagrapgh>

            <PrivacyParagrapgh>We use cookies for the following purposes:</PrivacyParagrapgh>

            <PrivacyParagrapgh>. To provide and improve our website's functionality and performance.</PrivacyParagrapgh>
            <PrivacyParagrapgh>. To understand how you interact with our website and to enhance your user experience.</PrivacyParagrapgh>
            <PrivacyParagrapgh>. To tailor our advertising to your interests and display relevant content.</PrivacyParagrapgh>

            <PrivacyParagrapgh>5. Managing Your Cookie Preferences</PrivacyParagrapgh>

            <PrivacyParagrapgh>You have the option to manage your cookie preferences. You can block or delete cookies through your browser settings. Please note that blocking or deleting cookies may affect the functionality of our website.</PrivacyParagrapgh>

            <PrivacyParagrapgh>6. Changes to This Policy</PrivacyParagrapgh>

            <PrivacyParagrapgh>We may update this Cookie Policy to reflect changes in our practices or for other operational, legal, or regulatory reasons. Any changes to this policy will be posted on this page with a revised "Last Updated" date.</PrivacyParagrapgh>

            <PrivacyParagrapgh>7. Contact Us</PrivacyParagrapgh>

            <PrivacyParagrapgh>If you have any questions about our use of cookies or this Cookie Policy, please contact us at <HrefLink href="mailto:info@potech.global">[info@potech.global]</HrefLink>.</PrivacyParagrapgh>

            <PrivacyParagrapgh>8. Additional Resources</PrivacyParagrapgh>

            <PrivacyParagrapgh>For more information about cookies and your rights regarding personal data, please refer to our <HrefLink href="/privacy_notice" target="_blank">Privacy Notice</HrefLink>.</PrivacyParagrapgh>
        </div>
    </PrivacyPolicyBackground>
}

export default CookiePolicy;