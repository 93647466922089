import { useRef, useState } from "react";
import VigilanceCard from '../../components/VigilanceCard';
import { AdvisoryBackground, AdvisoryBodyMobile, AdvisoryBodyMobileImg, AdvisoryButton, AdvisoryCover, AdvisoryDivBodyItemsMobile, AdvisoryDivHeaderItemsMobile, AdvisoryDivItemsMobile, AdvisoryDivLineItemsMobile, AdvisoryImg, AdvisoryTextBody, AdvisoryTextItemsMobile, AdvisoryTextTitle, AdvisoryTitle, Author, CornImg, CoverBody, CoverDiv, CoverIcon, CoverIconContainer, CoverTitle, DefenseBackground, DefenseBody, DefenseCol, DefenseImg, DefenseRow, FooterBackground, FooterImg, FooterTitle, ImageAdvisoryItemsMobile, PotechHistoryFooterAdvisory, PotechHistoryFooterAdvisoryImage, PotechHistoryFooterNewLine, PotechQuoteFooterAdvisory, StrategyBackground, StrategyImg, VigilanceBackground, VigilanceBody, VigilanceBodyHead, VigilanceBodyHeadText, VigilanceBodyText, VigilanceCardContainer, VigilanceEyeIcon, VigilanceImage, VigilanceImageDesc, VigilanceImageDescBody, VigilanceImageDescTitle, VigilanceImg, VigilancePrimeEyeIcon, VigilanceRow } from "./AdvisoryElement";
import { useEffect } from "react";

import VIcon from '../../assets/logos/vigilanceIcon.png';
import DIcon from '../../assets/logos/defenseIcon.png';
import SIcon from '../../assets/logos/strategyIcon.png';
import RIcon from '../../assets/logos/resilienceIcon.png';

import vigilance1 from '../../assets/images/strategyImage.jpg';
import vigilance2 from '../../assets/images/defenseImage.jpeg';
import vigilance3 from '../../assets/images/vigilance2.png';
import vigilance4 from '../../assets/images/resilienceImage.jpeg';
import vigilanceEyeIcon from '../../assets/logos/vigilanceEyeIcon.png';

import defenseIcon from '../../assets/logos/DefenseHeadIcon.png';

import strategyIcon from '../../assets/logos/strategyHeadIcon.png';

import resilienceIcon from '../../assets/logos/resilienceHeadIcon.png';

import potechCons from '../../assets/logos/potechConsulting.svg'
import FooterDivider from "../../components/FooterDivider";

import scrollTopIcon from "../../assets/images/ScrolltoTopIcon.png";
import chatIcon from "../../assets/images/chaticon.png";
import { ChatIcon, CornMobile, ScrollTop } from "../home/HomeElements";

import { useDispatch, useSelector } from 'react-redux';
import { resetTargetSection, setTargetSection } from "../../redux/scrollSlice";
import { ModuleBorderWrap, ModuleVigilanceBorderWrap, ModuleVigilanceBorderWrapBody, ModuleVigilanceBorderWrapBodyText } from "../../components/VigilanceCard/VigilanceCardElements";
import AdvisoryCardMobile from "../../components/AdvisoryCardMobile";
import { useMediaQuery } from "react-responsive";
import { FooterDarkivoreBackground } from "../darkivore/DarkivoreElements";
import { ADVISORY_CARD_TYPES } from "../../common/constant";
import { Loader, WrapperDiv } from "../../components/loader";
import { Helmet } from "react-helmet";

const Advisory = ({ isScrolled }) => {

    const vigilanceRef = useRef(null);
    const defenseRef = useRef(null);
    const strategyRef = useRef(null);
    const resilienceRef = useRef(null);

    const refs = {};

    const [strategyIndex, setStrategyIndex] = useState(null);
    const [sText, setSText] = useState(null);

    const [defenseIndex, setDefenseIndex] = useState(null);
    const [dText, setDText] = useState(null);

    const [vigilanceIndex, setVigilanceIndex] = useState(null);
    const [vText, setVText] = useState(null);

    const [resilienceIndex, setResilienceIndex] = useState(null);
    const [rText, setRText] = useState(null);

    const targetSection = useSelector((state) => state.scroll.targetSection);
    const targetCard = useSelector((state) => state.scroll.targetCard);
    const dispatch = useDispatch();
    // strategy Texts
    const StrategyAchieveText = <div>
        <div>Our expertise in international standards (e.g., NIST CSF, ISO27000 suite) helps identifying and addressing any gaps in businesses’ security posture.</div>
        <AdvisoryDivLineItemsMobile />
        <div>We provide detailed assessments, identify breaches, recommend best practices, and draw best fit roadmaps for achieving compliance.</div>
    </div>

    const StrategyDataText = <div>
        We help organizations navigate the complexities of data privacy regulations such as GDPR and ensure that they adhere to them.
        <AdvisoryDivLineItemsMobile />
        Our exercises cover local & international regulations, identify risks & mishaps, and provide solutions for optimal data protection.
    </div>

    const StrategyScenarioText = <div>
        We provide a complete overview of contextual risks, their relevant mitigation measures, and how companies can respond to them efficiently.
        <AdvisoryDivLineItemsMobile />
        That’s done by establishing a risk heatmap with actionable roadmaps.
        <AdvisoryDivLineItemsMobile />
        Our approach helps clients increase their visibility and prepare for any eventualities.
        <AdvisoryDivLineItemsMobile />
        This is particularly featured through our AI Risk Assessment service.
    </div>

    const StrategyVirtualText = <div>
        That’s what we are!
        <AdvisoryDivLineItemsMobile />
        Our team of certified professionals have long years of experience in providing comprehensive support and guidance through
        the entire process of setting up strong cyber defenses – from assessing the current state of security to rolling out the
        appropriate solutions in full agility.
    </div>

    // defense Texts
    const DefenseDirectoryText = <div>
        We review systems, user accounts and defined group policies while conducting a security evaluation to identify any potential misconfigurations and security weaknesses.
    </div>

    const DefenseInfraText = <div>
        We execute a security review on the IT and cloud infrastructure. We analyze the effectiveness of all implemented security controls, and their compliance with information security best practices & internal policies.
    </div>

    const DefenseDatabaseText = <div>
        We provide a technical security assessment of all types of databases and execute a review of the different servers, making sure that all best practices are applied.
    </div>

    const DefenseUserText = <div>
        We verify user accounts, their proper definition and configuration throughout systems and applications. A review of the user access, role, and permissions is also conducted to ensure that confidentiality and integrity norms are being respected.
    </div>

    // vigilance Texts
    const VigilanceTargetedText = <div>
        Our team of experts works closely with you to define the exact scope of each project — be it for mobile, web, APIs, IOT, systems, networks, or ICS/OT.
        <AdvisoryDivLineItemsMobile />
        We bring the best in automated and manual vulnerability discovery, testing and exploitation, ensuring that all potential risks are identified and remediated.
    </div>

    const VigilanceRedText = <div>
        The Red Team exercises draw a true image of your current security stance.
        <AdvisoryDivLineItemsMobile />
        We simulate non-targeted, best-effort cyberattacks, giving you a realistic visibility of your security posture and keeping you ahead of any direct threats.
        <AdvisoryDivLineItemsMobile />
        By building an attacker's profile and simulating attacks against your whole environment, we prioritize risks and develop the appropriate security measures.
        <AdvisoryDivLineItemsMobile />
        “Red Team Agile” is one of our finest crafts, offered on retainer mode. Our Red Team performs agile & continuous testing on your system, upon each change or new release.
        <AdvisoryDivLineItemsMobile />
        Threat modeling and security checks are fully integrated into your Quality Assurance and User Acceptance Testing processes.
    </div>

    const VigilanceSourceText = <div>
        We provide a comprehensive analysis of your system’s source code, detecting vulnerabilities and malicious code.
        <AdvisoryDivLineItemsMobile />
        Our tailored solutions and manual checks allow the identification of all existing issues before they are pushed to production.
    </div>

    const VigilanceRansomwareText = <div>
        We could say that this is our most lethal type of attacks!
        <AdvisoryDivLineItemsMobile />
        We simulate benign & unseen prototypes, to assess your level of readiness for all types of malwares extorsions and advise on the best defenses against them.
        <AdvisoryDivLineItemsMobile />
        On top of that, our team evaluates your existing infrastructure and processes to minimize impact in case of breach.
    </div>

    // resilience Texts
    const ResilienceIncident = <div>
        Our team swiftly mobilizes efficient first responder procedures to stop the bleeding at the earliest stage of a cyberattack.
        <AdvisoryDivLineItemsMobile />
        In parallel, we develop, implement, and simulate incident response plans with granular processes for detecting, analyzing, and responding to potential cyber incidents.
        <AdvisoryDivLineItemsMobile />
        We simulate benign & unseen prototypes, to assess your level of readiness for all types of malwares extortions and advise on the best defenses against them.
    </div>

    const ResilienceDigital = <div>
        Our Digital Forensics methodology complements our Incident Response cycle by acquiring and securing digital evidence, analyzing the kill chain, and identifying the root cause and scope of a cyberattack.
        <AdvisoryDivLineItemsMobile />
        It will detail how an attack occurred, what data may have been compromised, and how to prevent similar incidents in the future.
        <AdvisoryDivLineItemsMobile />
        This methodology is enriched by our hands-on expertise, in accordance with the latest standards and guidelines.
    </div>

    const ResilienceBusiness = <div>
        We develop and implement a comprehensive business continuity plan to ensure the continuity of critical business functions in the event of a disruption.
        <AdvisoryDivLineItemsMobile />
        This plan provides quick recovery from cyberattacks or other disasters, minimizing impact on business operations and brand reputation.
    </div>

    const ResilienceCyber = <div>
        Our dedicated organizational and technical assessments optimize the coverage and scope of cyber liability insurance policies.
        <AdvisoryDivLineItemsMobile />
        These exercises improve your posture and map the needed cyber liability policies, while preventing you from any operational damage and financial losses that may arise as a result of cyber incidents.
    </div>
    useEffect(() => {
        if (targetSection === null) {
            window.scrollTo(0, 0);
        }
    }, [])
    // this[ref]= React.createRef()
    const scroll = () => {
        const section = document.querySelector('#contact-us');
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };
    useEffect(() => {
        let sectionRef;

        switch (targetSection) {
            case 'vigilance':
                sectionRef = vigilanceRef;
                break;
            case 'defense':
                sectionRef = defenseRef;
                break;
            case 'strategy':
                sectionRef = strategyRef;
                break;
            case 'resilience':
                sectionRef = resilienceRef;
                break;
            default:
                sectionRef = null;
                break;
        }

        if (sectionRef && sectionRef.current) {
            const yOffset = -100;
            const y = sectionRef.current.getBoundingClientRect().top + window.scrollY + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            dispatch(resetTargetSection());
        }
    }, [targetSection, dispatch]);

    useEffect(() => {

        switch (targetCard) {
            // strategy cards
            case ADVISORY_CARD_TYPES.STRATEGY_ACHIEVE:
                setStrategyIndex(1);
                setSText(StrategyAchieveText);
                break;

            case ADVISORY_CARD_TYPES.STRATEGY_DATA:
                setStrategyIndex(2);
                setSText(StrategyDataText);
                break;

            case ADVISORY_CARD_TYPES.STRATEGY_SCENARIO:
                setStrategyIndex(3);
                setSText(StrategyScenarioText);
                break;

            case ADVISORY_CARD_TYPES.STRATEGY_VIRTUAL:
                setStrategyIndex(4);
                setSText(StrategyVirtualText);
                break;

            // defense cards
            case ADVISORY_CARD_TYPES.DEFENSE_DIRECTORY:
                setDefenseIndex(1);
                setDText(DefenseDirectoryText);
                break;

            case ADVISORY_CARD_TYPES.DEFENSE_INFRASTRUCTURE:
                setDefenseIndex(2);
                setDText(DefenseInfraText);
                break;

            case ADVISORY_CARD_TYPES.DEFENSE_DATABASE:
                setDefenseIndex(3);
                setDText(DefenseDatabaseText);
                break;

            case ADVISORY_CARD_TYPES.DEFENSE_USER:
                setDefenseIndex(4);
                setDText(DefenseUserText);
                break;

            // vigilance
            case ADVISORY_CARD_TYPES.VIGILANCE_TARGETED:
                setVigilanceIndex(1);
                setVText(VigilanceTargetedText);
                break;

            case ADVISORY_CARD_TYPES.VIGILANCE_RED:
                setVigilanceIndex(2);
                setVText(VigilanceRedText);
                break;

            case ADVISORY_CARD_TYPES.VIGILANCE_SOURCE:
                setVigilanceIndex(3);
                setVText(VigilanceSourceText);
                break;

            case ADVISORY_CARD_TYPES.VIGILANCE_RANSOMWARE:
                setVigilanceIndex(4);
                setVText(VigilanceRansomwareText);
                break;

            // resilience
            case ADVISORY_CARD_TYPES.RESILIENCE_INCIDENT:
                setResilienceIndex(1);
                setRText(ResilienceIncident);
                break;

            case ADVISORY_CARD_TYPES.RESILIENCE_DIGITAL:
                setResilienceIndex(2);
                setRText(ResilienceDigital);
                break;

            case ADVISORY_CARD_TYPES.RESILIENCE_BUSINESS:
                setResilienceIndex(3);
                setRText(ResilienceBusiness);
                break;

            case ADVISORY_CARD_TYPES.RESILIENCE_CYBER:
                setResilienceIndex(4);
                setRText(ResilienceCyber);
                break;

            default:
                break;
        }

        if (targetCard) {
            dispatch(resetTargetSection());
        }
    }, [targetCard, dispatch]);

    const isMobile = useMediaQuery({ query: `(max-width: 800px)` });

    const StrategyItems = <>
        <WrapperDiv>
            <Loader marginTop="12vh" />
            <ImageAdvisoryItemsMobile src={vigilance1} />
        </WrapperDiv>


        <AdvisoryTextItemsMobile>
            We lead clients in creating an effective cybersecurity strategy that sets a clear roadmap for their organization, optimizing their posture to the best adapted standards whether NIST, ISO27000 suite, or GDPR.
            <AdvisoryDivLineItemsMobile />
            We guide them in their cyber journey, protecting their data, systems, and digital assets.
        </AdvisoryTextItemsMobile>

        <AdvisoryDivItemsMobile>
            <AdvisoryDivHeaderItemsMobile>
                Achieve Cybersecurity Maturity
            </AdvisoryDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {StrategyAchieveText}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>

        <AdvisoryDivItemsMobile>
            <AdvisoryDivHeaderItemsMobile>
                Data Privacy Assessment
            </AdvisoryDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {StrategyDataText}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>
        <AdvisoryDivItemsMobile>
            <AdvisoryDivHeaderItemsMobile>
                Scenario-Based Risk Assessments
            </AdvisoryDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {StrategyScenarioText}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>
        <AdvisoryDivItemsMobile>
            <AdvisoryDivHeaderItemsMobile>
                Virtual CISO
            </AdvisoryDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {StrategyVirtualText}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>
    </>

    const DefenseItems = <>
        <WrapperDiv>
            <Loader marginTop="12vh" />
            <ImageAdvisoryItemsMobile src={vigilance2} />
        </WrapperDiv>

        <AdvisoryTextItemsMobile>
            A solid cyber defense through our in-depth security approach, remains our clients’ best bet against any malicious actors and cyberthreats.
        </AdvisoryTextItemsMobile>

        <AdvisoryDivItemsMobile>
            <AdvisoryDivHeaderItemsMobile>
                Directory Services Security Review
            </AdvisoryDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {DefenseDirectoryText}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>

        <AdvisoryDivItemsMobile>
            <AdvisoryDivHeaderItemsMobile>
                Database Security Assessment
            </AdvisoryDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {DefenseDatabaseText}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>
        <AdvisoryDivItemsMobile>
            <AdvisoryDivHeaderItemsMobile>
                Infrastructure & Cloud Security Assessments
            </AdvisoryDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {DefenseInfraText}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>
        <AdvisoryDivItemsMobile>
            <AdvisoryDivHeaderItemsMobile>
                User Management Security
            </AdvisoryDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {DefenseUserText}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>
    </>

    const VigilanceItems = <>
        <WrapperDiv>
            <Loader marginTop="12vh" />
            <ImageAdvisoryItemsMobile src={vigilance3} />
        </WrapperDiv>

        <AdvisoryTextItemsMobile>
            We keep clients’ environments under our spotlight and their teams on their cyber feet, making sure that they master the latest security trends and best practices in order to preemptively identify and neutralize any threats to their business, ranging from Red Team Exercises, to targeted penetration tests & source code review.
        </AdvisoryTextItemsMobile>

        <AdvisoryDivItemsMobile>
            <AdvisoryDivHeaderItemsMobile>
                TARGETED PENETRATION TESTS
            </AdvisoryDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {VigilanceTargetedText}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>

        <AdvisoryDivItemsMobile>
            <AdvisoryDivHeaderItemsMobile>
                Red Team Exercises
            </AdvisoryDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {VigilanceRedText}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>
        <AdvisoryDivItemsMobile>
            <AdvisoryDivHeaderItemsMobile>
                Source Code Review Analysis
            </AdvisoryDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {VigilanceSourceText}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>
        <AdvisoryDivItemsMobile>
            <AdvisoryDivHeaderItemsMobile>
                Ransomware Simulation
            </AdvisoryDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {VigilanceRansomwareText}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>
    </>

    const ResilienceItems = <>
        <WrapperDiv>
            <Loader marginTop="12vh" />
            <ImageAdvisoryItemsMobile src={vigilance4} />
        </WrapperDiv>

        <AdvisoryTextItemsMobile>
            It is not “if” but rather “when” businesses will come under attack. That is the reason why having a robust resilience plan and tactics will protect your organization and your own clients and limit any exponential impact of such attacks.
            <AdvisoryDivLineItemsMobile />
            Our team strengthens your ability to withstand the hits and quickly recover from cyberattacks or other disruptions to your systems and operations. To achieve resilience, we focus on several key areas, including:
        </AdvisoryTextItemsMobile>

        <AdvisoryDivItemsMobile>
            <AdvisoryDivHeaderItemsMobile>
                Incident Response
            </AdvisoryDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {ResilienceIncident}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>

        <AdvisoryDivItemsMobile>
            <AdvisoryDivHeaderItemsMobile>
                Digital Forensics
            </AdvisoryDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {ResilienceDigital}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>
        <AdvisoryDivItemsMobile>
            <AdvisoryDivHeaderItemsMobile>
                Business Continuity Management
            </AdvisoryDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {ResilienceBusiness}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>
        <AdvisoryDivItemsMobile>
            <AdvisoryDivHeaderItemsMobile>
                Cyber Liability Insurance
            </AdvisoryDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {ResilienceCyber}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>
    </>
    return <>
        <Helmet>
            <title>Advisory: Strategy, Defense, Vigilance & Resilience</title>
            <meta name="description" content="IT & Cybersecurity Advisory Services supporting clients build and managing secure, performant, and resilient systems." />
        </Helmet>
        <AdvisoryBackground>
            <AdvisoryImg />
            <AdvisoryTitle>
                <AdvisoryTextTitle>ADVISORY</AdvisoryTextTitle>
                <AdvisoryTextBody>
                    Our specialized IT & Cybersecurity Advisory Services aim at supporting clients in building and managing information systems that are secure, performant, and resilient.
                </AdvisoryTextBody>
                <AdvisoryButton onClick={() => { strategyRef.current.scrollIntoView({ behavior: 'smooth' }) }}>DISCOVER</AdvisoryButton>
                <ScrollTop isScrolled={isScrolled} src={scrollTopIcon} onClick={() => { window.scrollTo(0, 0) }} />
                <ChatIcon src={chatIcon} />
            </AdvisoryTitle>
        </AdvisoryBackground>
        <AdvisoryCover>
            <CornImg>
                <svg width="5vw" height="64" viewBox="0 0 95 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M93.2442 59.4236C92.3635 60.5719 91.0927 61.5253 89.5607 62.187C88.0286 62.8487 86.29 63.1951 84.5213 63.191H10.7127C8.94399 63.196 7.20501 62.8505 5.67236 62.1895C4.13972 61.5286 2.86799 60.5758 1.98633 59.4279C1.10466 58.2799 0.644456 56.9778 0.652446 55.6537C0.660436 54.3295 1.13634 53.0306 2.03182 51.8887L38.8942 4.16745C39.7767 3.01972 41.0476 2.06639 42.579 1.40351C44.1104 0.740626 45.8481 0.391602 47.617 0.391602C49.386 0.391602 51.1237 0.740626 52.655 1.40351C54.1864 2.06639 55.4574 3.01972 56.3399 4.16745L93.2022 51.8887C94.1045 53.0281 94.584 54.3272 94.5914 55.6518C94.5988 56.9765 94.1337 58.2785 93.2442 59.4236Z" fill="url(#paint0_linear_51_664)" style={{ mixBlendMode: 'normal' }} />
                    <defs>
                        <linearGradient id="paint0_linear_51_664" x1="0.652344" y1="31.7913" x2="94.5915" y2="31.7913" gradientUnits="userSpaceOnUse">
                            <stop offset="0.253125" stopColor="#bcbcbc" />
                            <stop offset="0.711458" stopColor="#bababa" />
                        </linearGradient>
                    </defs>
                </svg>
            </CornImg>
            <CornMobile>
                <svg width="46" height="40" viewBox="0 0 46 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M45.3402 37.0986C44.909 37.8203 44.2867 38.4194 43.5365 38.8353C42.7863 39.2511 41.9349 39.4688 41.0689 39.4663H4.92635C4.06024 39.4694 3.2087 39.2522 2.4582 38.8369C1.70769 38.4215 1.08496 37.8227 0.653222 37.1013C0.22149 36.3799 -0.00386255 35.5616 5.00943e-05 34.7294C0.00396274 33.8973 0.237 33.0809 0.675499 32.3633L18.7262 2.37293C19.1584 1.65164 19.7807 1.05252 20.5306 0.635934C21.2805 0.219344 22.1314 0 22.9976 0C23.8638 0 24.7147 0.219344 25.4646 0.635934C26.2145 1.05252 26.8369 1.65164 27.269 2.37293L45.3197 32.3633C45.7615 33.0794 45.9963 33.8958 46 34.7283C46.0036 35.5607 45.7758 36.379 45.3402 37.0986Z" fill="url(#paint0_linear_1_662)" style={{ mixBlendMode: 'normal' }} />
                    <defs>
                        <linearGradient id="paint0_linear_1_662" x1="0" y1="19.7332" x2="46" y2="19.7332" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#bcbcbc" />
                            <stop offset="1" stop-color="#bababa" />
                        </linearGradient>
                    </defs>
                </svg>
            </CornMobile>
            <CoverTitle>
                We Cover the Full Cybersecurity Cycle
            </CoverTitle>
            <CoverBody>
                Starting at the initial assessment, we stay with our clients for the implementation of best practices in their security management. We make sure that systems are well set to respond to continuous changes and threats, while preserving smooth business continuity.
            </CoverBody>
            <CoverIconContainer>
                <CoverDiv onClick={() => { dispatch(setTargetSection('strategy')) }}>
                    <CoverIcon src={SIcon} />
                    <div>Strategy</div>
                </CoverDiv>
                <CoverDiv onClick={() => dispatch(setTargetSection('defense'))}>
                    <CoverIcon src={DIcon} />
                    <div>Defense</div>
                </CoverDiv>
                <CoverDiv onClick={() => dispatch(setTargetSection('vigilance'))}>
                    <CoverIcon src={VIcon} />
                    <div>Vigilance</div>
                </CoverDiv>
                <CoverDiv onClick={() => dispatch(setTargetSection('resilience'))}>
                    <CoverIcon src={RIcon} />
                    <div>Resilience</div>
                </CoverDiv>
            </CoverIconContainer>
        </AdvisoryCover>
        {/* strategy */}
        <StrategyBackground ref={isMobile ? undefined : strategyRef}>
            <StrategyImg />
            <VigilanceEyeIcon src={strategyIcon} />
            <VigilanceBody height={strategyIndex ? '160vh' : '130vh'}>
                <VigilanceBodyHead>
                    <VigilanceBodyHeadText marginBottom={'7vh'}>
                        STRATEGY
                    </VigilanceBodyHeadText>
                    <WrapperDiv>
                        <Loader marginTop="20vh" />
                        <VigilanceImage width={'59vw'} src={vigilance1} />
                    </WrapperDiv>

                    <VigilanceBodyText>
                        We lead clients in creating an effective cybersecurity strategy that sets a clear roadmap for their organization, optimizing their posture to the best adapted standards whether NIST, ISO27000 suite, or GDPR.
                        <AdvisoryDivLineItemsMobile />
                        We guide them in their cyber journey, protecting their data, systems, and digital assets.
                    </VigilanceBodyText>
                </VigilanceBodyHead>
                <VigilanceCardContainer>
                    <VigilanceCard text={"ACHIEVE CYBERSECURITY MATURITY"} clickHandler={() => {
                        if (strategyIndex === 1) {
                            setStrategyIndex(null);
                            setSText(null);
                        }
                        else {
                            setStrategyIndex(1);
                            setSText(StrategyAchieveText)
                        }
                    }} active={strategyIndex === 1} />
                    <VigilanceCard text={"DATA PRIVACY ASSESSMENT"} clickHandler={() => {
                        if (strategyIndex === 2) {
                            setStrategyIndex(null);
                            setSText(null);
                        }
                        else {
                            setStrategyIndex(2);
                            setSText(StrategyDataText)
                        }
                    }} active={strategyIndex === 2} />
                    <VigilanceCard text={"SCENARIO-BASED RISK ASSESSMENTS"} clickHandler={() => {
                        if (strategyIndex === 3) {
                            setStrategyIndex(null);
                            setSText(null);
                        }
                        else {
                            setStrategyIndex(3);
                            setSText(StrategyScenarioText)
                        }

                    }} active={strategyIndex === 3} />
                    <VigilanceCard text={"VIRTUAL CISO"} clickHandler={() => {
                        if (strategyIndex === 4) {
                            setStrategyIndex(null);
                            setSText(null);
                        }
                        else {
                            setStrategyIndex(4);
                            setSText(StrategyVirtualText)
                        }

                    }} active={strategyIndex === 4} />
                </VigilanceCardContainer>
                {sText &&
                    <ModuleVigilanceBorderWrap active={true}>
                        <ModuleVigilanceBorderWrapBody>
                            <ModuleVigilanceBorderWrapBodyText>
                                {sText}
                            </ModuleVigilanceBorderWrapBodyText>
                        </ModuleVigilanceBorderWrapBody>
                    </ModuleVigilanceBorderWrap>
                }
            </VigilanceBody>
        </StrategyBackground>
        {/* defense */}
        <DefenseBackground ref={isMobile ? undefined : defenseRef}>
            <DefenseImg />
            <VigilanceEyeIcon src={defenseIcon} />
            <VigilanceBody height={defenseIndex ? '150vh' : '125vh'}>
                <VigilanceBodyHead>
                    <VigilanceBodyHeadText marginBottom={defenseIndex ? '8vh' : '8vh'}>
                        DEFENSE
                    </VigilanceBodyHeadText>
                    <WrapperDiv>
                        <Loader marginTop="20vh" />
                        <VigilanceImage width={'59vw'} src={vigilance2} />
                    </WrapperDiv>

                    <VigilanceBodyText>
                        A solid cyber defense through our in-depth security approach, remains our clients’ best bet against any malicious actors and cyberthreats.
                    </VigilanceBodyText>
                </VigilanceBodyHead>
                <VigilanceCardContainer>
                    <VigilanceCard text={"DIRECTORY SERVICES SECURITY REVIEW"} clickHandler={() => {
                        if (defenseIndex === 1) {
                            setDefenseIndex(null);
                            setDText(null);
                        }
                        else {
                            setDefenseIndex(1);
                            setDText(DefenseDirectoryText)
                        }
                    }} active={defenseIndex === 1} />
                    <VigilanceCard text={"INFRASTRUCTURE & CLOUD SECURITY ASSESSMENTS"} clickHandler={() => {
                        if (defenseIndex === 2) {
                            setDefenseIndex(null);
                            setDText(null);
                        }
                        else {
                            setDefenseIndex(2);
                            setDText(DefenseInfraText)
                        }

                    }} active={defenseIndex === 2} />
                    <VigilanceCard text={"DATABASE SECURITY ASSESSMENT"} clickHandler={() => {
                        if (defenseIndex === 3) {
                            setDefenseIndex(null);
                            setDText(null);
                        }
                        else {
                            setDefenseIndex(3);
                            setDText(DefenseDatabaseText)
                        }

                    }} active={defenseIndex === 3} />
                    <VigilanceCard text={"USER MANAGEMENT SECURITY"} clickHandler={() => {
                        if (defenseIndex === 4) {
                            setDefenseIndex(null);
                            setDText(null);
                        }
                        else {
                            setDefenseIndex(4);
                            setDText(DefenseUserText)
                        }

                    }} active={defenseIndex === 4} />
                </VigilanceCardContainer>
                {dText &&
                    <ModuleVigilanceBorderWrap active={true}>
                        <ModuleVigilanceBorderWrapBody>
                            <ModuleVigilanceBorderWrapBodyText>
                                {dText}
                            </ModuleVigilanceBorderWrapBodyText>
                        </ModuleVigilanceBorderWrapBody>
                    </ModuleVigilanceBorderWrap>
                }
            </VigilanceBody>
        </DefenseBackground>
        {/* vigilance */}
        <VigilanceBackground ref={isMobile ? undefined : vigilanceRef}>
            <VigilanceImg height={vigilanceIndex ? '152.6%' : '190%'} />
            <AdvisoryDivLineItemsMobile marginTop={'-2vh'} />
            <VigilancePrimeEyeIcon src={vigilanceEyeIcon} />
            <VigilanceBody height={vigilanceIndex ? '170vh' : '130vh'}>
                <VigilanceBodyHead>
                    <VigilanceBodyHeadText marginBottom={vigilanceIndex ? '10vh' : '7vh'}>
                        VIGILANCE
                    </VigilanceBodyHeadText>
                    <WrapperDiv>
                        <Loader marginTop="20vh" />
                        <VigilanceImage width={'59vw'} src={vigilance3} />
                    </WrapperDiv>

                    <VigilanceBodyText>
                        We keep clients’ environments under our spotlight and their teams on their cyber feet, making sure that they master the latest security trends and best practices in order to preemptively identify and neutralize any threats to their business, ranging from Red Team Exercises, to targeted penetration tests & source code review.
                    </VigilanceBodyText>
                </VigilanceBodyHead>
                <VigilanceCardContainer>
                    <VigilanceCard text={"TARGETED PENETRATION TESTS"} clickHandler={() => {
                        if (vigilanceIndex === 1) {
                            setVigilanceIndex(null);
                            setVText(null);
                        }
                        else {
                            setVigilanceIndex(1);
                            setVText(VigilanceTargetedText)
                        }
                    }} active={vigilanceIndex === 1} />
                    <VigilanceCard text={"Red Team Exercises"} clickHandler={() => {
                        if (vigilanceIndex === 2) {
                            setVigilanceIndex(null);
                            setVText(null);
                        }
                        else {
                            setVigilanceIndex(2);
                            setVText(VigilanceRedText)
                        }

                    }} active={vigilanceIndex === 2} />
                    <VigilanceCard text={"Source Code Review Analysis"} clickHandler={() => {
                        if (vigilanceIndex === 3) {
                            setVigilanceIndex(null);
                            setVText(null);
                        }
                        else {
                            setVigilanceIndex(3);
                            setVText(VigilanceSourceText)
                        }

                    }} active={vigilanceIndex === 3} />
                    <VigilanceCard text={"Ransomware Simulation"} clickHandler={() => {
                        if (vigilanceIndex === 4) {
                            setVigilanceIndex(null);
                            setVText(null);
                        }
                        else {
                            setVigilanceIndex(4);
                            setVText(VigilanceRansomwareText)
                        }

                    }} active={vigilanceIndex === 4} />
                </VigilanceCardContainer>
                {vText &&
                    <ModuleVigilanceBorderWrap active={true}>
                        <ModuleVigilanceBorderWrapBody>
                            <ModuleVigilanceBorderWrapBodyText>
                                {vText}
                            </ModuleVigilanceBorderWrapBodyText>
                        </ModuleVigilanceBorderWrapBody>
                    </ModuleVigilanceBorderWrap>
                }
            </VigilanceBody>
        </VigilanceBackground>
        {/* resilience */}
        <StrategyBackground ref={isMobile ? undefined : resilienceRef}>
            <StrategyImg />
            <VigilanceEyeIcon src={resilienceIcon} />
            <VigilanceBody height={resilienceIndex ? '170vh' : '130vh'}>
                <VigilanceBodyHead>
                    <VigilanceBodyHeadText marginBottom={resilienceIndex ? '9vh' : '7vh'}>
                        Resilience
                    </VigilanceBodyHeadText>
                    <WrapperDiv>
                        <Loader marginTop="20vh" />
                        <VigilanceImage width={'59vw'} src={vigilance4} />
                    </WrapperDiv>

                    <VigilanceBodyText>
                        It is not “if” but rather “when” businesses will come under attack. That is the reason why having a robust resilience plan and tactics will protect your organization and your own clients and limit any exponential impact of such attacks.
                        <AdvisoryDivLineItemsMobile />
                        Our team strengthens your ability to withstand the hits and quickly recover from cyberattacks or other disruptions to your systems and operations. To achieve resilience, we focus on several key areas, including:
                    </VigilanceBodyText>
                </VigilanceBodyHead>
                <VigilanceCardContainer>
                    <VigilanceCard text={"INCIDENT RESPONSE"} clickHandler={() => {
                        if (resilienceIndex === 1) {
                            setResilienceIndex(null);
                            setRText(null);
                        }
                        else {
                            setResilienceIndex(1);
                            setRText(ResilienceIncident)
                        }
                    }} active={resilienceIndex === 1} />
                    <VigilanceCard text={"DIGITAL FORENSICS"} clickHandler={() => {
                        if (resilienceIndex === 2) {
                            setResilienceIndex(null);
                            setRText(null);
                        }
                        else {
                            setResilienceIndex(2);
                            setRText(ResilienceDigital)
                        }

                    }} active={resilienceIndex === 2} />
                    <VigilanceCard text={"BUSINESS CONTINUITY MANAGEMENT"} clickHandler={() => {
                        if (resilienceIndex === 3) {
                            setResilienceIndex(null);
                            setRText(null);
                        }
                        else {
                            setResilienceIndex(3);
                            setRText(ResilienceBusiness)
                        }

                    }} active={resilienceIndex === 3} />
                    <VigilanceCard text={"CYBER LIABILITY INSURANCE"} clickHandler={() => {
                        if (resilienceIndex === 4) {
                            setResilienceIndex(null);
                            setRText(null);
                        }
                        else {
                            setResilienceIndex(4);
                            setRText(ResilienceCyber)
                        }

                    }} active={resilienceIndex === 4} />
                </VigilanceCardContainer>
                {rText &&
                    <ModuleVigilanceBorderWrap active={true}>
                        <ModuleVigilanceBorderWrapBody>
                            <ModuleVigilanceBorderWrapBodyText>
                                {rText}
                            </ModuleVigilanceBorderWrapBodyText>
                        </ModuleVigilanceBorderWrapBody>
                    </ModuleVigilanceBorderWrap>
                }
            </VigilanceBody>
        </StrategyBackground>
        <AdvisoryBodyMobile>
            <AdvisoryBodyMobileImg />
            <div style={{ marginTop: '23vh' }}></div>
            {/* isOpen={isOpen === 1} setOpen={() => setOpen(1)} setClose={() => setOpen(null)}
            isOpen={isOpen === 2} setOpen={() => setOpen(2)} setClose={() => setOpen(null)}
            isOpen={isOpen === 3} setOpen={() => setOpen(3)} setClose={() => setOpen(null)}
            isOpen={isOpen === 4} setOpen={() => setOpen(4)} setClose={() => setOpen(null)} */}
            <AdvisoryCardMobile refA={isMobile ? strategyRef : undefined} logo={strategyIcon} title={"STRATEGY"} items={StrategyItems} />
            <AdvisoryCardMobile refA={isMobile ? defenseRef : undefined} logo={defenseIcon} title={"DEFENSE"} items={DefenseItems} />
            <AdvisoryCardMobile refA={isMobile ? vigilanceRef : undefined} logo={vigilanceEyeIcon} title={"VIGILANCE"} items={VigilanceItems} />
            <AdvisoryCardMobile refA={isMobile ? resilienceRef : undefined} logo={resilienceIcon} title={"RESILIENCE"} items={ResilienceItems} />
        </AdvisoryBodyMobile>
        {/* footer */}
        <FooterBackground>
            <FooterImg />
            <FooterTitle>
                <PotechQuoteFooterAdvisory>
                    “Safeguarding clients’ peace of mind is our mission, with unwavering perfection, dedication and expertise.”
                    <Author>
                        <div>Hanady Karam</div>
                        <div>Managing Partner</div>
                    </Author>
                </PotechQuoteFooterAdvisory>
                <PotechHistoryFooterAdvisory>
                    <PotechHistoryFooterAdvisoryImage src={potechCons} />
                    <PotechHistoryFooterNewLine>Founded in 2008, Potech Consulting is part of Potech Group. It is a specialized IT and cybersecurity consulting firm that focuses on making information systems more performant, secure and resilient.</PotechHistoryFooterNewLine>
                    <PotechHistoryFooterNewLine>Potech Consulting has duly acquired a high recognition, with an international portfolio of clients from various sectors.</PotechHistoryFooterNewLine>
                    <PotechHistoryFooterNewLine>Our services have made their mark in helping customers to rapidly adapt and respond to internal or external changes, and most importantly to counter for disruptions or threats, preserving work continuity with limited impact on the business.</PotechHistoryFooterNewLine>
                </PotechHistoryFooterAdvisory>
            </FooterTitle>
        </FooterBackground>
        <FooterDivider />
    </>

}

export default Advisory;