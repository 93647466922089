import styled from "styled-components";

export const AdvisoryNav = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  column-gap: 10vw;
  row-gap: 1em;
  padding-top: 1vh;
  margin-bottom: 1%;
  width: 100%;
  cursor: auto;
`;

export const AdvisoryRow = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: start;
    text-align: start;
    margin-bottom: 1%;
    z-index: 1;
`;

export const AdvisoryTitle = styled.div`
    color: #0071CE;
    font-size: 0.9vw;
    cursor: pointer;
    margin-bottom: 3%;
    &:hover {
        opacity: 0.7;
    }
`;

export const AdvisoryBody = styled.div`
    color: #565656;
    font-size: 0.9vw;
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
`;