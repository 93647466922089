import { ArrowRight, NavPageMobile, SubNavs, SubNavsText, SubNavsTextLight } from "./Navbar/NavbarElements"
import arrowRight from '../assets/logos/arrowRight.svg'

const NavbarMobile = ({ setIndex, goTo }) => {

    return <NavPageMobile>
        <SubNavs data-aos="fade-right" onClick={() => { setIndex(1) }}>
            <SubNavsText>
                PRODUCTS
            </SubNavsText>
            <ArrowRight src={arrowRight} />
        </SubNavs>
        <SubNavs data-aos="fade-right" onClick={() => { setIndex(2) }}>
            <SubNavsText>
                ADVISORY
            </SubNavsText>
            <ArrowRight src={arrowRight} />
        </SubNavs>
        <SubNavs data-aos="fade-right" onClick={() => { setIndex(3) }}>
            <SubNavsText>
                MANAGED SERVICES
            </SubNavsText>
            <ArrowRight src={arrowRight} />
        </SubNavs>
        <SubNavs data-aos="fade-right" onClick={() => { setIndex(4) }}>
            <SubNavsText>
                ENGINEERING
            </SubNavsText>
            <ArrowRight src={arrowRight} />
        </SubNavs>
        <SubNavs data-aos="fade-right" onClick={() => { setIndex(5) }}>
            <SubNavsText>
                ACADEMY
            </SubNavsText>
            <ArrowRight src={arrowRight} />
        </SubNavs>
        <SubNavs data-aos="fade-right">
            <SubNavsText onClick={() => goTo('labs')}>
                LABS
            </SubNavsText>
        </SubNavs>
        <SubNavs data-aos="fade-right" onClick={() => { setIndex(6) }}>
            <SubNavsText>
                RESOURCES
            </SubNavsText>
            <ArrowRight src={arrowRight} />
        </SubNavs>
        <SubNavs data-aos="fade-right">
            <SubNavsTextLight onClick={() => goTo('contact-us')}>
                CONTACT US
            </SubNavsTextLight>
        </SubNavs>
        <SubNavs data-aos="fade-right">
            <SubNavsTextLight onClick={() => goTo('about-us')}>
                ABOUT US
            </SubNavsTextLight>
        </SubNavs>
        <SubNavs data-aos="fade-right">
            <SubNavsTextLight onClick={() => goTo('careers')}>
                CAREERS
            </SubNavsTextLight>
        </SubNavs>
    </NavPageMobile>
}

export default NavbarMobile;