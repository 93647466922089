import styled from "styled-components";
import img from '../../assets/images/Academy-Hero-BG.png';
import Timg from '../../assets/images/traingBack.png';

export const AcademyBackground = styled.nav`
  width: 100%;
  height: 85vh;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 800px) {
    height: auto;
  }
`;

export const AcademyImg = styled.div`
  width: 100%;
  height: 85vh;
  position: absolute;
  background-image: url(${img});
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  @media screen and (max-width: 800px) {
    height: 100.5%;
  }
`;

export const AcademyEyeIcon = styled.img`
  position: absolute;
  width: 200px;
  top: 11vh;
  left: 50%;
  transform: translateX(-50%);
  @media screen and (max-width: 1600px) {
    width: 160px;
    top: 11vh;
  }
`;

export const AcademyCover = styled.div`
display: flex;
flex-direction: column;
justify-content: space-evenly;
align-items: center;
text-align: center;
height: 31vh;
/* font-family: 'Helvetica Light'; */
font-family: "HelveticaLt", Arial, sans-serif;
background: var(--ocean-gradient, linear-gradient(90deg, #e2e2e2 0%, #bdbdbd 100%));
background-repeat: no-repeat;
background-attachment: fixed;
background-blend-mode: multiply;
position: relative;
@media screen and (max-width: 800px) {
  display: none;
}
`

export const AcademyTitle = styled.div`
  color: white;
  height: 50vh;
  margin-top: 15vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  @media screen and (max-width: 800px) {
    height: auto;
  }
`;

export const AcademyTextTitle = styled.h1`
    color: #FFF;
    /* Title-Internal Page */
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.8vw;
    margin-top: 0;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    @media screen and (max-width: 800px) {
      text-align: center;
      font-size: 23px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      word-wrap: break-word;
      box-sizing: border-box;
      text-align: center;
      padding-inline: 15%;
      margin-top: 10vh;
      margin-bottom: 5vh;
  }
`;

export const AcademyTextBody = styled.div`
    width: 75vw;
    color: #FFF;
    text-align: center;
    /* Title Copy-Internal Page */
    font-family: "HelveticaLt", Arial, sans-serif;
    font-size: 1.2vw;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 2vh;
    @media screen and (max-width: 800px) {
      font-size: 18px;
      padding-inline: 1%;
      box-sizing: border-box;
      margin-top: 2vh;
      margin-bottom: unset;
    }
`;

export const LabsButton = styled.div`
  border-radius: 5px;
  background-blend-mode: multiply;
  /* Drop Shadow Style */
  cursor: pointer;
  box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.20);
  color: var(--black, #000);
  text-align: center;
  font-weight: 400;
  width: 7vw;
  padding: 0.4vw;
  font-size: 1vw;
  font-family: 'Helvetica';
  background: var(--generic-neutral-grey-gradient, linear-gradient(90deg, #D6D6D6 25.31%, #A1A1A1 71.15%));
  margin-top: 0vh;
  z-index: 5;
  @media screen and (max-width: 800px) {
    width: auto;
    font-size: 14px;
    padding: 2%;
    padding-inline: 5%;
    margin-top: 3vh;
    margin-bottom: 0vh;
  }
`;

export const AcademyButton = styled.div`
    border-radius: 6px;
    background: var(--white, #FFF);
    background-blend-mode: multiply;
    /* Drop Shadow Style */
    box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.20);
    color: var(--black, #000);
    text-align: center;
    font-weight: 400;
    width: 7vw;
    padding: 0.5%;
    cursor: pointer;
    font-size: 1vw;
    margin-top: 1vh;
    font-family: 'Helvetica';
    @media screen and (max-width: 800px) {
      width: auto;
      font-size: 14px;
      padding: 2%;
      padding-inline: 5%;
      margin-top: 7vh;
      margin-bottom: 9vh;
    }
`;

export const TrainingBackground = styled.nav`
  width: 100%;
  height: auto;
  position: relative;
  /* background-color: red; */
  /* overflow: hidden; */
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const TrainingBodyMobileImg = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url(${Timg});
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  background-position: center;
  background-color: #5b1d0f;
`;

export const TrainingImg = styled.div`
  width: 100%;
  height: 100.5%;
  position: absolute;
  background-image: url(${Timg});
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  background-color: #b73f0f;
`;

export const AcademyBody = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  background-color: white;
  height: ${props => props.cardIndex ? '170vh' : '120vh'};
  /* height: auto; */
  width: 75vw;
  margin: auto;
  border-radius: 15px;
  margin-top: 22vh;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 10vh;
`;

export const AcademyBodyText = styled.h2`
  color: var(--academy-medium-orange, #E65300);
  text-transform: uppercase;
  font-size: 1.8vw;
  margin-bottom: 0;
  font-weight: normal;
  margin-top: 8vh;
  z-index: 1;
`;

export const AcademyCardContainer = styled.div`
  display: flex;
  width: 59vw;
  justify-content: space-between;
  height: 30vh;
`;

export const CertBody = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  background-color: white;
  height: ${props => props.cardIndex ? '180vh' : '140vh'};
  /* height: auto; */
  width: 75vw;
  margin: auto;
  border-radius: 15px;
  margin-top: 22vh;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 10vh;
`;

export const TrainingTitle = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
`;

export const CertTitle = styled.div`
  color: white;
  height: ${props => props.cardIndex ? '200vh' : '170vh'};
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
`;

export const CertText = styled.div`
  color: var(--black, #000);
  text-align: center;
  font-family: "HelveticaLt", Arial, sans-serif;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  width: 59vw;
`;

export const FooterAcademyBackground = styled.nav`
  width: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  font-weight: 100;
  /* font-family: 'Helvetica Light'; */
  font-family: "HelveticaLt", Arial, sans-serif;
  height: auto;
  background: linear-gradient(90deg, #9E3223 6.00%, #A6361E 20.00%, #BF4112 44.00%, #E65300 73.00%);
  @media screen and (max-width: 800px) {
    height: auto;
  }
`;

export const FooterAcademyTitle = styled.div`
  height: auto;
  display: flex;
  color: white;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  width: 86vw;
  margin: auto;
  
  margin-top: 10vh;
  margin-bottom: 10vh;
  @media screen and (max-width: 800px) {
    height: auto;
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 0vh;
    margin-bottom: 0vh;
    width: 100%;
  }
`;