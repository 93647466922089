import { AdvantageLogo, ModuleBorderWrap, TacivoarContainer,  } from "./TacivoarCardElements";
import arrowDown from '../../assets/logos/arrowDown.svg';
import arrowUp from '../../assets/logos/arrowUp.svg';

const TacivoareCard = (props) => {
    return <ModuleBorderWrap active={props.active}>
        <TacivoarContainer onClick={props.clickHandler} active={props.active}>
            <AdvantageLogo src={props.logo} />
            <h3 style={{ height: '12vh', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', textAlign: 'center', alignItems: 'center', paddingBottom: '7%', paddingInline: '7%', marginTop: 0, marginBottom: 0, fontWeight: 'normal', fontSize: '1vw' }}>
                {props.text}
            </h3>
            <div style={{ height: '4.5vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', alignItems: 'center', backgroundColor: '#f0f0f0', borderBottomLeftRadius: '8.5px', borderBottomRightRadius: '8.5px' }}>
                <img src={props.active ? arrowUp : arrowDown} alt="React Logo" width={15} />
            </div>
        </TacivoarContainer>
    </ModuleBorderWrap>
}

export default TacivoareCard;