import styled from "styled-components";
import OctivoreBack from '../../assets/images/Octivore-BG.png'

export const OctivoreBackground = styled.nav`
  width: 100%;
  height: 120vh;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  @media screen and (max-width: 800px) {
    height: 75vh;
  }
`;

export const OctivoreImg = styled.div`
  width: 100%;
  height: 120vh;
  position: absolute;
  background-image: url(${OctivoreBack});
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  @media screen and (max-width: 800px) {
    height: 100%;
  }
`;

export const OctivoreTitle = styled.div`
  color: white;
  height: 110vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  /* font-family: 'Helvetica Light'; */
  font-family: "HelveticaLt", Arial, sans-serif;
  @media screen and (max-width: 800px) {
    height: 75vh;
  }
`;

export const OctivoreLogo = styled.img`
  width: 10vw;
  margin-top: 5vh;
  margin-bottom: 2vh;
  @media screen and (max-width: 800px) {
    width: 110px;
  }
`;

export const OctivoreButton = styled.div`
border-radius: 5px;
background-blend-mode: multiply;
/* Drop Shadow Style */

background: white;
color: black;
margin-top: 5vh;

cursor: pointer;
box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.20);
text-align: center;
font-weight: 400;
width: 7vw;
padding: 0.4vw;
font-size: 1vw;
font-family: 'Helvetica';
@media screen and (max-width: 800px) {
  width: auto;
    font-size: 14px;
    padding: 2%;
    padding-inline: 5%;
    margin-top: 6vh;
  }
`;

export const OctivoreTextTitle = styled.h1`
  margin-top: 5vh;
  font-size: 1.4vw;
  font-weight: 400;
  margin-bottom: 2vh;
  font-family: "HelveticaLt", Arial, sans-serif;
  @media screen and (max-width: 800px) {
    font-size: 20px;
    padding-inline: 10vw;
    text-align: center;
  }
`;

export const OctivoreTechContainer = styled.div`
  width: 47vw;
  justify-content: space-between;
  display: flex;
  @media screen and (max-width: 800px) {
    width: 80%;
    flex-direction: column;
    padding-bottom: 5vh;
  }
`;

export const OctivoreText = styled.div`
  color: var(--black, #000);
  width: ${props => props.width ?? '58vw'};
  font-size: 0.95vw;
  text-align: ${props => props.textAlign ?? 'center'};
  margin-top: ${props => props.marginTop ?? ''};
  margin-bottom: ${props => props.marginBottom?? ''};
  @media screen and (max-width: 800px) {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 138.889%;
    font-family: "HelveticaLt", Arial, sans-serif;
    text-align: center;
    margin-bottom: 3vh;
    margin-top: 1vh;
  }
`;

export const OctivoreTechBackground = styled.nav`
  width: 100%;
  height: 115vh;
  position: relative;
  @media screen and (max-width: 800px) {
    height: auto;
  }
`;

export const OctivoreTechTitle = styled.div`
  color: white;
  height: 115vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
  /* font-family: 'Helvetica Light'; */
  font-family: "HelveticaLt", Arial, sans-serif;
  @media screen and (max-width: 800px) {
    height: auto;
  }
`;

export const OctivoreHeadLogo = styled.img`
  position: absolute;
  left: 50%;
  width: 280px;
  top: -140px;
  transform: translateX(-50%);
  @media screen and (max-width: 1600px) {
    width: 200px;
    top: -100px;
    transform: translateX(-50%);
  }
  @media screen and (max-width: 800px) {
    width: 170px;
    top: -85px;
  }
`;

export const OctivoreTechBody = styled.div`
  position: relative;
  color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  background-color: white;
  height: 75vh;
  width: 75vw;
  margin: auto;
  color: var(--darkivore-dark-blue, #1F3B71);
  border-radius: 15px;
  margin-top: 25vh;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  @media screen and (max-width: 800px) {
    height: auto;
    width: 80vw;
    margin-top: 25vh;
    margin-bottom: 10vh;
  }
`;

export const OctivoreTechHead = styled.h2`
  color: var(--darkivore-dark-blue, #1F3B71);
  text-align: center;
  /* Heading H1 */
  font-weight: normal;
  font-size: 1.5vw;
  text-transform: uppercase;
  color: black;
  margin-bottom: -5vh;
  margin-top: 12vh;
  font-family: "HelveticaLt", Arial, sans-serif;
  z-index: 1;
  @media screen and (max-width: 800px) {
    font-size: 20px;
    font-family: "HelveticaLt", Arial, sans-serif;
    margin-top: 18vh;
    padding-inline: 5vw;
    margin-bottom: 5vh;
  }
`;

export const OctivoreTextBody = styled.div`
  font-size: 1.3vw;
  font-weight: 400;
`;


export const OctivoreTechDiv = styled.div`
  position: relative;
  border-radius: 15px;
  background: var(--white, #FFF);
  width: 15vw;
  height: 17vh;
  /* Testimonials Box Shadow */
  box-shadow: 15px 15px 30px 5px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  /* padding-top: 6.5vh; */
  box-sizing: border-box;
  @media screen and (max-width: 800px) {
    width: 100%;
    margin-top: 10vh;
  }
`;

export const OctivoreTechLogo = styled.img`
  position: absolute;
  left: 50%;
  width: 140px;
  transform: translateX(-50%);
  top: -65px;
  @media screen and (max-width: 1600px) {
    left: 50%;
    width: 120px;
    transform: translateX(-50%);
    top: -60px;
  }
`;

export const OctivoreTechDesc = styled.h3`
  color: black;
  text-align: center;
  /* Body 02 */
  font-family: "HelveticaLt", Arial, sans-serif;
  font-size: 0.9vw;
  font-style: normal;
  font-weight: 400;
  margin-top: 8vh;
  line-height: normal;
  @media screen and (max-width: 800px) {
    font-size: 14px;
  }
`;