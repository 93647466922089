import styled from "styled-components";

export const ContactContainer = styled.div`
    height: 360vh;
    height: auto;
    background: var(--ocean-gradient, linear-gradient(90deg, #00ADBB 0%, #002F87 100%));
    display: flex;
    padding-bottom: 10vh;
    padding-top: 25vh;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    @media screen and (max-width: 800px) {
        height: auto;
    }
`;

export const TextHeader = styled.div`
    color: #FFF;
    /* Title-Internal Page */
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.8vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    /* margin-bottom: -10vh; */
    margin-top: 20vh;
    margin-bottom: 30vh;
    @media screen and (max-width: 800px) {
        font-size: 23px;
        /* margin-top: unset; */
        /* margin-top: unset; */
        /* margin-bottom: 10vh; */
    }
`;

export const LabelText = styled.div`
    font-size: 0.9vw;
`;

export const ContactDiv = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    border-radius: 15px;
    height: 110vh;
    width: 80vw;
    margin-top: 10vh;
    margin-bottom: 25vh;
    background: var(--white, #FFF);
    @media screen and (max-width: 800px) {
        height: auto;
        margin-top: unset;
    }
`;

export const ContactOfficeDiv = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    border-radius: 15px;
    height: auto;
    width: 80vw;
    background: var(--white, #FFF);
    /* margin-top: -20vh; */
    @media screen and (max-width: 1600px) {
        /* margin-top: 10vh; */
        height: auto;
    }
    @media screen and (max-width: 800px) {
        height: auto;
        padding-bottom: 5vh;
    }
`;

export const ContactIcon = styled.img`
    position: absolute;
    width: 200px;
    top: -100px;
    left: 50%;
    transform: translateX(-50%);
    @media screen and (max-width: 1600px) {
        position: absolute;
        width: 150px;
        top: -80px;
        left: 50%;
        transform: translateX(-50%);
    }
`;

export const MessageTitle = styled.div`
    color: var(--potech-dark-blue, #002F87);
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin-top: 15vh;
    margin-bottom: 10vh;
    @media screen and (max-width: 800px) {
        font-size: 20px;
        font-family: "HelveticaLt", Arial, sans-serif;
        margin-top: 13vh;
        margin-bottom: 7vh;
    }
`;

export const FormMobileContainer = styled.div`
    @media screen and (min-width: 801px) {
        display: none;
    }
`;

export const FormDesktopContainer = styled.div`
    @media screen and (max-width: 800px) {
        display: none;
    }
`;

export const MessageForm = styled.div`
    border-radius: 15px;
    background: var(--white, #FFF);
    height: auto;
    width: 80%;
    padding: 4%;
    box-sizing: border-box;
    /* Drop Shadow Style */
    box-shadow: 12px 12px 15px 2px rgba(0, 0, 0, 0.20);
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-top: -5vh;
    @media screen and (max-width: 800px) {
        height: auto;
        margin-top: 0vh;
        margin-bottom: 5vh;
    }
`;

export const MessageOfficeForm = styled.div`
    border-radius: 15px;
    background: var(--white, #FFF);
    height: auto;
    width: 80%;
    padding: 4%;
    padding-top: 2%;
    padding-bottom: 2%;
    /* box-sizing: border-box; */
    /* Drop Shadow Style */
    box-shadow: 12px 12px 15px 2px rgba(0, 0, 0, 0.20);
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-bottom: 10vh;

    @media screen and (max-width: 1600px) {
        height: auto;
    }
    @media screen and (max-width: 800px) {
        height: auto;
        margin-top: 0vh;
        margin-bottom: 5vh;
    }
`;

export const LeaveMessage = styled.div`
    color: var(--black, #000);
    /* Body 01 */
    font-family: "HelveticaLt", Arial, sans-serif;
    font-size: 1vw;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; /* 138.889% */
    margin-bottom:20px;
    @media screen and (max-width: 800px) {
        font-size: 14px;
    }
`;

export const MessageRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: start;
    text-align: start;
    @media screen and (max-width: 800px) {
        flex-direction: column;
        height: auto;
        margin-top: 0vh;
    }
`;

export const MessageOfficeRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: start;
    text-align: start;
    height: auto;
    margin-top: 0vh;
    @media screen and (max-width: 800px) {
        flex-direction: column;
        height: auto;
    }
`;

export const FieldGroup = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin-right: ${props => props.marginRight ?? 'unset'};
    margin-left: ${props => props.marginLeft ?? 'unset'};
    height: ${props => props.height ?? 'auto'};
    @media screen and (max-width: 800px) {
        margin-right: unset;
        height: auto;
        margin-left: unset;
        margin-bottom: ${props => props.addMarginBottomOnMobile ? '50px' : 'unset'};
    }
`;

export const FieldLabel = styled.div`
    color: var(--black, #000);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1vw;
    font-weight: 700;
    flex: 0 1 auto;
    margin-right: 1vw;
    @media screen and (max-width: 800px) {
        font-size: 14px;
        margin-top: 3vh;
        margin-bottom: 1vh;
    }
`;

export const Hr = styled.hr`
    width: 90%;
    height: 1px;
    background: #002F87;
`;

export const FieldRow = styled.div`
    display: flex;
    align-items: end;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    /* align-items: stretch; */
    align-content: stretch;
    width: 100%;
`;

export const TextAreaStyle = styled("textarea")(({theme}) => ({
    fontFamily: 'Helvetica',
    fontSize: '14px',
    border: `2px solid #F9FAFB`,
    width: "100%",
    // flexGrow: 1,
    boxSizing: "border-box",
    borderRadius: '10px',
    // font-size: 16px;
    height: '160px',
    resize: "none",
    border: `2px solid #0071CE`,
    "&:hover": {
        border: `2px solid #000000`
    },
    "&:focus": {
        border: `2px solid #0071CE`,
        outline: "none"
    },
    '&:disabled': {
        background: '#d9d9d9',
        cursor: 'not-allowed'
    }
}));

export const MessageDesc = styled.div`
    color: var(--dark-grey, #565656);
    font-family: "HelveticaLt", Arial, sans-serif;
    font-size: 0.8vw;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 153.846% */
`;

export const SubmitBtn = styled.button`
    border-radius: 6px;
    background: var(--dark-light-orange-gradient, linear-gradient(90deg, #F1AF5E 38.85%, #E88A0D 71.15%));
    background-blend-mode: multiply;
    width: fit-content;
    cursor: pointer;
    border: none;
    text-align: center;
    color: white;
    font-size: 1vw;
    padding: 1%;
    padding-inline: 5%;
    font-family: Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    /* Drop Shadow Style */
    box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.20);

    &:disabled {
        opacity: 0.5;
        cursor: no-drop;
    }

    @media screen and (max-width: 800px) {
        font-size: 14px;
        width: 100%;
        padding: 3%;
        box-sizing: border-box;
    }
`;

export const OfficeDiv = styled.div`
    margin-bottom: 5vh;
    margin-top: 5vh;
`;

export const OfficeTitle = styled.div`
    color: var(--black, #000);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1vw;
    font-style: normal;
    font-weight: 700;
    line-height: 25px; /* 138.889% */
    @media screen and (max-width: 800px) {
        font-size: 14px;
        margin-top: 2vh;
    }
`;

export const OfficeText = styled.div`
    color: var(--black, #000);

    /* Body 01 */
    font-family: "HelveticaLt", Arial, sans-serif;
    font-size: 1vw;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; /* 138.889% */
    @media screen and (max-width: 800px) {
        font-size: 14px;
    }
`;

export const OfficeTextWithLogo = styled.div`
    display: flex;
    align-items: ${props => props.alignItems ?? 'start'};
    @media screen and (max-width: 800px) {
        align-items: start;
    }
`;

export const ContactLogo = styled.img`
    margin-right: 10px;
`;

export const PotechMail = styled.a`
    /* background: var(--lightest-darkest-blue-gradient, linear-gradient(90deg, #0071CE 0%, #002F87 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    font-family: Helvetica;
    font-size: 1.2vw;
    font-style: normal;
    font-weight: 700;
    text-decoration: none;
    color: black;
    line-height: 25px; /* 138.889% */
    cursor: pointer;
    @media screen and (max-width: 800px) {
        font-size: 15px;
        margin-bottom: 3vh;
    }
`;

export const HrefLink = styled.a`
    cursor: pointer;
    /* text-decoration: none;
    color: black; */
`;

export const ContactUsCondition = styled.div`
    font-size: 0.75vw;
    color: rgb(105, 105, 105);
    @media screen and (max-width: 800px) {
        font-size: 14px;
        margin-top: 3vh;
        margin-bottom: 3vh;
    }
`;

export const HrContact = styled.hr`
    background: #565656;
    height: 1px;
    width: 100%;
    @media screen and (max-width: 800px) {
        margin-top: 5vh;
        margin-bottom: 5vh;
    }
`;

export const PhoneNumberNotice = styled.span`
    color: #757575;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    margin-top: 20px;
`;

export const FormRow = styled.div`
    width: 100%;
    ${({compact}) => !compact && `margin-bottom:20px;`};
`;