import { ManagedServicesBody, ManagedServicesNav, ManagedServicesRow, ManagedServicesTitle } from "./ManagedServicesNavItemElements";

const ManagedServicesNavItem = ({ handleClick }) => {
    return <ManagedServicesNav>
        <ManagedServicesRow>
            <ManagedServicesTitle onClick={() => {
                handleClick('detect')
            }}>
                DETECT
            </ManagedServicesTitle>
            <ManagedServicesBody onClick={() => {
                handleClick('detect')
            }}>
                <div>
                    IDR
                </div>
                <div>
                    Ex-DR
                </div>
                <div>
                    SOC Security Operation Center
                </div>
                <div>
                    Managed SIEM
                </div>
            </ManagedServicesBody>
        </ManagedServicesRow>
        <ManagedServicesRow>
            <ManagedServicesTitle onClick={() => {
                handleClick('fortify')
            }}>
                FORTIFY
            </ManagedServicesTitle>
            <ManagedServicesBody onClick={() => {
                handleClick('fortify')
            }}>
                <div>
                    Managed Security Service
                </div>
                <div>
                    Managed EDR
                </div>
                <div>
                    SOC Maturity Assessment
                </div>
                <div>
                    Vulnerability Management
                </div>
                <div>
                    Hardening
                </div>
            </ManagedServicesBody>
        </ManagedServicesRow>
        <ManagedServicesRow>
            <ManagedServicesTitle onClick={() => {
                handleClick('respond')
            }}>
                RESPOND
            </ManagedServicesTitle>
            <ManagedServicesBody onClick={() => {
                handleClick('respond')
            }}>
                <div>
                    Incident Response
                </div>
                <div>
                    Managed SOAR
                </div>
                <div>
                    Case Management
                </div>
            </ManagedServicesBody>
        </ManagedServicesRow>
    </ManagedServicesNav>
}

export default ManagedServicesNavItem;