import { ProductDesc } from "../ProductElements";
import DarkiLogo from "../../assets/logos/DarkivoreLogo.png";
import { ProductContainer } from "../ProductElements";

const DarkivoreProduct = ({ DarkivoreItems }) => {
    return <ProductContainer>
        <ProductDesc>
            <img style={{ width: '11vw', marginRight: '1vw' }} src={DarkiLogo} alt="" />
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'end' }}>
                <h1 style={{ width: '100%', color: '#002F87', fontSize: '1.4vw', marginTop: '1vh', fontWeight: 'normal' }}>
                    DISARM <br />CYBERTHREATS AT <br />THE SOURCE
                </h1>
                <h2 style={{ width: '100%', marginTop: '2vh', fontSize: '1vw', fontWeight: 'normal', marginBottom: 0 }}>
                    Cutting-edge comprehensive<br /> Cyberthreat Intelligence and <br /> Digital Risk Protection Platform.
                </h2>
            </div>
        </ProductDesc>
        {DarkivoreItems}
    </ProductContainer>
}

export default DarkivoreProduct;