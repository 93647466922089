import styled, { keyframes } from "styled-components";

const s1 = keyframes`
  to{transform: rotate(.5turn)};
`;

export const Loader = styled.div`
  width:50px;
  height:50px;
  border-radius:50%;
  border:8px solid;
  border-color:#99999d #0000;
  z-index: 0;
  position: absolute;
  margin-top: ${props => props.marginTop};
  animation:${s1} 2s infinite;
`;

export const WrapperDiv = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
`;