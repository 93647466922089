import { useRef } from 'react';
import arrowDown from '../../assets/logos/arrowDown.svg';
import arrowUp from '../../assets/logos/arrowUp.svg';
import { useState } from 'react';
import { AdvisoryCardMobileContainer, CardBottom, ProductCol, ProductTitle, VigilanceEyeIconMobile } from './AdvisoryCardMobileElements';

const AdvisoryCardMobile = (props) => {
    const topCard = useRef(null);
    const [isOpen, setOpen] = useState(null);

    return <AdvisoryCardMobileContainer onClick={props.clickHandler} active={props.active}>
        <VigilanceEyeIconMobile ref={props.refA} src={props.logo} />
        <ProductCol>
            <ProductTitle>
                {props.title}
            </ProductTitle>
            {isOpen && props.items}
        </ProductCol>
        <CardBottom onClick={() => {
            if (isOpen){
                const yOffset = -100; 
                const y = props.refA?.current.getBoundingClientRect().top + window.scrollY + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            }
            setOpen(prev => !prev);
        }}>
            <img src={isOpen ? arrowUp : arrowDown} alt="React Logo" width={20} />
        </CardBottom>
    </AdvisoryCardMobileContainer>
}

export default AdvisoryCardMobile;