import { ADVISORY_CARD_TYPES } from "../../common/constant";
import { AdvisoryBody, AdvisoryNav, AdvisoryRow, AdvisoryTitle } from "./AdvisoryNavItemElements";

const AdvisoryNavItem = ({ handleClick, openInNewTab, handleClickCard }) => {
    return <AdvisoryNav>
        <AdvisoryRow>
            <AdvisoryTitle onClick={() => {
                handleClick('strategy')
            }}>
                STRATEGY
            </AdvisoryTitle>
            <AdvisoryBody onClick={() => {
                handleClick('strategy')
            }}>
                <div onClick={() => handleClickCard(ADVISORY_CARD_TYPES.STRATEGY_ACHIEVE)}>Achieve Cybersecurity Maturity</div>
                <div onClick={() => handleClickCard(ADVISORY_CARD_TYPES.STRATEGY_DATA)}>Data Privacy Assessment</div>
                <div onClick={() => handleClickCard(ADVISORY_CARD_TYPES.STRATEGY_SCENARIO)}>Scenario-Based Risk Assessments</div>
                <div onClick={() => handleClickCard(ADVISORY_CARD_TYPES.STRATEGY_VIRTUAL)}>Virtual CISO</div>
            </AdvisoryBody>
        </AdvisoryRow>
        <AdvisoryRow>
            <AdvisoryTitle onClick={() => {
                handleClick('defense')
            }}>
                DEFENSE
            </AdvisoryTitle>
            <AdvisoryBody onClick={() => {
                handleClick('defense')
            }}>
                <div onClick={() => handleClickCard(ADVISORY_CARD_TYPES.DEFENSE_DIRECTORY)}>Directory Services Security Review</div>
                <div onClick={() => handleClickCard(ADVISORY_CARD_TYPES.DEFENSE_INFRASTRUCTURE)}>Infrastructure & Cloud Security Assessments</div>
                <div onClick={() => handleClickCard(ADVISORY_CARD_TYPES.DEFENSE_DATABASE)}>Database Security Assessment</div>
                <div onClick={() => handleClickCard(ADVISORY_CARD_TYPES.DEFENSE_USER)}>User Management Security</div>
            </AdvisoryBody>
        </AdvisoryRow>
        <AdvisoryRow>
            <AdvisoryTitle style={{ color: '#565656' }} onClick={() => openInNewTab('https://nigma.global/')}>
                Nigma Conseil
            </AdvisoryTitle>
            <AdvisoryBody onClick={() => openInNewTab('https://nigma.global/')}>
                <div>Physical Security</div>
                <div>Business Intelligence</div>
                <div>Blockchain Forensics</div>
                {/* <div style={{ textDecoration: 'underline', color: '#405DF9', marginTop: 20 }} onClick={() => openInNewTab('https://nigma.global/')}>
                    About Nigma Conseil
                </div> */}
            </AdvisoryBody>
        </AdvisoryRow>
        <AdvisoryRow>
            <AdvisoryTitle onClick={() => {
                handleClick('vigilance')
            }}>
                VIGILANCE
            </AdvisoryTitle>
            <AdvisoryBody onClick={() => {
                handleClick('vigilance')
            }}>
                <div onClick={() => handleClickCard(ADVISORY_CARD_TYPES.VIGILANCE_TARGETED)}>Targeted Penetration Tests</div>
                <div onClick={() => handleClickCard(ADVISORY_CARD_TYPES.VIGILANCE_RED)}>Red Team Exercises</div>
                <div onClick={() => handleClickCard(ADVISORY_CARD_TYPES.VIGILANCE_SOURCE)}>Source Code Review Analysis</div>
                <div onClick={() => handleClickCard(ADVISORY_CARD_TYPES.VIGILANCE_RANSOMWARE)}>Ransomware Simulation</div>
            </AdvisoryBody>
        </AdvisoryRow>
        <AdvisoryRow>
            <AdvisoryTitle onClick={() => {
                handleClick('resilience')
            }}>
                RESILIENCE
            </AdvisoryTitle>
            <AdvisoryBody onClick={() => {
                handleClick('resilience')
            }}>
                <div onClick={() => handleClickCard(ADVISORY_CARD_TYPES.RESILIENCE_INCIDENT)}>Incident Response</div>
                <div onClick={() => handleClickCard(ADVISORY_CARD_TYPES.RESILIENCE_DIGITAL)}>Digital Forensics</div>
                <div onClick={() => handleClickCard(ADVISORY_CARD_TYPES.RESILIENCE_BUSINESS)}>Business Continuity Management</div>
                <div onClick={() => handleClickCard(ADVISORY_CARD_TYPES.RESILIENCE_CYBER)}>Cyber Liability Insurance</div>
            </AdvisoryBody>
        </AdvisoryRow>
    </AdvisoryNav>
}

export default AdvisoryNavItem;