import { AdvisoryButton, AdvisoryDivLineItemsMobile, FooterBackground, FooterTitle, PotechHistoryFooterAdvisory } from "../advisory/AdvisoryElement";
import { ChatIcon, ScrollTop } from "../home/HomeElements";
import { FooterLabsBackground, FooterLabsTitle, LabsBackground, LabsBody, LabsContainer, LabsGreyBackground, LabsGreyImg, LabsHead, LabsHeadContainer, LabsImageCard, LabsImageContainer, LabsImageTitle, LabsImg, LabsTextTitle, LabsTitle } from "./LabsElements";
import scrollTopIcon from "../../assets/images/ScrolltoTopIcon.png";
import chatIcon from "../../assets/images/chaticon.png";
import { AcademyTextBody, LabsButton } from "../academy/AcademyElements";
import { DarkivoreFooterImage, DarkivoreFooterNewLine, DarkivoreHistoryFooter, DigitalHeadLogo, HuntingHead } from "../darkivore/DarkivoreElements";
import LabsLogo from "../../assets/logos/LabsLogo.png";
import Labs1 from "../../assets/images/Labs1.svg";
import Labs2 from "../../assets/images/Labs2.svg";
import Labs3 from "../../assets/images/Labs3.svg";
import Labs4 from "../../assets/images/Labs4.svg";
import FooterDivider from "../../components/FooterDivider";
import potechLabsWhiteLogo from '../../assets/logos/potechLabs.png';
import { useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { Loader, WrapperDiv } from "../../components/loader";
import { Helmet } from "react-helmet";

const Labs = ({ isScrolled }) => {
    const targetSection = useSelector((state) => state.scroll.targetSection);

    useEffect(() => {
        if (targetSection === null) {
            window.scrollTo(0, 0);
        }
    }, [])
    const discoverJump = useRef(null);

    return <>
        <Helmet>
            <title>POTECH Labs: An academic award-winning research entity</title>
            <meta name="robots" content="nofollow"></meta>
            <meta name="description" content="Cybersecurity and Information & Technology Research Publications published in internationally acclaimed journals." />
        </Helmet>
        <LabsBackground>
            <LabsImg />
            <LabsTitle>
                <LabsHeadContainer>
                    <LabsTextTitle>
                        POTECH LABS
                    </LabsTextTitle>
                    {/* <AcademyTextBody>
                        An academic research entity which produces award-winning research papers in cooperation with multiple universities.
                    </AcademyTextBody>
                    <LabsButton onClick={() => { discoverJump.current.scrollIntoView({ behavior: 'smooth' })}} style={{}}>DISCOVER</LabsButton> */}
                </LabsHeadContainer>
                <LabsBody ref={discoverJump}>
                    <DigitalHeadLogo src={LabsLogo} />
                    <LabsHead>
                        Academic Research & PUBLICATIONS
                    </LabsHead>
                    <LabsContainer>
                        Our passion for Information and Technology comes hand in hand with our thirst for continuous learning.
                        <AdvisoryDivLineItemsMobile marginTop={'3vh'} />
                        That is the reason why we proudly nurture a close collaboration with academia, accompanying the relentless development of our field of business.
                        <AdvisoryDivLineItemsMobile marginTop={'3vh'} />
                        We are actively cooperating with institutions for high learning such as Université St. Joseph in Lebanon and the Lebanese University; publishing research papers that have been featured at select conferences and technical events.
                        <AdvisoryDivLineItemsMobile marginTop={'3vh'} />
                        Our very own Potech Labs dedicates its full attention to supporting such research and to issuing various essential literature in the areas of cybersecurity, as well as information & technology.
                        <AdvisoryDivLineItemsMobile marginTop={'3vh'} />
                        This is done through a multidisciplinary approach that strengthens ecosystem collaboration, always pushing and advocating the development of IT innovation projects.
                        <AdvisoryDivLineItemsMobile marginTop={'3vh'} />
                        As such, we vigorously put our technical knowledge and professional capacities at the service of students in PhD programs and other advanced studies, supporting them with the tools needed to make valuable contributions to real Information & Technology solutions.
                    </LabsContainer>
                </LabsBody>
                <ScrollTop isScrolled={isScrolled} src={scrollTopIcon} onClick={() => { window.scrollTo(0, 0) }} />
                <ChatIcon src={chatIcon} />
            </LabsTitle>
        </LabsBackground>
        <LabsGreyBackground>
            <LabsGreyImg />
            <LabsImageTitle>
                Our research contributions are published in notable international IEEE conferences and journals. They include:
            </LabsImageTitle>
            <LabsImageContainer>
                <WrapperDiv>
                    <Loader marginTop="20vh" />
                    <LabsImageCard src={Labs1} onClick={() => window.open("https://www.scitepress.org/PublishedPapers/2020/93737", '_blank', 'noopener,noreferrer')} />
                </WrapperDiv>
                <WrapperDiv>
                    <Loader marginTop="20vh" />
                    <LabsImageCard src={Labs2} onClick={() => window.open("https://ieeexplore.ieee.org/abstract/document/9148422", '_blank', 'noopener,noreferrer')} />
                </WrapperDiv>
                <WrapperDiv>
                    <Loader marginTop="20vh" />
                    <LabsImageCard src={Labs3} onClick={() => window.open("https://ieeexplore.ieee.org/abstract/document/9265528", '_blank', 'noopener,noreferrer')} />
                </WrapperDiv>
                <WrapperDiv>
                    <Loader marginTop="20vh" />
                    <LabsImageCard src={Labs4} onClick={() => window.open("https://ieeexplore.ieee.org/abstract/document/9492558", '_blank', 'noopener,noreferrer')} />
                </WrapperDiv>
            </LabsImageContainer>
        </LabsGreyBackground>
        <FooterLabsBackground>
            <FooterLabsTitle>
                <DarkivoreHistoryFooter>
                    <DarkivoreFooterImage src={potechLabsWhiteLogo} />
                    <DarkivoreFooterNewLine>
                        An academic research entity that produced award-winning research papers in cooperation with multiple universities.
                    </DarkivoreFooterNewLine>
                </DarkivoreHistoryFooter>
            </FooterLabsTitle>
        </FooterLabsBackground>
        <FooterDivider />
    </>
}

export default Labs;