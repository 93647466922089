import styled, { keyframes } from "styled-components";
import img from '../../assets/images/heroImage.png';
import waveimg from '../../assets/images/waves.png';
// import corn from '../../assets/logos/cornBlue.svg';

export const HomeBackground = styled.nav`
  width: 100%;
  height: 120vh;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  @media screen and (max-width: 800px) {
    height: auto;
  }
`;

export const HomeImg = styled.div`
  width: 100%;
  height: 120vh;
  position: absolute;
  background-image: url(${img});
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
`;

const mover = keyframes`
    0% { transform: translateY(0); }
    100% { transform: translateY(-20px) };
`;

const s1 = keyframes`
  to{transform: rotate(.5turn)};
`;

export const WaveImg = styled.div`
  width: 55%;
  height: 100%;
  position: absolute;
  background-image: url(${waveimg});
  color: white;
  right: 3%;
  top: 25%;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: -1;

  @media screen and (max-width: 800px) {
    top: 20%;
    width: 70%;
    margin-left: 15%;
    right: unset;
  }

  /* -webkit-animation: ${mover} 2s infinite alternate;
  animation: ${mover} 2s infinite alternate; */
`;

export const HomeTitle = styled.div`
  color: white;
  height: 120vh;
  padding-left: 4%;
  margin-top: 5%;
  display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: flex-start;
	align-content: stretch;
  @media screen and (max-width: 800px) {
    padding-left: 0%;
  	align-items: center;
    height: 80vh;
  }
`;

export const HomeTextTitle = styled.h1`
  font-size: 2.5vw;
  font-weight: 400;
  color: white;
  /* font-family: 'Helvetica Light'; */
  font-family: "HelveticaLt", Arial, sans-serif;
  @media screen and (max-width: 800px) {
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    word-wrap: break-word;
    box-sizing: border-box;
    text-align: center;
    padding-inline: 15%;
    margin-top: 20vh;
  }
`;
export const HomeTextBody = styled.div`
  margin-top: 15px;
  font-size: 1.57vw;
  color: white;
  /* font-family: 'Helvetica Light'; */
  font-family: "HelveticaLt", Arial, sans-serif;
  @media screen and (max-width: 800px) {
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    padding-inline: 15%;
    margin-top: 20vh;
  }
`;

export const HomeButton = styled.div`
  cursor: pointer;
  padding: 0.5%;
  padding-inline: 1.3%;
  font-size: 1vw;
  margin-top: 30px;
  background: linear-gradient(90deg, #F1AF5E 38.85%, #E88A0D 71.15%);
  text-align: center;
  border-radius: 5px;
  font-family: 'Helvetica';
  box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.20);
  @media screen and (max-width: 800px) {
    margin-top: 7vh;
    font-size: 4vw;
    padding: 2%;
    padding-inline: 5%;
  }
`;

export const ChatIcon = styled.img`
  cursor: pointer;
  position: fixed;
  width: 60px;
  bottom: 3%;
  right: 3%;
  z-index: 3;
  display: none;
  @media screen and (max-width: 800px) {
    display: ${props => props.isOpen ? "block" : "none"};
    display: none;
  }
`;

export const ScrollTop = styled.img`
  ${props => props.isScrolled ? "" : "display: none;"}
  cursor: pointer;
  position: fixed;
  width: 7vw;
  bottom: 0%;
  left: 3%;
  z-index: 2;
  @media screen and (max-width: 800px) {
    width: 100px;
    display: none;
  }
`;

export const ProductSection = styled.div`
  background: linear-gradient(90deg, #00ADBB 0%, #002F87 100%);
  height: 123vh;
  padding-top: 1vh;
  position: relative;
  display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: flex-start;
	align-content: stretch;
  &:after {
    content: "";
    position: absolute;
    top: 95vh;
    left: 50vw;
    transform: translateX(-50%);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: contain;
    width: 80px; /* Adjust the width and height of the vector image */
    height: 60px;
    z-index: -1;    
  }
  @media screen and (max-width: 800px) {
   height: auto;
   padding-top: 10vh;
   padding-inline: 8vw;
   box-sizing: border-box;
  }
`;

export const SwiperConrtainer = styled.div`
  height: 100vh;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;
export const CornImgBlue = styled.div`
  position: absolute;
  top: -45px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  @media screen and (max-width: 800px) {
    top: -4vh;
    display: none;
  }
`;

export const CornMobile = styled.div`
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  z-index: ${props => props.zIndex ?? 2};
  @media screen and (min-width: 801px) {
    display: none;
  }
`;

export const BigSection = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  @media screen and (max-width: 800px) {
    height: 100%;

  }
`;

export const Img = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url(${props => props.src});
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  background-color: rgba(186, 186, 186, 1);
  @media screen and (max-width: 800px) {
    height: 100.05%;
    background-position: center;
  }
`;

export const SectionBody = styled.div`
  display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	align-content: center;
  height: auto;
  margin-bottom: 13vh;
  @media screen and (max-width: 800px) {
    height: 100%;
    margin-bottom: 10vh;
  }
`;

export const SectionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.justifyContent ?? 'space-between'};
  text-align: center;
  align-items: center;
  width: ${props => props.width ?? '75%'};
  margin-top: ${props => props.marginTop ?? '6%'};
  /* font-family: 'Helvetica Light'; */
  font-family: "HelveticaLt", Arial, sans-serif;

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const StatisticDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  align-items: center;
  background-color: white;
  width: 14vw;
  padding: 0.5vw;
  padding-inline: 1.8vw;
  border-radius: 15px;
  box-shadow: 15px 15px 30px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  @media screen and (max-width: 1600px) {
    padding: 0.1vw;
    padding-inline: 1.8vw;
  }
  @media screen and (max-width: 800px) {
    width: 100%;
    box-shadow: none;
    box-sizing: border-box;
  }
`;

export const ServiceDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  text-align: center;
  align-items: center;
  background-color: white;
  width: 21.5vw;
  padding: 0.2vw;
  padding-inline: 0vw;
  border-radius: 10px;
  box-shadow: 15px 15px 30px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  padding-left: ${props => props.paddingLeft ?? '7%'};

  @media screen and (max-width: 1600px) {
    padding: 0.2vw;
    padding-inline: 0vw;
    box-sizing: border-box;
    padding-left: ${props => props.paddingLeft ?? '7%'};
  }
  @media screen and (max-width: 800px) {
    box-shadow: none;
    width: 100%;
    box-sizing: border-box;
    justify-content: center;
  }
`;

export const Plus = styled.span`
  font-size: 2vw;
  vertical-align: middle;
  margin-left: 0px;
  @media screen and (max-width: 800px) {
    font-size: 26px;
  }
`;

export const StatisticNumber = styled.div`
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 3vw;
  line-height: 69px;

  display: flex;
  align-items: center;
  /* Dark & Light Orange Gradient */

  background: linear-gradient(90deg, #F1AF5E 38.85%, #E88A0D 71.15%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  background-blend-mode: multiply;
  mix-blend-mode: normal;

  @media screen and (max-width: 800px) {
    font-size: 48px;
    /* margin-top: 1vh; */
  line-height: 90px;
  }
`;

export const StatisticDesc = styled.h2`
  text-align: left;
  margin-left: 5%;
  font-size: 1vw;
  font-weight: normal;
  @media screen and (max-width: 800px) {
    font-size: 16px;
    font-family: "HelveticaLt", Arial, sans-serif;
  }
`;

export const ClientDiv = styled.div`
  background: #FFFFFF;
  border-radius: 15px;

  position: relative;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: start;
	align-items: center;
	align-content: stretch;
  text-align: center;
  color: #000;
  box-shadow: 15px 15px 30px 5px rgba(0, 0, 0, 0.15);
  width: 20.7vw;
  height: 43vh;
  font-size: 0.8rem;
  padding-inline: 10px;
	/* padding-top: 1vh; */
  border-radius: 10px;
  @media screen and (max-width: 800px) {
    box-shadow: none;
    width: 75%;
    height: auto;
    left: 50%;
    transform: translateX(-50%);
    /* margin-inline: 8vw; */
    box-sizing: border-box;
  }
`;

export const ClientName = styled.div`
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: 21px;
  text-align: center;
  margin-top: 7vh;
  margin-bottom: 2vh;
  @media screen and (max-width: 800px) {
    font-size: 18px;
    margin-top: 50px;
  }
`;

export const ClientText = styled.div`
  font-size: 0.82vw;
  line-height: ${props => props.lineHeight ?? '130%'};
  font-family: "HelveticaLt", Arial, sans-serif;
  padding-inline: 0.3vw;
  margin-top: ${props => props.marginTop ?? ''};
  margin-bottom: ${props => props.marginBottom ?? ''};
  @media screen and (max-width: 800px) {
    font-size: 16px;
    line-height: 150%;
    padding-inline: 6vw;
  }
`;

export const ClientCircle = styled.div`
	position: absolute;
	top: -23%;
	left: 50%;
	width: 125px;
	height: 125px;
	transform: translateX(-50%);
	background-image: url(${(props) => props.img});
	background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-width: 1600px) {
		width: 100px;
		height: 100px;
		top: -55px;
	}
  @media screen and (max-width: 800px) {
    top: -40px;
    width: 80px;
    height: 80px;
  }
`;

export const SectionContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: stretch;
  background: #FFFFFF;
  mix-blend-mode: normal;
  width: 75%;
  height: 50%;
  margin-top: 3%;
  border-radius: 10px;
  padding-top: 8vh;
  padding-bottom: 8vh;
  box-sizing: border-box;
  /* Testimonials Box Shadow */
  box-shadow: 15px 15px 30px rgba(0, 0, 0, 0.15);
  padding-inline: 7vw;

  @media screen and (max-width: 800px) {
    height: auto;
    padding-top: 30px;
    margin-bottom: 0vh;
    margin-top: 80px;
    padding-bottom: 5vh;
  }
`;

export const SectionContainer2 = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: stretch;
  background: #FFFFFF;
  mix-blend-mode: normal;
  width: 75%;
  height: 55%;
  padding-top: 8vh;
  padding-bottom: 8vh;
  margin-top: 9vh;
  border-radius: 10px;
  /* Testimonials Box Shadow */
  box-sizing: border-box;
  box-shadow: 15px 15px 30px rgba(0, 0, 0, 0.15);
  padding-inline: 7vw;

  @media screen and (max-width: 800px) {
    height: auto;
    padding-top: 30px;
    margin-bottom: 0vh;
    margin-top: 80px;
    padding-bottom: 5vh;
  }
`;

export const CartContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: ${props => props.justifyContent ?? 'space-between'};
	align-items: center;
	align-content: stretch;
  width: 100%;
  margin-top: 5%;
  margin-bottom: 7%;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    margin-top: 3vh;
    margin-bottom: unset;
  }
`;

export const Cart = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	align-content: stretch;
  height: 100%;
  width: 23.7%;
  position: relative;
  z-index: 1;
  border-radius: 10px;
  margin-inline: ${props => props.marginInline ?? ''};
  /* Drop Shadow Style */
  box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.2);
  @media screen and (max-width: 800px) {
    width: 100%;
    margin-top: 5vh;
  }
`;

export const CartHeader = styled.h2`
  font-style: normal;
  font-weight: 400;
  font-size: 1.4vw;
  line-height: 33px;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  /* Potech Dark Blue */
  font-family: "HelveticaLt", Arial, sans-serif;
  color: #002F87;
  @media screen and (max-width: 800px) {
    font-size: 20px;
    padding-top: 4%;
    padding-bottom: 4%;
  }
`;

export const CartImage = styled.img`
  width: 100%;
  min-height: 15vh;
  z-index: 1;
  /* &::before {
    width:50px;
    height:50px;
    border-radius:50%;
    border:8px solid;
    border-color:#766DF4 #0000;
    background-color: red;
    animation:${s1} 1s infinite;
  } */
`;

export const CartBody = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 0.9vw;
  width: 100%;
  height: 26vh;
  line-height: 150%;
  /* padding: 5%; */
  padding: 3vh;
  padding-top: 2.5vh;
  box-sizing: border-box;
  /* background-color: red; */
  font-family: "HelveticaLt", Arial, sans-serif;
  @media screen and (max-width: 800px) {
    font-size: 14px;
    line-height: 142.857%;
    padding-bottom: 0%;
    /* margin-top: 3vh; */
    margin-bottom: 3vh;
    height: auto;
    box-sizing: border-box;
  }
`;

export const CartServiceBody = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 0.9vw;
  width: 100%;
  height: 22vh;
  /* line-height: 150%; */
  /* padding: 5%; */
  padding: 2.1vh;
  padding-top: 2vh;
  box-sizing: border-box;
  font-family: "HelveticaLt", Arial, sans-serif;
  @media screen and (max-width: 800px) {
    font-size: 14px;
    line-height: 142.857%;
    padding-bottom: 0%;
    padding-top: unset;
    margin-top: 3vh;
    margin-bottom: 3vh;
    height: auto;
    box-sizing: border-box;
  }
`;

export const SwiperMobile = styled.div`
  margin-top: 70px;
  position: relative;
  @media screen and (min-width: 801px){
    display: none;
  }
`;

export const TrustedBy = styled.h1`

  margin-top: 6.5vh;
  font-size: 1.5vw;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  font-family: "HelveticaLt", Arial, sans-serif;
  color: var(--Black, #000);

  @media screen and (max-width: 800px) {
  /* Resp-Sub-title */
    font-size: 20px;
    margin-top: 80px;
  }
`;

export const OurClient = styled.div`
  margin-top: 3vh;
  font-size: 0.95vw;
  font-family: "HelveticaLt", Arial, sans-serif;
  color: var(--Black, #000);

  @media screen and (max-width: 800px) {
    text-align: center;
    margin-top: 7%;
    /* Body 01 */
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    padding-inline: 20%;
    line-height: 138.889%;
  }
`;

export const BigSectionTitleMobile = styled.h1`
  font-size: 0.9vw;
  color: #002F87;
  margin-bottom: 1%;
  font-family: Helvetica;
  margin-top: 0;
  font-weight: normal;
  @media screen and (max-width: 800px) {
    font-size: 14px;
    margin-bottom: unset;
  }
`;

export const BigSectionSubTitleMobile = styled.h2`
  font-size: 1.5vw;
  color: #002F87;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  font-family: "HelveticaLt", Arial, sans-serif;
  @media screen and (max-width: 800px) {
    font-size: 20px;
    margin-top: 42px;
    text-align: center;
    padding-inline: 4vw;
  }
`;

export const BigSectionSubTitleMobile2 = styled.div`
  font-size: 1.5vw;
  color: #002F87;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  font-family: "HelveticaLt", Arial, sans-serif;
  @media screen and (max-width: 800px) {
    font-size: 20px;
    margin-top: 42px;
    text-align: center;
    padding-inline: 3vw;
  }
`;

export const BigSectionDescMobile = styled.div`
  font-size: 0.95vw;
  color: #000000;
  text-align: center;
  margin-top: 5%;
  font-family: "HelveticaLt", Arial, sans-serif;
  margin-bottom: 1%;
  @media screen and (max-width: 800px) {
    font-size: 16px;
    line-height: 156.25%;
    margin-top: 40px;
    padding-inline: 3vw;
  }
`;

export const ProudlyServe = styled.div`
  margin-top: 10vh;
  font-size: 1.4vw;
  color: white;
  text-align: center;
  /* Resp-Sub-title */
  font-family: "HelveticaLt", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  @media screen and (max-width: 800px) {
    font-size: 20px;
    margin-top: 10vh;
  }
`;

export const ServeDesc = styled.div`
  margin-top: 3vh;
  font-size: 0.9vw;
  color: white;
  text-align: center;
  font-family: "HelveticaLt", Arial, sans-serif;

  @media screen and (max-width: 800px) {
    text-align: center;
    margin-top: 7%;
    /* Body 01 */
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    padding-inline: 10%;
    line-height: 138.889%;
  }
`;