import styled from "styled-components";

export const InfoDiv = styled.img`
  position: relative;
  width: 14px;
  @media screen and (max-width: 1600px) {
    width: 12px;
  }
  @media screen and (max-width: 800px) {
    width: 24.635px;
  }
`;

export const InfoSpan = styled.div`
  visibility: ${(props) => props.isHovered ? 'visible' : 'hidden'};
  text-align: center;
  box-shadow: 15px 15px 30px 5px rgba(0, 0, 0, 0.15);
  position: absolute;
  background-color: white;
  color: black;
  width: 15vw;
  border-radius: 10px;
  font-size: 0.8vw;
  padding: 20px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 60px;
  top: -60px;
  line-height: 15px;
  @media screen and (max-width: 800px) {
    right: 100%;
    left: auto;
    font-size: 14px;
    margin-top: 50%;
    width: 50vw;
    line-height: 140%;
  }
`;