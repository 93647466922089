import styled from "styled-components";

export const MembersContainer = styled.div`
    border-radius: 12px;
    background: var(--white, #FFF);
    /* Drop Shadow Style */
    color: var(--potech-dark-blue, #002F87);
    text-align: center;
    /* Expandable Sub Title */
    font-size: 0.9vw;
    font-style: normal;
    line-height: normal;
    /* width: 276px; */
    //height: ${(props) => props.active ? '27vh' : '22vh'};
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-inline: 1%;
    cursor: pointer;
`;

export const ModuleBorderWrap = styled.div`
    position: relative;

    background: ${(props) => props.active ? 'linear-gradient(90deg, #00ADBB 0%, #002F87 100%)' : 'none'};
    padding: ${props => props.active ? '3px' : '0px'};
    border-radius: 15px;
    /* Testimonials Box Shadow */
    box-shadow: 10px 10px 15px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    /* Expandable Sub Title */
    font-size: 1.5vw;
    font-style: normal;
    line-height: normal;
    /* width: 276px; */
    width: 20vw;
    height: ${(props) => props.active ? '230px' : '200px'};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    cursor: pointer;

    @media screen and (max-width: 800px) {
        width: 100%;
        margin-bottom: 50px;
        margin-top: 50px;
    }
`;

export const LogoContainer = styled.div`
    overflow: hidden;
    position: absolute;
    left: 50%;
    width: 110px;
    height: 110px;
    transform: translateX(-50%);
    top: -55px;


    background-blend-mode: normal, multiply;
    filter: drop-shadow(4px 3px 2px rgba(0, 0, 0, 0.2));
    border-radius: 50%; /* Make image round */
    @media screen and (max-width: 800px) {
        //width: 100px;
        //height: 100px;     
    }
`;

export const MembersLogo = styled.img`
    width: 100%; /* Ensure the image fills the container horizontally */
    height: 100%; /* Ensure the image fills the container vertically */
    object-fit: contain; /* Maintain aspect ratio while covering the container */
    border-radius: 50%;
    //transform: scale(1.5);
    //margin-top: 15px;
`;

export const MembersName = styled.div`
    color: var(--black, #000);
    text-align: center;
    font-family: Helvetica;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 1vh;
    //margin-top: 5vh;
`;

export const MembersPosition = styled.div`
    color: var(--black, #000);
    text-align: center;
	font-family: "HelveticaLt", Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

export const MembersCardBody = styled.div`
    padding-top: ${(props) => props.active ? '50px' : '55px'};
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: ${(props) => props.active ? 'center' : 'center'};
    text-align: center;
    align-items: center;
    padding-inline: 2%;
`;