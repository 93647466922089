import { AdvisoryBodyMobile, AdvisoryButton, AdvisoryDivBodyItemsMobile, AdvisoryDivHeaderItemsMobile, AdvisoryDivItemsMobile, AdvisoryDivLineItemsMobile, AdvisoryTextItemsMobile, FooterBackground, FooterTitle, PotechHistoryFooterAdvisory } from "../advisory/AdvisoryElement";
import { ChatIcon, ScrollTop, SwiperMobile } from "../home/HomeElements";
import { AdvancedImg, AdvantageDivItemsMobile, Desktop, FeatureCard, FeatureDesc, FeatureRow, FeatureTitle, Mobile, NumImg, ProblemBody, ProblemContainer, ProblemContainerTextBody, ProblemContainerTextBottom, ProblemContainerTextHead, ProblemDiv, ProblemDivSection1, ProblemDivSection2, ProblemImg, SiemBody, SiemHead, SolImg, SwiperTaceMobile, TaceFeatureImg, TaceFeatureLogo, TaceNum, TaceNumBottomDiv, TaceNumBottomText, TaceNumBottomTextMobile, TaceNumDesc, TaceNumDiv, TaceNumLogo, TacivoarAdvantageBackground, TacivoarAdvantageBody, TacivoarAdvantageBottom, TacivoarAdvantageContainerDesk, TacivoarAdvantageDemoImage, TacivoarAdvantageDemoText, TacivoarAdvantageHead, TacivoarAdvantageTitle, TacivoarBackground, TacivoarBodyBackground, TacivoarBodyTitle, TacivoarButton, TacivoarDemoImage, TacivoarDivBodyItemsMobile, TacivoarDivHeaderItemsMobile, TacivoarFeatureBackground, TacivoarFeatureHeadText, TacivoarFeatureTitle, TacivoarImg, TacivoarLogo, TacivoarNumBackground, TacivoarNumContainer, TacivoarNumHeadText, TacivoarNumTitle, TacivoarProblemHead, TacivoarTextTitle, TacivoarTitle, TacivorDemoContainer, TacivorDemoDiv, TacivoreDemoHead, UlTaceSwiper } from "./TacivoreElements";
import TacivoarWhiteLogo from '../../assets/logos/TacivoarWhiteLogo.png';
import scrollTopIcon from "../../assets/images/ScrolltoTopIcon.png";
import chatIcon from "../../assets/images/chaticon.png";
import { useEffect, useRef } from "react";
import { DarkivoreFooterImage, DarkivoreFooterNewLine, DarkivoreHistoryFooter, DigitalHeadLogo, FooterDarkivoreBackground, FooterDarkivoreTitle, HuntingBackground, HuntingHead, HuntingHeadLogo, HuntingText, HuntingTitle, HuntingeBody, UlSwiper } from "../darkivore/DarkivoreElements";
import solLogo from '../../assets/logos/neuralNetworkIcon.png';
import tacivoarDemo from '../../assets/images/Tacivoar-First-Mock-Up.png';
import whiteDanger from '../../assets/logos/whiteDanger.png';
import whiteLike from '../../assets/logos/whiteLike.png';
import TacivoareCard from "../../components/TacivoarCard";
import streamData from '../../assets/logos/streamData.png';
import generation from '../../assets/logos/generation.png';
import hunting from '../../assets/logos/hunting.png';
import eps from '../../assets/logos/eps.png';
import diverseData from '../../assets/logos/diverseData.png';
import parsing from '../../assets/logos/parsing.png';
import agile from '../../assets/logos/agile.png';
import vulManagement from '../../assets/logos/vulManagement.png';
import ueba from '../../assets/logos/ueba.png';
import compliance from '../../assets/logos/compliance.png';
import { useState } from "react";
import { AdvantageLogo, ModuleBorderWrap, ModuleTacivoarBorderWrap, ModuleTacivoarBorderWrapBody, ModuleTacivoarBorderWrapText, TacivoarBodyMobile } from "../../components/TacivoarCard/TacivoarCardElements";
import TaceDemo from '../../assets/images/Tace-Demo.png';
import blueAlert from '../../assets/logos/blueAlert.png';
import blueRetry from '../../assets/logos/blueRetry.png';
import bluePuzzle from '../../assets/logos/bluePuzzle.png';
import blueLogout from '../../assets/logos/blueLogout.png';
import thidesoftLogo from '../../assets/logos/Thidesoft Logo Horizontal White.png';
import FooterDivider from "../../components/FooterDivider";
import { useSelector } from "react-redux";

import KillchainLogo from '../../assets/logos/killChainLogo.png';
import uebaLogo from '../../assets/logos/ubeaLogo.png';
import agileLogo from '../../assets/logos/AgileParsingLogo.png';
import vulnerabilityLogo from '../../assets/logos/VulnerableLogo.png';
import reportLogo from '../../assets/logos/ReportLogo.png';
import threatLogo from '../../assets/logos/ThreatLogo.png';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import "../home/swiperStyle.css";

// import required modules
import { Pagination } from "swiper";
import { Loader, WrapperDiv } from "../../components/loader";
import { ContainerButton, ProductMobileButton } from "../../components/ProductElements";
import { Helmet } from "react-helmet";

const Tacivoar = ({ isScrolled, setIsProductactive }) => {

    const [cardIndex, setCardIndex] = useState(null);
    const [text, setText] = useState(null);
    const targetSection = useSelector((state) => state.scroll.targetSection);

    useEffect(() => {
        setIsProductactive(true);
        if (targetSection === null) {
            window.scrollTo(0, 0);
        }
        return () => {
            setIsProductactive(false);
        }
    }, [])

    const streamLineText = <div>
        Pre-process events at scale, reducing event noise before reaching the engine, and consequently lowering unneeded storage.
    </div>

    const implementText = <div>
        Implement automated enrichment playbooks to combat analysis fatigue and extract meaningful insights from every record.
    </div>

    const pivotText = <div>
        Pivot between collected artifacts in real-time, eliminating the need for ad-hoc tools to visualize MITRE Patterns.
    </div>

    const scaleText = <div>
        Scale resources dynamically as per growth needs by clustering and expanding the platform on multiple instances.
    </div>

    const smartText = <div>
        TACIVOAR smart bots crunch any kind of data source, including APIs, custom data formats, HTML Audit Events, and more.
    </div>

    const dataText = <div>
        Utilize ultra-flexible parsing with smart regex and versatile data connectors to process any machine data.
    </div>

    const leverageText = <div>
        Leverage built-in NDR capabilities to provide remarkably elastic Network Traffic Monitoring.
    </div>

    const eliminateText = <div>
        Eliminate the need for integration of third-party tools with our built-in vulnerability management capability.
    </div>

    const harnessText = <div>
        Harness statistical and machine learning models to correlate various behaviors such as user & entity, application/database, and network activities, aiding in the detection of potential security threats.
    </div>

    const generateText = <div>
        Generate On-the-Fly Compliance Reports with the support of Dedicated Relational Databases for precise reporting.
    </div>

    const AdvantageItems = <>
        <AdvantageDivItemsMobile>
            <AdvantageLogo src={streamData} />
            <TacivoarDivHeaderItemsMobile color={'#002F87'}>
                Streamline Data Processing
            </TacivoarDivHeaderItemsMobile>
            <TacivoarDivBodyItemsMobile>
                {streamLineText}
            </TacivoarDivBodyItemsMobile>
        </AdvantageDivItemsMobile>

        <AdvantageDivItemsMobile>
            <AdvantageLogo src={generation} />
            <TacivoarDivHeaderItemsMobile color={'#002F87'}>
                Accelerate Insight Generation
            </TacivoarDivHeaderItemsMobile>
            <TacivoarDivBodyItemsMobile>
                {implementText}
            </TacivoarDivBodyItemsMobile>
        </AdvantageDivItemsMobile>

        <AdvantageDivItemsMobile>
            <AdvantageLogo src={hunting} />
            <TacivoarDivHeaderItemsMobile color={'#002F87'}>
                Empower Realtime Threat Hunting
            </TacivoarDivHeaderItemsMobile>
            <TacivoarDivBodyItemsMobile>
                {pivotText}
            </TacivoarDivBodyItemsMobile>
        </AdvantageDivItemsMobile>

        <AdvantageDivItemsMobile>
            <AdvantageLogo src={eps} />
            <TacivoarDivHeaderItemsMobile color={'#002F87'}>
                Scale Infinitely with Unlimited EPS
            </TacivoarDivHeaderItemsMobile>
            <TacivoarDivBodyItemsMobile>
                {scaleText}
            </TacivoarDivBodyItemsMobile>
        </AdvantageDivItemsMobile>

        <AdvantageDivItemsMobile>
            <AdvantageLogo src={diverseData} />

            <TacivoarDivHeaderItemsMobile color={'#002F87'}>
                Seamlessly Connect Diverse Data
            </TacivoarDivHeaderItemsMobile>
            <TacivoarDivBodyItemsMobile>
                {smartText}
            </TacivoarDivBodyItemsMobile>
        </AdvantageDivItemsMobile>

        <AdvantageDivItemsMobile>
            <AdvantageLogo src={parsing} />

            <TacivoarDivHeaderItemsMobile color={'#002F87'}>
                Enhance Parsing Flexibility
            </TacivoarDivHeaderItemsMobile>
            <TacivoarDivBodyItemsMobile>
                {dataText}
            </TacivoarDivBodyItemsMobile>
        </AdvantageDivItemsMobile>

        <AdvantageDivItemsMobile>
            <AdvantageLogo src={agile} />

            <TacivoarDivHeaderItemsMobile color={'#002F87'}>
                Built-In NDR for Agile Monitoring
            </TacivoarDivHeaderItemsMobile>
            <TacivoarDivBodyItemsMobile>
                {leverageText}
            </TacivoarDivBodyItemsMobile>
        </AdvantageDivItemsMobile>

        <AdvantageDivItemsMobile>
            <AdvantageLogo src={vulManagement} />

            <TacivoarDivHeaderItemsMobile color={'#002F87'}>
                Simplify Vulnerability Management
            </TacivoarDivHeaderItemsMobile>
            <TacivoarDivBodyItemsMobile>
                {eliminateText}
            </TacivoarDivBodyItemsMobile>
        </AdvantageDivItemsMobile>

        <AdvantageDivItemsMobile>
            <AdvantageLogo src={ueba} />

            <TacivoarDivHeaderItemsMobile color={'#002F87'}>
                Detect Threats with Advanced UEBA
            </TacivoarDivHeaderItemsMobile>
            <TacivoarDivBodyItemsMobile>
                {harnessText}
            </TacivoarDivBodyItemsMobile>
        </AdvantageDivItemsMobile>

        <AdvantageDivItemsMobile>
            <AdvantageLogo src={compliance} />

            <TacivoarDivHeaderItemsMobile color={'#002F87'}>
                Effortless Compliance Insights
            </TacivoarDivHeaderItemsMobile>
            <TacivoarDivBodyItemsMobile>
                {generateText}
            </TacivoarDivBodyItemsMobile>
        </AdvantageDivItemsMobile>

    </>
    const discoverJump = useRef(null);

    return <>
        <Helmet>
            <title>Tacivoar: Knowledge, at the First Lines of Defense</title>
            <meta name="description" content="A revolutionary SIEM++ solution empowering organizations with full control of their IT and Cloud infrastructures." />
        </Helmet>
        <TacivoarBackground>
            <TacivoarImg />
            <TacivoarTitle>
                <TacivoarLogo src={TacivoarWhiteLogo} />
                <TacivoarTextTitle>
                    KNOWLEDGE, AT THE FIRST LINES OF DEFENSE
                </TacivoarTextTitle>
                <TacivoarButton onClick={() => { discoverJump.current.scrollIntoView({ behavior: 'smooth' }) }}>DISCOVER</TacivoarButton>
                <ContainerButton marginTop={'6vh'}>
                    <ProductMobileButton href="/documents/TacivoarDatasheet.pdf" target="_blank" width={'14vw'} padding={'1%'}>DOWNLOAD DATASHEET</ProductMobileButton>
                    <ProductMobileButton href="mailto:info@potech.global" width={'14vw'} padding={'1%'}>REQUEST A DEMO</ProductMobileButton>
                </ContainerButton>
                <ScrollTop isScrolled={isScrolled} src={scrollTopIcon} onClick={() => { window.scrollTo(0, 0) }} />
                <ChatIcon src={chatIcon} />
            </TacivoarTitle>
        </TacivoarBackground>
        <HuntingBackground height={'145vh'} ref={discoverJump}>
            <SolImg />
            <HuntingTitle height={'165vh'}>
                <SiemBody>
                    <HuntingHeadLogo src={solLogo} />
                    <div>
                        <SiemHead>
                            A SIEM++ solution
                        </SiemHead>
                        <AdvisoryDivLineItemsMobile marginTop={'5vh'} />
                        <HuntingText textAlign={'start'} width={'60vw'}>
                            TACIVOAR is the revolutionary SIEM++ solution, born to empower organizations by allowing them to hold full control of their IT and Cloud infrastructures.
                            <AdvisoryDivLineItemsMobile marginTop={'3vh'} />
                            Easily integratable with any data source with unparalleled flexibility, TACIVOAR’s optimized engineering goes beyond traditional SIEMs.
                            <AdvisoryDivLineItemsMobile marginTop={'3vh'} />
                            It provides advanced filtering and correlation capabilities, monitoring all different components of the information system and generating contextual alerts to counter any security incident.
                        </HuntingText>
                    </div>

                    <TacivorDemoContainer className="container-2">
                        <WrapperDiv>
                            <Loader marginTop="10%" />
                            <TacivoarDemoImage src={tacivoarDemo} />
                        </WrapperDiv>
                        <TacivorDemoDiv>
                            <TacivoreDemoHead>
                                The platform combats the ever-evolving landscape of cybersecurity threats through built-in:
                            </TacivoreDemoHead>
                            <AdvisoryDivLineItemsMobile />
                            <UlSwiper>
                                <li>
                                    Network Detection and Response (NDR)
                                </li>
                                <li>
                                    Vulnerability Management
                                </li>
                                <li>
                                    User and Entity Behavior Analysis (UEBA)
                                </li>
                                <li>
                                    Security Orchestration, Automation, and Response (SOAR)
                                </li>
                            </UlSwiper>
                        </TacivorDemoDiv>
                    </TacivorDemoContainer>
                </SiemBody>
            </HuntingTitle>
        </HuntingBackground>
        <TacivoarBodyBackground height={'117vh'}>
            <ProblemImg />
            <TacivoarBodyTitle height={'120vh'}>
                <ProblemBody>
                    <DigitalHeadLogo src={whiteDanger} />
                    <TacivoarProblemHead>
                        THE PROBLEM
                    </TacivoarProblemHead>
                    <ProblemContainer>
                        <ProblemContainerTextHead>
                            In a rapidly evolving digital landscape, traditional SIEMs lack smart connectors and compatibility with many APIs. This makes threat detection a slow and arduous process, especially considering the increasingly unmanageable volume of data and the limited processing power.
                        </ProblemContainerTextHead>
                        <ProblemContainerTextBody>
                            Traditional SIEMs have become more of a historical archive than a practical tool for incident management.
                        </ProblemContainerTextBody>
                    </ProblemContainer>
                    <ProblemContainerTextBottom>
                        Significant delays in identifying risks and responding to threats are pushing analysts to resort to manual threat hunting or rely on disparate SOC Tools & Services such as XDR / MDR, precisely during critical missions that demand swift action. This is adding more sophistication to the already complex task of incident management.
                    </ProblemContainerTextBottom>
                    <Desktop>
                        <ProblemDiv>
                            <ProblemDivSection1>
                                With unparalleled flexibility,
                                swift threat-hunting and
                                contextual data correlation,
                            </ProblemDivSection1>
                            <ProblemDivSection2>
                                TACIVOAR empowers SOC teams to respond in full agility even through massive data lakes.
                            </ProblemDivSection2>
                        </ProblemDiv>
                    </Desktop>

                    <Mobile>
                        <AdvisoryDivLineItemsMobile marginTop={'4vh'} />

                        <ProblemDiv>
                            <ProblemDivSection1>
                                With unparalleled flexibility,
                                swift threat-hunting and
                                contextual data correlation,
                            </ProblemDivSection1>
                        </ProblemDiv>
                        <ProblemDiv>
                            <ProblemDivSection2>
                                TACIVOAR empowers SOC teams to respond in full agility even through massive data lakes.
                            </ProblemDivSection2>
                        </ProblemDiv>
                    </Mobile>
                </ProblemBody>
            </TacivoarBodyTitle>
        </TacivoarBodyBackground>
        <TacivoarAdvantageBackground height={'165vh'}>
            <AdvancedImg />
            <TacivoarAdvantageTitle height={'168vh'}>
                <TacivoarAdvantageBody height={text ? '105vh' : '80vh'}>
                    <DigitalHeadLogo src={whiteLike} />
                    <TacivoarAdvantageHead marginTop={text ? '2vh' : '2vh'}>
                        ADVANTAGES
                    </TacivoarAdvantageHead>
                    <TacivoarAdvantageContainerDesk>
                        <TacivoareCard text={"Streamline Data Processing"} clickHandler={() => {
                            if (cardIndex === 1) {
                                setCardIndex(null);
                                setText(null);
                            }
                            else {
                                setCardIndex(1);
                                setText(streamLineText)
                            }

                        }} active={cardIndex === 1} logo={streamData} />

                        <TacivoareCard text={"Accelerate Insight Generation"} clickHandler={() => {
                            if (cardIndex === 2) {
                                setCardIndex(null);
                                setText(null);
                            }
                            else {
                                setCardIndex(2);
                                setText(implementText)
                            }

                        }} active={cardIndex === 2} logo={generation} />

                        <TacivoareCard text={"Empower Realtime Threat Hunting"} clickHandler={() => {
                            if (cardIndex === 3) {
                                setCardIndex(null);
                                setText(null);
                            }
                            else {
                                setCardIndex(3);
                                setText(pivotText)
                            }

                        }} active={cardIndex === 3} logo={hunting} />

                        <TacivoareCard text={"Scale Infinitely with Unlimited EPS"} clickHandler={() => {
                            if (cardIndex === 4) {
                                setCardIndex(null);
                                setText(null);
                            }
                            else {
                                setCardIndex(4);
                                setText(scaleText)
                            }

                        }} active={cardIndex === 4} logo={eps} />

                        <TacivoareCard text={"Seamlessly Connect Diverse Data"} clickHandler={() => {
                            if (cardIndex === 5) {
                                setCardIndex(null);
                                setText(null);
                            }
                            else {
                                setCardIndex(5);
                                setText(smartText)
                            }

                        }} active={cardIndex === 5} logo={diverseData} />

                    </TacivoarAdvantageContainerDesk>
                    <TacivoarBodyMobile>
                        {AdvantageItems}
                    </TacivoarBodyMobile>

                    {text && cardIndex <= 5 &&
                        <ModuleTacivoarBorderWrap active={true}>
                            <ModuleTacivoarBorderWrapBody>
                                <ModuleTacivoarBorderWrapText>
                                    {text}
                                </ModuleTacivoarBorderWrapText>
                            </ModuleTacivoarBorderWrapBody>
                        </ModuleTacivoarBorderWrap>
                    }

                    <TacivoarAdvantageContainerDesk>

                        <TacivoareCard text={"Enhance Parsing Flexibility"} clickHandler={() => {
                            if (cardIndex === 6) {
                                setCardIndex(null);
                                setText(null);
                            }
                            else {
                                setCardIndex(6);
                                setText(dataText)
                            }

                        }} active={cardIndex === 6} logo={parsing} />

                        <TacivoareCard text={"Built-In NDR for Agile Monitoring"} clickHandler={() => {
                            if (cardIndex === 7) {
                                setCardIndex(null);
                                setText(null);
                            }
                            else {
                                setCardIndex(7);
                                setText(leverageText)
                            }

                        }} active={cardIndex === 7} logo={agile} />

                        <TacivoareCard text={"Simplify Vulnerability Management"} clickHandler={() => {
                            if (cardIndex === 8) {
                                setCardIndex(null);
                                setText(null);
                            }
                            else {
                                setCardIndex(8);
                                setText(eliminateText)
                            }

                        }} active={cardIndex === 8} logo={vulManagement} />

                        <TacivoareCard text={"Detect Threats with Advanced UEBA"} clickHandler={() => {
                            if (cardIndex === 9) {
                                setCardIndex(null);
                                setText(null);
                            }
                            else {
                                setCardIndex(9);
                                setText(harnessText)
                            }

                        }} active={cardIndex === 9} logo={ueba} />

                        <TacivoareCard text={"Effortless Compliance Insights"} clickHandler={() => {
                            if (cardIndex === 10) {
                                setCardIndex(null);
                                setText(null);
                            }
                            else {
                                setCardIndex(10);
                                setText(generateText)
                            }

                        }} active={cardIndex === 10} logo={compliance} />

                    </TacivoarAdvantageContainerDesk>

                    {text && cardIndex > 5 &&
                        <ModuleTacivoarBorderWrap active={true}>
                            <ModuleTacivoarBorderWrapBody>
                                <ModuleTacivoarBorderWrapText>
                                    {text}
                                </ModuleTacivoarBorderWrapText>
                            </ModuleTacivoarBorderWrapBody>
                        </ModuleTacivoarBorderWrap>
                    }
                </TacivoarAdvantageBody>
                <TacivoarAdvantageBottom>
                    <WrapperDiv>
                        <Loader marginTop="15vh" />
                        <TacivoarAdvantageDemoImage src={TaceDemo} />
                    </WrapperDiv>
                    <TacivoarAdvantageDemoText>
                        TACIVOAR brings the power of knowledge to the frontlines.
                    </TacivoarAdvantageDemoText>
                </TacivoarAdvantageBottom>
            </TacivoarAdvantageTitle>

        </TacivoarAdvantageBackground>
        <TacivoarNumBackground>
            <NumImg />
            <TacivoarNumTitle>
                <TacivoarNumHeadText>
                    TACIVOAR IN NUMBERS
                </TacivoarNumHeadText>
                <TacivoarNumContainer>
                    <TaceNumDiv>
                        <TaceNumLogo src={blueAlert} />
                        <TaceNum>
                            500+
                        </TaceNum>
                        <TaceNumDesc>
                            Alerting Rules
                        </TaceNumDesc>
                    </TaceNumDiv>
                    <TaceNumDiv>
                        <TaceNumLogo src={blueRetry} />
                        <TaceNum>
                            50,000+
                        </TaceNum>
                        <TaceNumDesc>
                            Events Per Second <br />
                            Handling Capability
                        </TaceNumDesc>
                    </TaceNumDiv>
                    <TaceNumDiv>
                        <TaceNumLogo src={bluePuzzle} />
                        <TaceNum>
                            50+
                        </TaceNum>
                        <TaceNumDesc>
                            Integrations with Third-Party <br />
                            Threat Intelligence Sources
                        </TaceNumDesc>
                    </TaceNumDiv>
                    <TaceNumDiv>
                        <TaceNumLogo src={blueLogout} />
                        <TaceNum>
                            100+
                        </TaceNum>
                        <TaceNumDesc>
                            Log Sources
                        </TaceNumDesc>
                    </TaceNumDiv>
                </TacivoarNumContainer>
                {/* swiper mobile */}

                <SwiperTaceMobile className="container-tace">
                    <Swiper
                        style={{
                            width: "100%",
                            paddingInline: '8vw',
                            boxSizing: 'border-box',
                            paddingTop: '5vh',
                            filter: 'drop-shadow(15px 15px 30px rgba(0, 0, 0, 0.15))'
                        }}
                        slidesPerView={1.01}
                        // centeredSlides={true}
                        navigation
                        spaceBetween={20}
                        pagination={{
                            el: '.tacivoar-page',
                            clickable: true
                        }}
                        modules={[Pagination]}
                        className="tacivoarSwiper"
                        scrollbar={{ draggable: true }}
                    >
                        <SwiperSlide>
                            <TaceNumDiv>
                                <TaceNumLogo src={blueAlert} />
                                <TaceNum>
                                    500+
                                </TaceNum>
                                <TaceNumDesc>
                                    Alerting Rules
                                </TaceNumDesc>
                            </TaceNumDiv>
                        </SwiperSlide>
                        <SwiperSlide>
                            <TaceNumDiv>
                                <TaceNumLogo src={blueRetry} />
                                <TaceNum>
                                    50,000+
                                </TaceNum>
                                <TaceNumDesc>
                                    Events Per Second <br />
                                    Handling Capability
                                </TaceNumDesc>
                            </TaceNumDiv>
                        </SwiperSlide>
                        <SwiperSlide>
                            <TaceNumDiv>
                                <TaceNumLogo src={bluePuzzle} />
                                <TaceNum>
                                    50+
                                </TaceNum>
                                <TaceNumDesc>
                                    Integrations with Third-Party <br />
                                    Threat Intelligence Sources
                                </TaceNumDesc>
                            </TaceNumDiv>
                        </SwiperSlide>
                        <SwiperSlide>
                            <TaceNumDiv>
                                <TaceNumLogo src={blueLogout} />
                                <TaceNum>
                                    100+
                                </TaceNum>
                                <TaceNumDesc>
                                    Log Sources
                                </TaceNumDesc>
                            </TaceNumDiv>
                        </SwiperSlide>
                    </Swiper>

                    <div className="tacivoar-page" />
                </SwiperTaceMobile>

                <TaceNumBottomDiv>
                    <TaceNumBottomText>
                        {`
With over 500 alerting rules, TACIVOAR can process over 50,000 events per second.
This makes it one of the most powerful solutions available today!
With 50+ integrations with third-party threat intelligence sources and 100+ log sources, TACIVOAR gives you full visibility of your
network and empowers you to take proactive steps to protect your organization. 
                            `}
                    </TaceNumBottomText>
                    <TaceNumBottomTextMobile>
                        With over 500 alerting rules, TACIVOAR can process over 50,000 events per second.
                        <AdvisoryDivLineItemsMobile />
                        This makes it one of the most powerful solutions available today!
                        <AdvisoryDivLineItemsMobile />
                        With 50+ integrations with third-party threat intelligence sources and 100+ log sources, TACIVOAR gives you full visibility of your
                        network and empowers you to take proactive steps to protect your organization.
                    </TaceNumBottomTextMobile>
                </TaceNumBottomDiv>
            </TacivoarNumTitle>
        </TacivoarNumBackground>
        <TacivoarFeatureBackground>
            <TaceFeatureImg />
            <TacivoarFeatureTitle>
                <TacivoarFeatureHeadText>
                    FEATURES & CAPABILITIES
                </TacivoarFeatureHeadText>
                <FeatureRow>
                    <FeatureCard>
                        <TaceFeatureLogo src={KillchainLogo} />
                        <FeatureTitle>
                            Alerts and Killchain Overview
                        </FeatureTitle>
                        <FeatureDesc>
                            <ul>
                                <li>
                                    Top noticeable attacks
                                </li>
                                <li>
                                    Quick alert drill-down
                                </li>
                                <li>
                                    MITRE alignment
                                </li>
                                <li>
                                    SOC analyst assistant
                                </li>
                            </ul>
                        </FeatureDesc>
                    </FeatureCard>
                    <FeatureCard>
                        <TaceFeatureLogo src={uebaLogo} />
                        <FeatureTitle>
                            UEBA
                        </FeatureTitle>
                        <FeatureDesc>
                            <ul>
                                <li>
                                    Pre-processing and post-processing <br />correlation
                                </li>
                                <li>
                                    Built-in threat detection
                                </li>
                                <li>
                                    User/Network behavior analytics
                                </li>
                                <li>
                                    Flexible fine-tuning and visualization
                                </li>
                            </ul>
                        </FeatureDesc>
                    </FeatureCard>
                    <FeatureCard>
                        <TaceFeatureLogo src={agileLogo} />
                        <FeatureTitle>
                            Agile Parsing
                        </FeatureTitle>
                        <FeatureDesc>
                            <ul>
                                <li>
                                    Versatile integration
                                </li>
                                <li>
                                    Smart crawling for unorthodox logs
                                </li>
                                <li>
                                    All log types ingestion and visualization
                                </li>
                                <li>
                                    Live simulation and testing
                                </li>
                            </ul>
                        </FeatureDesc>
                    </FeatureCard>
                </FeatureRow>
                <FeatureRow>
                    <FeatureCard>
                        <TaceFeatureLogo src={vulnerabilityLogo} />
                        <FeatureTitle>
                            Vulnerability Management
                        </FeatureTitle>
                        <FeatureDesc>
                            <ul>
                                <li>
                                    Unlimited VA scans
                                </li>
                                <li>
                                    Black box and gray box scans
                                </li>
                                <li>
                                    Patches and available weakness notices<br /> with recommendations on their resolutions
                                </li>
                                <li>
                                    Automatic signature updates
                                </li>
                            </ul>
                        </FeatureDesc>
                    </FeatureCard>
                    <FeatureCard>
                        <TaceFeatureLogo src={reportLogo} />
                        <FeatureTitle>
                            On-the-Fly Compliance Reports
                        </FeatureTitle>
                        <FeatureDesc>
                            <ul>
                                <li>
                                    Swift report visualization and filtering
                                </li>
                                <li>
                                    Flexible report rules creation
                                </li>
                                <li>
                                    Standards reporting available (ISO 27001,<br /> PCI, etc.)
                                </li>
                                <li>
                                    Easy filtering and insightful visualization
                                </li>
                            </ul>
                        </FeatureDesc>
                    </FeatureCard>
                    <FeatureCard>
                        <TaceFeatureLogo src={threatLogo} />
                        <FeatureTitle>
                            Threat Intelligence Consolidation
                        </FeatureTitle>
                        <FeatureDesc>
                            <ul>
                                <li>
                                    Integration and addition of new sources
                                </li>
                                <li>
                                    Built-in and real-time updated SOC IOC’s<br /> threat sources
                                </li>
                                <li>
                                    Capability to enrich any log source with TI
                                </li>
                            </ul>
                        </FeatureDesc>
                    </FeatureCard>
                </FeatureRow>

                {/* swiper mobile */}

                <SwiperTaceMobile className="container-tace">
                    <Swiper
                        style={{
                            width: "100%",
                            paddingInline: '8vw',
                            boxSizing: 'border-box',
                            paddingTop: '6vh',
                            filter: 'drop-shadow(15px 15px 30px rgba(0, 0, 0, 0.15))'
                        }}
                        slidesPerView={1.01}
                        // centeredSlides={true}
                        navigation
                        spaceBetween={20}
                        pagination={{
                            el: '.tacivoar-feature-page',
                            clickable: true
                        }}
                        modules={[Pagination]}
                        className="tacivoarSwiper"
                        scrollbar={{ draggable: true }}
                    >
                        <SwiperSlide>
                            <FeatureCard>
                                <TaceFeatureLogo src={KillchainLogo} />
                                <FeatureTitle>
                                    Alerts and Killchain Overview
                                </FeatureTitle>
                                <FeatureDesc>
                                    <UlTaceSwiper>
                                        <li>
                                            Top noticeable attacks
                                        </li>
                                        <li>
                                            Quick alert drill-down
                                        </li>
                                        <li>
                                            MITRE alignment
                                        </li>
                                        <li>
                                            SOC analyst assistant
                                        </li>
                                    </UlTaceSwiper>
                                </FeatureDesc>
                            </FeatureCard>
                        </SwiperSlide>
                        <SwiperSlide>
                            <FeatureCard>
                                <TaceFeatureLogo src={uebaLogo} />
                                <FeatureTitle>
                                    UEBA
                                </FeatureTitle>
                                <FeatureDesc>
                                    <UlTaceSwiper>
                                        <li>
                                            Pre-processing and post-processing <br />correlation
                                        </li>
                                        <li>
                                            Built-in threat detection
                                        </li>
                                        <li>
                                            User/Network behavior analytics
                                        </li>
                                        <li>
                                            Flexible fine-tuning and visualization
                                        </li>
                                    </UlTaceSwiper>
                                </FeatureDesc>
                            </FeatureCard>
                        </SwiperSlide>
                        <SwiperSlide>
                            <FeatureCard>
                                <TaceFeatureLogo src={agileLogo} />
                                <FeatureTitle>
                                    Agile Parsing
                                </FeatureTitle>
                                <FeatureDesc>
                                    <UlTaceSwiper>
                                        <li>
                                            Versatile integration
                                        </li>
                                        <li>
                                            Smart crawling for unorthodox logs
                                        </li>
                                        <li>
                                            All log types ingestion and visualization
                                        </li>
                                        <li>
                                            Live simulation and testing
                                        </li>
                                    </UlTaceSwiper>
                                </FeatureDesc>
                            </FeatureCard>
                        </SwiperSlide>
                        <SwiperSlide>
                            <FeatureCard>
                                <TaceFeatureLogo src={vulnerabilityLogo} />
                                <FeatureTitle>
                                    Vulnerability Management
                                </FeatureTitle>
                                <FeatureDesc>
                                    <UlTaceSwiper>
                                        <li>
                                            Unlimited VA scans
                                        </li>
                                        <li>
                                            Black box and gray box scans
                                        </li>
                                        <li>
                                            Patches and available weakness notices<br /> with recommendations on their resolutions
                                        </li>
                                        <li>
                                            Automatic signature updates
                                        </li>
                                    </UlTaceSwiper>
                                </FeatureDesc>
                            </FeatureCard>
                        </SwiperSlide>
                        <SwiperSlide>
                            <FeatureCard>
                                <TaceFeatureLogo src={reportLogo} />
                                <FeatureTitle>
                                    On-the-Fly Compliance Reports
                                </FeatureTitle>
                                <FeatureDesc>
                                    <UlTaceSwiper>
                                        <li>
                                            Swift report visualization and filtering
                                        </li>
                                        <li>
                                            Flexible report rules creation
                                        </li>
                                        <li>
                                            Standards reporting available (ISO 27001,<br /> PCI, etc.)
                                        </li>
                                        <li>
                                            Easy filtering and insightful visualization
                                        </li>
                                    </UlTaceSwiper>
                                </FeatureDesc>
                            </FeatureCard>
                        </SwiperSlide>
                        <SwiperSlide>
                            <FeatureCard>
                                <TaceFeatureLogo src={threatLogo} />
                                <FeatureTitle>
                                    Threat Intelligence Consolidation
                                </FeatureTitle>
                                <FeatureDesc>
                                    <UlTaceSwiper>
                                        <li>
                                            Integration and addition of new sources
                                        </li>
                                        <li>
                                            Built-in and real-time updated SOC IOC’s<br /> threat sources
                                        </li>
                                        <li>
                                            Capability to enrich any log source with TI
                                        </li>
                                    </UlTaceSwiper>
                                </FeatureDesc>
                            </FeatureCard>
                        </SwiperSlide>
                    </Swiper>

                    <div className="tacivoar-feature-page" />
                </SwiperTaceMobile>
            </TacivoarFeatureTitle>
        </TacivoarFeatureBackground>
        <FooterDarkivoreBackground>
            <FooterDarkivoreTitle>
                <DarkivoreHistoryFooter>
                    <DarkivoreFooterImage src={thidesoftLogo} />
                    <DarkivoreFooterNewLine>Member of Potech group, Thidesoft is a Cybersecurity Technology Vendor headquartered in Paris - France, with the mission to provide an arsenal of 360° cyber defense tools.</DarkivoreFooterNewLine>
                    <DarkivoreFooterNewLine>Among others, Thidesoft offers TACIVOAR, DARKIVORE and OCTIVORE, three engineering masterpieces that harness AI, Machine Learning, and cutting-edge technology to protect internal & cloud assets, hunt the web for potential threats targeted at businesses, and respond to security incidents.</DarkivoreFooterNewLine>
                </DarkivoreHistoryFooter>
            </FooterDarkivoreTitle>
        </FooterDarkivoreBackground>
        <FooterDivider />
    </>
}

export default Tacivoar;