// scrollSlice.js
import { createSlice } from '@reduxjs/toolkit';

const jobSlice = createSlice({
    name: 'job',
    initialState: {
        description: null,
        type: null,
        attendance: null,
        logo: null,
        position: null,
        country: null
    },
    reducers: {
        setJob: (state, action) => {
            const { description, type, attendance, logo, position, country } = action.payload;
            state.description = description;
            state.type = type;
            state.attendance = attendance;
            state.logo = logo;
            state.position = position;
            state.country = country;
        },
        resetJob: (state) => {
            state.description = null;
        },
    },
});

export const { setJob, resetJob } = jobSlice.actions;

export default jobSlice.reducer;
