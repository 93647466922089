import styled from "styled-components";

export const DarkivoreRow = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
    justify-content: space-between;
	align-items: center;
    width: 80%;
	margin-top: ${props => props.marginTop ?? ''};
	align-content: stretch;
	@media screen and (max-width: 800px) {
		flex-direction: column;
	}
`;