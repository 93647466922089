import React, { useState } from "react";
import {
  Nav,
  Logo,
  Navs,
  NavsCol,
  NavsHeader,
  SubNavsHeader,
  SubNavsProduct,
  ContainerProduct,
  ContainerAdvisory,
  ContainerManaged,
  SubNavsEng,
  ContainerEng,
  ContainerAcademy,
  SubNavsAcademy,
  ContainerResources,
  SubNavsResources,
  AdvisoryNavLink,
  ManagedNavLink,
  AcademyNavLink,
  EngineeringNavLink,
  LabsNavLink,
  ResourcesNavLink,
  NavsMobile,
  NavPageMobile,
  SubNavs,
  NavMobile,
  SubNavsText,
  ArrowRight,
  SubNavsTextLight,
  ArrowLeft,
  ProductTitle,
  ProductDivMobile,
  ProductRow,
  ProductImageMobile,
  ProductDescription,
  LearnMoreNavButtonMobile,
} from "./NavbarElements";
import PotechLogo from "../../assets/logos/potech Logo & Slogan.svg";
import PotechLogoBlack from "../../assets/logos/potechLogo&Slogan.svg";
import PotechLogoInWhite from "../../assets/logos/potechLogoWhite.svg";
import ProductNavItem from "../ProductNavItem";
import AdvisoryNavItem from "../AdvisoryNavItem";
import ManagedServicesNavItem from "../ManagedServicesNavItem";
import EngineeringNavItem from "../EngineeringNavItem";
import AcademyNavItem from "../AcademyNavItem";
import ResourcesNavItem from "../ResourcesNavItem";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { setTargetCard, setTargetSection } from "../../redux/scrollSlice";
import Hamburger from "hamburger-react";
import Aos from "aos";
import "aos/dist/aos.css";
import NavbarMobile from "../NavbarMobile";
import arrowLeft from '../../assets/logos/arrowLeft.svg';

import DarkiLogo from "../../assets/logos/DarkivoreLogo.png";
import TaceLogo from "../../assets/logos/TacivoarLogo.png";
import OctivoreLogo from "../../assets/logos/Octivore Logo.png";

const Navbar = ({ isScrolled, isProductactive, isBlack, isOpen, setOpen, index, setIndex }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClick = (sectionId) => {
    dispatch(setTargetSection(sectionId));
  };

  const handleClickCard = (cardId) => {
    dispatch(setTargetCard(cardId));
  };

  const goTo = (path) => {
    setOpen(false);
    navigate(path);
  }

  useState(() => {
    Aos.init({ duration: 500 });
  }, []);

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      <Nav isWhite={!!(isScrolled || (matchPath("/privacy_notice", pathname) || matchPath("/cookie_policy", pathname)))} isBlack={isBlack}>
        <Logo isScrolled={isScrolled} src={isScrolled || (matchPath("/privacy_notice", pathname) || matchPath("/cookie_policy", pathname)) ? PotechLogoInWhite : isBlack ? PotechLogoBlack : PotechLogo} onClick={() => navigate('/')} />
        <NavsCol>
          <NavsHeader>
            <SubNavsHeader isScrolled={isScrolled} to='/contact-us'>
            CONTACT US
            </SubNavsHeader>
            <SubNavsHeader isScrolled={isScrolled} to='/about-us'>
              ABOUT US
            </SubNavsHeader>
            <SubNavsHeader isScrolled={isScrolled} to='/careers'>
              CAREERS
            </SubNavsHeader>
          </NavsHeader>
          <Navs>
            <SubNavsProduct isScrolled={isScrolled || matchPath("/privacy_notice", pathname) || matchPath("/cookie_policy", pathname)} active={isProductactive} isBlack={isBlack} isHome={matchPath("/", pathname)}>
              PRODUCTS
              <ContainerProduct>
                <ProductNavItem navigate={navigate} />
              </ContainerProduct>
            </SubNavsProduct>
            <AdvisoryNavLink to="/advisory" isScrolled={isScrolled || matchPath("/privacy_notice", pathname) || matchPath("/cookie_policy", pathname)} isBlack={isBlack} isHome={matchPath("/", pathname)}>
              ADVISORY
              <ContainerAdvisory>
                <AdvisoryNavItem openInNewTab={openInNewTab} handleClick={handleClick} handleClickCard={handleClickCard} />
              </ContainerAdvisory>
            </AdvisoryNavLink>
            <ManagedNavLink to="/managed-services" isScrolled={isScrolled || matchPath("/privacy_notice", pathname) || matchPath("/cookie_policy", pathname)} isBlack={isBlack} isHome={matchPath("/", pathname)}>
              MANAGED SERVICES
              <ContainerManaged>
                <ManagedServicesNavItem handleClick={handleClick} />
              </ContainerManaged>
            </ManagedNavLink>
            <EngineeringNavLink to="/engineering" isScrolled={isScrolled || matchPath("/privacy_notice", pathname) || matchPath("/cookie_policy", pathname)} isBlack={isBlack} isHome={matchPath("/", pathname)}>
              ENGINEERING
              <ContainerEng>
                <EngineeringNavItem handleClick={handleClick} handleClickCard={handleClickCard} />
              </ContainerEng>
            </EngineeringNavLink>
            <AcademyNavLink to="/academy" isScrolled={isScrolled || matchPath("/privacy_notice", pathname) || matchPath("/cookie_policy", pathname)} isBlack={isBlack} isHome={matchPath("/", pathname)}>
              ACADEMY
              <ContainerAcademy>
                <AcademyNavItem handleClick={handleClick} handleClickCard={handleClickCard} />
              </ContainerAcademy>
            </AcademyNavLink>
            <LabsNavLink to="/labs" isScrolled={isScrolled || matchPath("/privacy_notice", pathname) || matchPath("/cookie_policy", pathname)} isBlack={isBlack} isHome={matchPath("/", pathname)}>
              LABS
            </LabsNavLink>
            <ResourcesNavLink to="/resources" isScrolled={isScrolled || matchPath("/privacy_notice", pathname) || matchPath("/cookie_policy", pathname)} isBlack={isBlack} isHome={matchPath("/", pathname)}>
              RESOURCES
              <ContainerResources>
                <ResourcesNavItem handleClick={handleClick} />
              </ContainerResources>
            </ResourcesNavLink>
          </Navs>
        </NavsCol>
      </Nav>
      <NavMobile isWhite={matchPath("/privacy_notice", pathname) || matchPath("/cookie_policy", pathname)} isOpen={isOpen} isScrolled={isScrolled}>
        <Logo isWhite={matchPath("/privacy_notice", pathname) || matchPath("/cookie_policy", pathname)} isOpen={isOpen} src={isOpen || matchPath("/privacy_notice", pathname) || matchPath("/cookie_policy", pathname) || isScrolled ? PotechLogoInWhite : isBlack ? PotechLogoBlack : PotechLogo} onClick={() => goTo('/')} />
        <NavsMobile>
          <Hamburger toggled={isOpen} toggle={setOpen} color={isOpen || matchPath("/privacy_notice", pathname) || matchPath("/cookie_policy", pathname) || isScrolled ? '#002F87' : isBlack ? 'black' : 'white'} />
        </NavsMobile>
      </NavMobile>
      {isOpen && index === null && (
        <NavbarMobile setIndex={setIndex} goTo={goTo} setOpen={setOpen} />
      )}
      {isOpen && index === 1 && (
        <NavPageMobile>
          <div data-aos="fade-left">
            <div style={{ display: 'flex', paddingTop: '0%' }} onClick={() => setIndex(null)}>
              <ArrowLeft src={arrowLeft} />
              <SubNavsText>
                MAIN MENU
              </SubNavsText>
            </div>
            <div style={{ marginTop: '3vh' }}>
              <SubNavsText>
                PRODUCTS
              </SubNavsText>
            </div>
            <ProductDivMobile>
              <ProductTitle style={{ marginBottom: '3.5vh' }}>
                DRP & CTI
              </ProductTitle>
              <ProductRow>
                <ProductImageMobile src={DarkiLogo} />
                <div>
                  <ProductDescription style={{ marginBottom: '1vh' }}>
                    <div>Digital Risk Protection</div>
                    <div>Brand Protection</div>
                    <div>Attack Surface & CTI</div>
                    <div>Cyberthreat Takedown</div>
                  </ProductDescription>
                  <LearnMoreNavButtonMobile style={{ paddingInline: '2vw' }} color={"#70AB46"} onClick={() => {
                    goTo("/darkivore");
                  }}>LEARN MORE</LearnMoreNavButtonMobile>
                </div>
              </ProductRow>
            </ProductDivMobile>
            <ProductDivMobile>
              <ProductTitle style={{ marginBottom: '3.5vh' }}>
                SIEM++
              </ProductTitle>
              <ProductRow>
                <ProductImageMobile src={TaceLogo} />
                <div>
                  <ProductDescription style={{ marginBottom: '1vh' }}>
                    <div>Threat Detection</div>
                    <div>Flexible Parsing</div>
                    <div>SOAR Capabilities</div>
                    <div>User Entity & Behavior Analytics</div>

                  </ProductDescription>
                  <LearnMoreNavButtonMobile color={"#8EC0E8"} onClick={() => {
                    goTo("/tacivoar");
                  }}>LEARN MORE</LearnMoreNavButtonMobile>
                </div>
              </ProductRow>
            </ProductDivMobile>
            <ProductDivMobile style={{ borderBottom: 'none' }}>
              <ProductTitle style={{ marginBottom: '3.5vh' }}>
                SECURITY IR
              </ProductTitle>
              <ProductRow>
                <ProductImageMobile src={OctivoreLogo} />
                <div>
                  <ProductDescription style={{ marginBottom: '1vh' }}>
                    <div>Alerts Aggregation</div>
                    <div>Support for Any SIEM</div>
                    <div>Centralized Ticket Management</div>
                  </ProductDescription>
                  <LearnMoreNavButtonMobile color={"#E9D24A"} onClick={() => {
                    goTo("/octivore");
                  }}>LEARN MORE</LearnMoreNavButtonMobile>
                </div>
              </ProductRow>
            </ProductDivMobile>
          </div>
        </NavPageMobile>
      )}
      {isOpen && index === 2 && (
        <NavPageMobile>
          <div data-aos="fade-left">
            <div style={{ display: 'flex', paddingTop: '0%' }} onClick={() => setIndex(null)}>
              <ArrowLeft src={arrowLeft} />
              <SubNavsText>
                MAIN MENU
              </SubNavsText>
            </div>
            <div style={{ marginTop: '3vh' }}>
              <SubNavsText onClick={() => goTo('advisory')}>
                ADVISORY
              </SubNavsText>
            </div>
            <ProductDivMobile>
              <ProductTitle onClick={() => {
                goTo('advisory')
                handleClick('strategy')
              }}>
                STRATEGY
              </ProductTitle>
              <ProductRow>
                <ProductDescription>
                  <div>Achieve Cybersecurity Maturity</div>
                  <div>Data Privacy Assessment</div>
                  <div>Scenario-Based Risk Assessments</div>
                  <div>Virtual CISO</div>
                </ProductDescription>
              </ProductRow>
            </ProductDivMobile>
            <ProductDivMobile>
              <ProductTitle onClick={() => {
                goTo('advisory')
                handleClick('defense')
              }}>
                DEFENSE
              </ProductTitle>
              <ProductRow>
                <ProductDescription>
                  <div>Directory Services Security Review</div>
                  <div>Infrastructure & Cloud Security Assessments</div>
                  <div>Database Security Assessment</div>
                  <div>User Management Security</div>
                </ProductDescription>
              </ProductRow>
            </ProductDivMobile>
            <ProductDivMobile>
              <ProductTitle onClick={() => {
                goTo('advisory')
                handleClick('vigilance')
              }}>
                VIGILANCE
              </ProductTitle>
              <ProductRow>
                <ProductDescription>
                  <div>Targeted Penetration Tests</div>
                  <div>Red Team Exercises</div>
                  <div>Source Code Review Analysis</div>
                  <div>Ransomware Simulation</div>
                </ProductDescription>
              </ProductRow>
            </ProductDivMobile>
            <ProductDivMobile>
              <ProductTitle onClick={() => {
                goTo('advisory')
                handleClick('resilience')
              }}>
                RESILIENCE
              </ProductTitle>
              <ProductRow>
                <ProductDescription>
                  <div>Incident Response</div>
                  <div>Digital Forensics</div>
                  <div>Business Continuity Management</div>
                  <div>Cyber Liability Insurance</div>
                </ProductDescription>
              </ProductRow>
            </ProductDivMobile>
            <ProductDivMobile style={{ borderBottom: 'none' }} onClick={() => openInNewTab('https://nigma.global/')}>
              <ProductTitle style={{ color: '#565656' }}>
                Nigma Conseil
              </ProductTitle>
              <ProductRow>
                <ProductDescription>
                  <div>Physical Security</div>
                  <div>Business Intelligence</div>
                  <div>Blockchain Forensics</div>
                </ProductDescription>
              </ProductRow>
            </ProductDivMobile>
          </div>
        </NavPageMobile>
      )}
      {isOpen && index === 3 && (
        <NavPageMobile>
          <div data-aos="fade-left">
            <div style={{ display: 'flex', paddingTop: '0%' }} onClick={() => setIndex(null)}>
              <ArrowLeft src={arrowLeft} />
              <SubNavsText>
                MAIN MENU
              </SubNavsText>
            </div>
            <div style={{ marginTop: '3vh' }}>
              <SubNavsText onClick={() => goTo('managed-services')}>
                MANAGED SERVICES
              </SubNavsText>
            </div>
            <ProductDivMobile>
              <ProductTitle onClick={() => {
                goTo('managed-services');
                handleClick('detect');
              }}>
                DETECT
              </ProductTitle>
              <ProductRow>
                <ProductDescription>
                  <div>
                    IDR
                  </div>
                  <div>
                    Ex-DR
                  </div>
                  <div>
                    SOC Security Operation Center
                  </div>
                  <div>
                    Managed SIEM
                  </div>
                </ProductDescription>
              </ProductRow>
            </ProductDivMobile>
            <ProductDivMobile>
              <ProductTitle onClick={() => {
                goTo('managed-services');
                handleClick('fortify');
              }}>
                FORTIFY
              </ProductTitle>
              <ProductRow>
                <ProductDescription>
                  <div>
                    Managed Security Service
                  </div>
                  <div>
                    Managed EDR
                  </div>
                  <div>
                    SOC Maturity Assessment
                  </div>
                  <div>
                    Vulnerability Management
                  </div>
                  <div>
                    Hardening
                  </div>
                </ProductDescription>
              </ProductRow>
            </ProductDivMobile>
            <ProductDivMobile style={{ borderBottom: 'none' }}>
              <ProductTitle onClick={() => {
                goTo('managed-services');
                handleClick('respond');
              }}>
                RESPOND
              </ProductTitle>
              <ProductRow>
                <ProductDescription>
                  <div>
                    Incident Response
                  </div>
                  <div>
                    Managed SOAR
                  </div>
                  <div>
                    Case Management
                  </div>
                </ProductDescription>
              </ProductRow>
            </ProductDivMobile>
          </div>
        </NavPageMobile>
      )}
      {isOpen && index === 4 && (
        <NavPageMobile>
          <div data-aos="fade-left">
            <div style={{ display: 'flex', paddingTop: '0%' }} onClick={() => setIndex(null)}>
              <ArrowLeft src={arrowLeft} />
              <SubNavsText>
                MAIN MENU
              </SubNavsText>
            </div>
            <div style={{ marginTop: '3vh' }}>
              <SubNavsText onClick={() => goTo('engineering')}>
                ENGINEERING
              </SubNavsText>
            </div>
            <ProductDivMobile>
              <ProductTitle onClick={() => {
                goTo('engineering');
                handleClick('engineering');
              }}>
                ENGINEERING
              </ProductTitle>
              <ProductRow>
                <ProductDescription>
                  <div>
                    No-Code Data Flows
                  </div>
                  <div>
                    No-Code Workflow Builds
                  </div>
                  <div>
                    High Performance Engines: C, Java, Python
                  </div>
                  <div>
                    Interactive UX/UI Platforms
                  </div>
                  <div>
                    Real-Time Monitoring Dashboards
                  </div>
                </ProductDescription>
              </ProductRow>
            </ProductDivMobile>
            <ProductDivMobile>
              <ProductTitle onClick={() => {
                goTo('engineering');
                handleClick('ai')
              }}>
                AI & MACHINE LEARNING
              </ProductTitle>
              <ProductRow>
                <ProductDescription>
                  <div>
                    Log Pattern Analysis
                  </div>
                  <div>
                    Big Data Analytics & Actionable Insights
                  </div>
                  <div>
                    Advanced Data Crawlers & Smart Parsing
                  </div>
                </ProductDescription>
              </ProductRow>
            </ProductDivMobile>

            <ProductDivMobile>
              <ProductTitle onClick={() => {
                goTo('engineering');
                handleClick('consult')
              }}>
                CONSULTING
              </ProductTitle>
              <ProductRow>
                <ProductDescription>
                  <div>
                    Security by Design
                  </div>
                  <div>
                    Performance Assessment
                  </div>
                  <div>
                    CTO as a Service
                  </div>
                  <div>
                    User Acceptance Tests
                  </div>
                  <div>
                    Virtual Scrum Master
                  </div>
                  <div>
                    Digital Transformation
                  </div>
                </ProductDescription>
              </ProductRow>
            </ProductDivMobile>

            <ProductDivMobile style={{ borderBottom: 'none' }}>
              <ProductTitle onClick={() => {
                goTo('engineering');
                handleClick('solution')
              }}>
                SOLUTIONS
              </ProductTitle>
              <ProductRow>
                <ProductDescription>
                  <div>
                    SmartMail | Mass Mailing
                  </div>
                  <div>
                    EOMP | Electoral Operation Management Platform
                  </div>
                  <div>
                    ERP/CRM Implementation
                  </div>
                  <div>
                    Secure Private Hosting | SaaS/PaaS/IaaS
                  </div>
                </ProductDescription>
              </ProductRow>
            </ProductDivMobile>
          </div>
        </NavPageMobile>
      )}
      {isOpen && index === 5 && (
        <NavPageMobile>
          <div data-aos="fade-left">
            <div style={{ display: 'flex', paddingTop: '0%' }} onClick={() => setIndex(null)}>
              <ArrowLeft src={arrowLeft} />
              <SubNavsText>
                MAIN MENU
              </SubNavsText>
            </div>
            <div style={{ marginTop: '3vh' }}>
              <SubNavsText onClick={() => goTo('academy')}>
                ACADEMY
              </SubNavsText>
            </div>
            <ProductDivMobile>
              <ProductTitle onClick={() => {
                goTo('academy');
                handleClick('training');
              }}>
                TRAINING
              </ProductTitle>
              <ProductRow>
                <ProductDescription>
                  <div>
                    Purple Team
                  </div>
                  <div>
                    Digital Forensics & Incident Response
                  </div>
                  <div>
                    SOC & SIEM Management
                  </div>
                  <div>
                    Compliance & Strategy
                  </div>
                  <div>
                    Machine Learning & Blockchain
                  </div>
                </ProductDescription>
              </ProductRow>
            </ProductDivMobile>
            <ProductDivMobile style={{ borderBottom: 'none' }}>
              <ProductTitle
                onClick={() => {
                  goTo('academy');
                  handleClick('pecb');
                }}>
                PECB TRAINING CERTIFICATIONS
              </ProductTitle>
              <ProductRow>
                <ProductDescription>
                  <div>Information Security</div>
                  <div>Cybersecurity</div>
                  <div>Continuity, Resilience, Recovery</div>
                  <div>Governance, Risk and Compliance</div>
                  {/* <ProductTitle style={{ color: '#405DF9', marginTop: '2vh' }}>
                    ISO 22301/16/17
                  </ProductTitle> */}
                </ProductDescription>
              </ProductRow>
            </ProductDivMobile>
          </div>
        </NavPageMobile>
      )}
      {isOpen && index === 6 && (
        <NavPageMobile>
          <div data-aos="fade-left">
            <div style={{ display: 'flex', paddingTop: '0%' }} onClick={() => setIndex(null)}>
              <ArrowLeft src={arrowLeft} />
              <SubNavsText>
                MAIN MENU
              </SubNavsText>
            </div>
            <div style={{ marginTop: '3vh' }}>
              <SubNavsText onClick={() => goTo('resources')}>
                RESOURCES
              </SubNavsText>
            </div>
            <ProductDivMobile>
              <ProductTitle onClick={() => {
                goTo('resources');
                handleClick('knowledge')
              }}>
                KNOWLEDGE BASE
              </ProductTitle>
              <ProductRow>
                <ProductDescription>
                  <div>
                    Research & Publications
                  </div>
                  <div>
                    Datasheets
                  </div>
                  {/* <div>
                    Blog Posts
                  </div> */}
                </ProductDescription>
              </ProductRow>
            </ProductDivMobile>
            {/* <ProductDivMobile style={{ borderBottom: 'none' }}>
              <ProductTitle onClick={() => {
                goTo('resources');
                handleClick('media')
              }}>
                MEDIA
              </ProductTitle>
              <ProductRow>
                <ProductDescription>
                  <div>
                    News
                  </div>
                  <div>
                    Events
                  </div>
                </ProductDescription>
              </ProductRow>
            </ProductDivMobile> */}
          </div>
        </NavPageMobile>
      )}
    </>
  );
};

export default Navbar;