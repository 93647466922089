import { useRef, useState } from 'react';
import arrowDown from '../../assets/logos/arrowDown.svg';
import arrowUp from '../../assets/logos/arrowUp.svg';
import { ProductCol, FeatureCardMobileContainer, ProductTitle, ProductDesc, CardBottom } from './FeatureCardMobileElements';

const FeatureCardMobile = (props) => {
    const [isOpen, setOpen] = useState(null);
    const topRef = useRef(null);
    return <FeatureCardMobileContainer onClick={props.clickHandler} width={props.width} isOpen={isOpen} ref={topRef}>
        {!isOpen &&
            <ProductCol>
                <ProductTitle>
                    {props.title}
                </ProductTitle>
            </ProductCol>
        }
        {isOpen && props.items}
        <CardBottom onClick={() => {
            if (isOpen)
                topRef.current.scrollIntoView({ behavior: 'smooth' });
            setOpen(prev => !prev);
        }}>
            <img src={isOpen ? arrowUp : arrowDown} alt="React Logo" width={20} />
        </CardBottom>
    </FeatureCardMobileContainer>
}

export default FeatureCardMobile;