import arrowDown from '../../assets/logos/arrowDown.svg';
import arrowUp from '../../assets/logos/arrowUp.svg';
import { EngineeringContainer, ModuleBorderWrap } from './EngineeringCardElements';


const EngineeringCard = (props) => {
    return <ModuleBorderWrap width={props.width} active={props.active} isConsultSection={props.isConsultSection} >
        <EngineeringContainer onClick={props.clickHandler} active={props.active} isConsultSection={props.isConsultSection}>
            <h3 style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', alignItems: 'center', paddingInline: props.isConsultSection ? '20%' : '9%', fontWeight: 'normal', marginTop: 0, marginBottom: 0, fontSize: '1.3vw'}}>
                {props.text}
            </h3>
            <div style={{ height: 35, display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', alignItems: 'center', backgroundColor: '#f0f0f0', borderBottomLeftRadius: '13px', borderBottomRightRadius: '13px' }}>
                <img src={props.active ? arrowUp : arrowDown} alt="React Logo" width={20} />
            </div>
        </EngineeringContainer>
    </ModuleBorderWrap>
}

export default EngineeringCard;