import { ModuleBorderWrap, VigilanceContainer, VigilanceContainerText } from "./VigilanceCardElements";
import arrowDown from '../../assets/logos/arrowDown.svg';
import arrowUp from '../../assets/logos/arrowUp.svg';

const VigilanceCard = (props) => {
    return <ModuleBorderWrap active={props.active}>
        <VigilanceContainer onClick={props.clickHandler} active={props.active}>
            <VigilanceContainerText>
                {props.text}
            </VigilanceContainerText>
            <div style={{ height: 35, display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', alignItems: 'center', backgroundColor: '#f0f0f0', borderBottomLeftRadius: '13px', borderBottomRightRadius: '13px' }}>
                <img src={props.active ? arrowUp : arrowDown} alt="React Logo" width={20} />
            </div>
        </VigilanceContainer>
    </ModuleBorderWrap>
}

export default VigilanceCard;