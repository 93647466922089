import styled from "styled-components";
import LabsBack from '../../assets/images/LabsBack.png'
import LabsGreyBack from '../../assets/images/LabsGreyBack.png'

export const LabsBackground = styled.nav`
  width: 100%;
  height: 125vh;
  position: relative;
  z-index: 2;
  @media screen and (max-width: 800px) {
    height: 100vh;
  }
`;

export const LabsGreyBackground = styled.nav`
  width: 100%;
  height: 110vh;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 0;
  @media screen and (max-width: 800px) {
    height: auto;
  }
`;

export const LabsImg = styled.div`
  width: 100%;
  height: 125vh;
  position: absolute;
  background-image: url(${LabsBack});
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
`;

export const LabsGreyImg = styled.div`
  width: 100%;
  height: 100.5%;
  position: absolute;
  background-image: url(${LabsGreyBack});
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  @media screen and (max-width: 800px) {
    height: 100.5%;
  }
`;

export const LabsImageContainer = styled.div`
  width: 75vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10vh;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    margin-top: 10vh;
    margin-bottom: 10vh;
  }
`;

export const LabsTitle = styled.div`
  color: white;
  height: 80vh;
  display: flex;
  flex-direction: column;
  /* flex-wrap: nowrap; */
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  /* font-family: 'Helvetica Light'; */
  font-family: "HelveticaLt", Arial, sans-serif;
  z-index: 1;
  @media screen and (max-width: 800px) {
    height: 100vh;
  }
`;

export const LabsHeadContainer = styled.div`
  color: white;
  height: 40vh;
  display: flex;
  flex-direction: column;
  /* flex-wrap: nowrap; */
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  /* font-family: 'Helvetica Light'; */
  font-family: "HelveticaLt", Arial, sans-serif;
  z-index: 1;
  @media screen and (max-width: 800px) {
    height: 40vh;
  }
`;

export const LabsTextTitle = styled.h1`
    color: #FFF;
    /* Title-Internal Page */
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.8vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-top: 10vh;
    margin-bottom: 0;
    @media screen and (max-width: 800px) {
      text-align: center;
      font-size: 23px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      word-wrap: break-word;
      box-sizing: border-box;
      text-align: center;
      /* padding-inline: 15%; */
      margin-top: 1vh;
  }
`;

export const LabsBody = styled.div`
  position: relative;
  color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: white;
  height: 65vh;
  width: 75vw;
  color: var(--darkivore-dark-blue, #1F3B71);
  border-radius: 15px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 1;
  margin-bottom: -85vh;
  @media screen and (max-width: 800px) {
    padding-inline: 5vw;
    box-sizing: border-box;
    height: auto;
    margin-bottom: -100vh;
    padding-bottom: 5vh;
    text-align: center;
  }
`;

export const LabsHead = styled.h2`
  color: var(--darkivore-dark-blue, #1F3B71);
  text-align: center;
  /* Heading H1 */
  font-size: 1.4vw;
  text-transform: uppercase;
  font-weight: normal;
  margin-top: 3vh;
  margin-bottom: 5vh;
  font-family: "HelveticaLt", Arial, sans-serif;
  z-index: 1;
  color: var(--labs-dark-purple, #6C345E);
  @media screen and (max-width: 800px) {
    font-size: 20px;
    margin-top: 15vh;
    margin-bottom: 5vh;
    font-family: "HelveticaLt", Arial, sans-serif;
  }
`;

export const LabsContainer = styled.div`
  color: black;
  font-size: 0.9vw;
  width: 60vw;
  font-family: "HelveticaLt", Arial, sans-serif;
  @media screen and (max-width: 800px) {
    width: 100%;
    font-size: 14px;
  }
`;

export const FooterLabsBackground = styled.nav`
  width: 100%;
  height: 40vh;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  font-weight: 100;
  /* font-family: 'Helvetica Light'; */
  font-family: "HelveticaLt", Arial, sans-serif;
  @media screen and (max-width: 800px) {
    height: auto;
  }
`;

export const FooterLabsTitle = styled.div`
  color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  margin: auto;
  text-align: center;
  height: 40vh;
  background: var(--labs-gradient, linear-gradient(90deg, #C39EC1 26.00%, #6C345E 94.58%));

  @media screen and (max-width: 800px) {
    height: auto;
    flex-direction: column-reverse;
  }
`;

export const LabsImageTitle = styled.div`
  color: var(--Black, #000);
  text-align: center;
  font-family: Helvetica;
  font-size: 1.2vw;
  font-style: normal;
  width: 80vw;
  font-weight: 400;
  margin-top: 15vh;
  @media screen and (max-width: 800px) {
    font-size: 18px;
    margin-top: 100vh;
    padding-inline: 10%;
    width: auto;
    line-height: 140%;
  }
`;

export const LabsImageCard = styled.img`
  width: 17vw;
  cursor: pointer;
  z-index: 1;
  @media screen and (max-width: 800px) {
    width: 100%;
    margin-top: 5vh;
  }
`;