import styled from "styled-components";

export const TacivoarContainer = styled.div`
    border-radius: 9px;
    background: var(--white, #FFF);
    /* Drop Shadow Style */
    color: var(--potech-dark-blue, #002F87);
    text-align: center;
    /* Expandable Sub Title */
    font-size: 0.9vw;
    font-style: normal;
    line-height: normal;
    /* width: 276px; */
    height: 15vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    /* padding-inline: 1%; */
    cursor: pointer;
`;

export const ModuleBorderWrap = styled.div`
    position: relative;

    background: ${(props) => props.active ? 'var(--tacivoar-gradient, linear-gradient(311deg, #8EC0E8 0%, #2D2151 100%))' : 'none'};
    padding: ${props => props.active ? '3px' : '0px'};
    border-radius: 12px;
    /* Drop Shadow Style */
    box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.20);
    text-align: center;
    /* Expandable Sub Title */
    font-size: 1.5vw;
    font-style: normal;
    line-height: normal;
    /* width: 276px; */
    width: 11.8vw;
    height: 15vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    /* box-sizing: border-box; */
    cursor: pointer;
    @media screen and (max-width: 800px) {
        display: none;
    }
`;

export const TacivoarBodyMobile = styled.nav`
  width: 100%;
  height: auto;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  padding-bottom: 8vh;
  /* font-family: 'Helvetica Light'; */
	font-family: "HelveticaLt", Arial, sans-serif;
  @media screen and (min-width: 801px) {
    display: none;
  }
`;

export const ModuleTacivoarBorderWrap = styled.div`
    position: relative;

    background: ${(props) => props.active ? 'var(--tacivoar-gradient, linear-gradient(311deg, #8EC0E8 0%, #2D2151 100%))' : 'none'};
    padding: ${props => props.active ? '3px' : '0px'};
    border-radius: 12px;
    /* Drop Shadow Style */
    box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.20);
    /* Expandable Sub Title */
    font-size: 1.5vw;
    font-style: normal;
    line-height: normal;
    /* width: 276px; */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    cursor: pointer;
    width: 85%;
    height: auto;
    text-align: start;
    text-transform: none;
    margin-top: -2vh;
    @media screen and (max-width: 800px) {
        display: none;
    }
`;

export const ModuleTacivoarBorderWrapBody = styled.div`
    background-color: white;
    width: 100%;
    border-radius: 9px;
    box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.20);
    color: black;
    box-sizing: border-box;
    padding: 2%;
    padding-inline: 2%;
    font-size: 1vw;
`;

export const ModuleTacivoarBorderWrapText = styled.div`
    color: var(--black, #000);
    font-family: "HelveticaLt", Arial, sans-serif;
    font-size: 1vw;
`;

export const AdvantageLogo = styled.img`
    position: absolute;
    left: 50%;
    width: 90px;
    transform: translateX(-50%);
    top: -45px;
    @media screen and (max-width: 1600px) {
        left: 50%;
        width: 70px;
        transform: translateX(-50%);
        top: -35px;
    }
    @media screen and (max-width: 800px) {
        left: 50%;
        width: 80px;
        transform: translateX(-50%);
        top: -40px;
    }
`;