import FooterLogo from '../../assets/logos/logoFooter.svg';

import linkedIn from '../../assets/logos/linkedIn.png';
import facebook from '../../assets/logos/facebook.png';
import youtube from '../../assets/logos/youtube.png';
import twitter from '../../assets/logos/x.svg';
import medium from '../../assets/logos/medium.png';
import {
    ContactUsButton,
    ContactUsContainer,
    Footer,
    FooterBottomRow,
    FooterBottomText,
    FooterBottomTextRow,
    FooterCol,
    FooterHeadRow,
    FooterHr,
    FooterLogoImage,
    FooterRow,
    FooterSectiontitle,
    FooterText,
    Footertitle,
    FooterU, ProductFooterLink,
    SocialDiv,
    SocialLogo
} from './FooterElements';

import EmailForm from '../EmailForm';
import {useNavigate} from 'react-router-dom';
import {setTargetCard, setTargetSection} from '../../redux/scrollSlice';
import {useDispatch} from 'react-redux';
import {ACADEMY_CARD_TYPES, ADVISORY_CARD_TYPES, ENGINEERING_CARD_TYPES} from '../../common/constant';

const FooterComponent = ({setReshowDialog}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const navigateToSection = (page, sectionId) => {
        navigate(page);
        handleClick(sectionId);
    }

    const navigateToSectionAndCard = (page, sectionId, cardId) => {
        navigate(page);
        handleClick(sectionId);
        handleClickCard(cardId);
    }

    const handleClick = (sectionId) => {
        dispatch(setTargetSection(sectionId));
    };

    const handleClickCard = (cardId) => {
        dispatch(setTargetCard(cardId));
    };

    return <Footer>
        <FooterHeadRow>
            <FooterLogoImage src={FooterLogo} />
            <ContactUsContainer>
                <ContactUsButton onClick={() => { navigate("contact-us"); handleClick("contactUsTop"); }}>Contact Us</ContactUsButton>
                {/* <AdvantageDivItemsMobile marginTop={'0'} /> */}
                <SocialDiv>
                    <a rel='nofollow' href='https://www.linkedin.com/company/potechglobal' target='_blank'><SocialLogo src={linkedIn} alt=""/></a>
                    <a rel='nofollow' href='https://www.facebook.com/potechglobal' target='_blank'><SocialLogo src={facebook} alt=""/></a>
                    <a rel='nofollow' href='https://twitter.com/potechglobal' target='_blank'><SocialLogo src={twitter} alt="" /></a>
                    <a rel='nofollow' href='https://www.youtube.com/channel/UCtPL7xbwvdFiWgwEnGz7hgg' target='_blank'><SocialLogo src={youtube} alt="" /></a>
                    <a rel="nofollow" href='https://potechglobal.medium.com' target='_blank'><SocialLogo src={medium} alt="" /></a>
                </SocialDiv>
            </ContactUsContainer>
            <EmailForm setReshowDialog={setReshowDialog} />
        </FooterHeadRow>
        <FooterRow>
            <FooterCol>
                <div>
                    <FooterSectiontitle>PRODUCTS</FooterSectiontitle>
                    <FooterHr />
                    <ProductFooterLink to='darkivore'><b>DRP & CTI:</b> DARKIVORE®</ProductFooterLink><br />
                    <ProductFooterLink to='tacivoar'><b>SIEM++:</b>  TACIVOAR®</ProductFooterLink><br />
                    <ProductFooterLink to='octivore'><b>Security IR:</b> OCTIVORE®</ProductFooterLink><br />
                </div>
                <div style={{ marginTop: '1.5vw' }}>
                    <FooterSectiontitle to='advisory'>ADVISORY</FooterSectiontitle>
                    <FooterHr />
                    <Footertitle onClick={() => { navigateToSection('advisory', 'strategy') }}>STRATEGY</Footertitle>
                    <FooterText onClick={() => { navigateToSectionAndCard('advisory', 'strategy', ADVISORY_CARD_TYPES.STRATEGY_ACHIEVE) }}>Achieve Cybersecurity Maturity</FooterText>
                    <FooterText onClick={() => { navigateToSectionAndCard('advisory', 'strategy', ADVISORY_CARD_TYPES.STRATEGY_DATA) }}>Data Privacy Assessment</FooterText>
                    <FooterText onClick={() => { navigateToSectionAndCard('advisory', 'strategy', ADVISORY_CARD_TYPES.STRATEGY_SCENARIO) }}>Scenario-Based Risk Assessments</FooterText>
                    <FooterText onClick={() => { navigateToSectionAndCard('advisory', 'strategy', ADVISORY_CARD_TYPES.STRATEGY_VIRTUAL) }}>Virtual CISO</FooterText>
                </div>
                <div style={{ marginTop: '1.5vw' }}>
                    <Footertitle onClick={() => { navigateToSection('advisory', 'defense') }}>DEFENSE</Footertitle>
                    <FooterText onClick={() => { navigateToSectionAndCard('advisory', 'defense', ADVISORY_CARD_TYPES.DEFENSE_DIRECTORY) }}>Directory Services Security Review</FooterText>
                    <FooterText onClick={() => { navigateToSectionAndCard('advisory', 'defense', ADVISORY_CARD_TYPES.DEFENSE_INFRASTRUCTURE) }}>Infrastructure & Cloud Security Assessments</FooterText>
                    <FooterText onClick={() => { navigateToSectionAndCard('advisory', 'defense', ADVISORY_CARD_TYPES.DEFENSE_DATABASE) }}>Database Security Assessment</FooterText>
                    <FooterText onClick={() => { navigateToSectionAndCard('advisory', 'defense', ADVISORY_CARD_TYPES.STRATEGY_ACHIEVE) }}>User Management Security</FooterText>
                </div>
                <div style={{ marginTop: '1.5vw' }}>
                    <Footertitle onClick={() => { navigateToSection('advisory', 'vigilance') }}>VIGILANCE</Footertitle>
                    <FooterText onClick={() => navigateToSectionAndCard('advisory', 'vigilance', ADVISORY_CARD_TYPES.VIGILANCE_TARGETED)}>Targeted Penetration Tests</FooterText>
                    <FooterText onClick={() => navigateToSectionAndCard('advisory', 'vigilance', ADVISORY_CARD_TYPES.VIGILANCE_RED)}>Red Team Exercises</FooterText>
                    <FooterText onClick={() => navigateToSectionAndCard('advisory', 'vigilance', ADVISORY_CARD_TYPES.VIGILANCE_SOURCE)}>Source Code Review Analysis</FooterText>
                    <FooterText onClick={() => navigateToSectionAndCard('advisory', 'vigilance', ADVISORY_CARD_TYPES.VIGILANCE_RANSOMWARE)}>Ransomware Simulation</FooterText>
                </div>
                <div style={{ marginTop: '1.5vw' }}>
                    <Footertitle onClick={() => { navigateToSection('advisory', 'resilience') }}>RESILIENCE</Footertitle>
                    <FooterText onClick={() => { navigateToSectionAndCard('advisory', 'resilience', ADVISORY_CARD_TYPES.RESILIENCE_INCIDENT) }}>Incident Response</FooterText>
                    <FooterText onClick={() => { navigateToSectionAndCard('advisory', 'resilience', ADVISORY_CARD_TYPES.RESILIENCE_DIGITAL) }}>Digital Forensics</FooterText>
                    <FooterText onClick={() => { navigateToSectionAndCard('advisory', 'resilience', ADVISORY_CARD_TYPES.RESILIENCE_BUSINESS) }}>Business Continuity Management</FooterText>
                    <FooterText onClick={() => { navigateToSectionAndCard('advisory', 'resilience', ADVISORY_CARD_TYPES.RESILIENCE_CYBER) }}>Cyber liability Insurance</FooterText>
                </div>
                <div style={{ marginTop: '1.5vw' }} onClick={() => openInNewTab('https://nigma.global/')}>
                    <Footertitle>Nigma Conseil</Footertitle>
                    <FooterText>Physical Security</FooterText>
                    <FooterText>Business Intelligence</FooterText>
                    <FooterText>Blockchain Forensics</FooterText>
                </div>
            </FooterCol>
            <FooterCol marginLeft={'3%'}>
                <div>
                    <FooterSectiontitle onClick={() => { navigate('managed-services') }}>MANAGED SERVICES</FooterSectiontitle>
                    <FooterHr marginRight={'10%'} />
                    <Footertitle onClick={() => { navigateToSection('managed-services', 'detect') }}>DETECT</Footertitle>
                    <FooterText>IDR</FooterText>
                    <FooterText>Ex-DR</FooterText>
                    <FooterText>SOC Security Operation Center</FooterText>
                    <FooterText>Managed SIEM</FooterText>
                </div>
                <div style={{ marginTop: '1.5vw' }}>
                    <Footertitle onClick={() => { navigateToSection('managed-services', 'fortify') }}>FORTIFY</Footertitle>
                    <FooterText>Managed Security Service</FooterText>
                    <FooterText>Managed EDR</FooterText>
                    <FooterText>SOC Maturity Assessment</FooterText>
                    <FooterText>Vulnerability Management</FooterText>
                    <FooterText>Hardening</FooterText>
                </div>
                <div style={{ marginTop: '1.5vw' }}>
                    <Footertitle onClick={() => { navigateToSection('managed-services', 'respond') }}>RESPOND</Footertitle>
                    <FooterText>Incident Response</FooterText>
                    <FooterText>Managed SOAR</FooterText>
                    <FooterText>Case Management</FooterText>
                </div>
            </FooterCol>
            <FooterCol marginLeft={'0%'}>
                <div>
                    <FooterSectiontitle to='engineering'>ENGINEERING</FooterSectiontitle>
                    <FooterHr marginRight={'10%'} />
                    <Footertitle onClick={() => { navigateToSection('engineering', 'engineering') }}>ENGINEERING</Footertitle>
                    <FooterText onClick={() => { navigateToSectionAndCard('engineering', 'engineering', ENGINEERING_CARD_TYPES.ENG_DATA) }}>No-Code Data Flows</FooterText>
                    <FooterText onClick={() => { navigateToSectionAndCard('engineering', 'engineering', ENGINEERING_CARD_TYPES.ENG_WORKFLOW) }}>No-Code Workflow Builds</FooterText>
                    <FooterText onClick={() => { navigateToSectionAndCard('engineering', 'engineering', ENGINEERING_CARD_TYPES.ENG_ENGINES) }}>High Performance Engines: C, Java, Python</FooterText>
                    <FooterText onClick={() => { navigateToSectionAndCard('engineering', 'engineering', ENGINEERING_CARD_TYPES.ENG_UI) }}>Interactive UX/UI Platforms</FooterText>
                    <FooterText onClick={() => { navigateToSectionAndCard('engineering', 'engineering', ENGINEERING_CARD_TYPES.ENG_DASHBOARD) }}>Real-Time Monitoring Dashboards</FooterText>
                </div>
                <div style={{ marginTop: '1.5vw' }}>
                    <Footertitle onClick={() => { navigateToSection('engineering', 'ai') }}>AI & MACHINE LEARNING</Footertitle>
                    <FooterText onClick={() => { navigateToSectionAndCard('engineering', 'ai', ENGINEERING_CARD_TYPES.AI_LOG) }}>Log Pattern Analysis</FooterText>
                    <FooterText onClick={() => { navigateToSectionAndCard('engineering', 'ai', ENGINEERING_CARD_TYPES.AI_BIG_DATA) }}>Big Data Analytics & Actionable Insights</FooterText>
                    <FooterText onClick={() => { navigateToSectionAndCard('engineering', 'ai', ENGINEERING_CARD_TYPES.AI_ADVANCED_DATA) }}>Advanced Data Crawlers & Smart Parsing</FooterText>
                </div>
                <div style={{ marginTop: '1.5vw' }}>
                    <Footertitle onClick={() => { navigateToSection('engineering', 'consult') }}>CONSULTING</Footertitle>
                    <FooterText onClick={() => { navigateToSectionAndCard('engineering', 'consult', ENGINEERING_CARD_TYPES.CONSULT_SECURITY) }}>Security by Design</FooterText>
                    <FooterText onClick={() => { navigateToSectionAndCard('engineering', 'consult', ENGINEERING_CARD_TYPES.CONSULT_PERFORMANCE) }}>Performance Assessment</FooterText>
                    <FooterText onClick={() => { navigateToSectionAndCard('engineering', 'consult', ENGINEERING_CARD_TYPES.CONSULT_CTO) }}>CTO as a Service</FooterText>
                    <FooterText onClick={() => { navigateToSectionAndCard('engineering', 'consult', ENGINEERING_CARD_TYPES.CONSULT_USER) }}>User Acceptance Tests</FooterText>
                    <FooterText onClick={() => { navigateToSectionAndCard('engineering', 'consult', ENGINEERING_CARD_TYPES.CONSULT_VIRTUAL) }}>Virtual Scrum Master</FooterText>
                    <FooterText onClick={() => { navigateToSectionAndCard('engineering', 'consult', ENGINEERING_CARD_TYPES.CONSULT_DIGITAL) }}>Digital Transformation</FooterText>
                </div>
                <div style={{ marginTop: '1.5vw' }}>
                    <Footertitle onClick={() => { navigateToSection('engineering', 'solution') }}>SOLUTIONS</Footertitle>
                    <FooterText onClick={() => { navigateToSectionAndCard('engineering', 'solution', ENGINEERING_CARD_TYPES.SOLUTIONS_SMARTMAIL) }}>SmartMail | Mass Mailing</FooterText>
                    <FooterText onClick={() => { navigateToSectionAndCard('engineering', 'solution', ENGINEERING_CARD_TYPES.SOLUTIONS_EOMP) }}>EOMP | Electoral Operation Management Platform</FooterText>
                    <FooterText onClick={() => { navigateToSectionAndCard('engineering', 'solution', ENGINEERING_CARD_TYPES.SOLUTIONS_ERP) }}>ERP/CRM Implementation</FooterText>
                    <FooterText onClick={() => { navigateToSectionAndCard('engineering', 'solution', ENGINEERING_CARD_TYPES.SOLUTIONS_SECURE) }}>Secure Private Hosting | SaaS/PaaS/IaaS</FooterText>
                </div>
            </FooterCol>
            <FooterCol marginRight={'8%'}>
                <div>
                    <FooterSectiontitle to='academy'>ACADEMY</FooterSectiontitle>
                    <FooterHr />
                    <Footertitle onClick={() => { navigateToSection('academy', 'training') }}>TRAINING</Footertitle>
                    <FooterText onClick={() => { navigateToSectionAndCard('academy', 'training', ACADEMY_CARD_TYPES.AC_PURPLE) }}>Purple Team</FooterText>
                    <FooterText onClick={() => { navigateToSectionAndCard('academy', 'training', ACADEMY_CARD_TYPES.AC_DIGITAL) }}>Digital Forensics and Incident Response</FooterText>
                    <FooterText onClick={() => { navigateToSectionAndCard('academy', 'training', ACADEMY_CARD_TYPES.AC_SOC) }}>SOC and SIEM Management</FooterText>
                    <FooterText onClick={() => { navigateToSectionAndCard('academy', 'training', ACADEMY_CARD_TYPES.AC_COMP) }}>Compliance and Strategy</FooterText>
                    <FooterText onClick={() => { navigateToSectionAndCard('academy', 'training', ACADEMY_CARD_TYPES.AC_MACHINE) }}>Machine Learning and Blockchain</FooterText>
                </div>
                <div style={{ marginTop: '1.5vw' }}>
                    {/* TRAINING PARTNERS */}
                    <Footertitle onClick={() => { navigateToSection('academy', 'pecb') }}>PECB TRAINING CERTIFICATIONS</Footertitle>
                    {/* <FooterText></FooterText> */}
                    <FooterText onClick={() => { navigateToSectionAndCard('academy', 'pecb', ACADEMY_CARD_TYPES.CERT_INFO) }}>Information Security</FooterText>
                    <FooterText onClick={() => { navigateToSectionAndCard('academy', 'pecb', ACADEMY_CARD_TYPES.CERT_CYBER) }}>Cybersecurity</FooterText>
                    <FooterText onClick={() => { navigateToSectionAndCard('academy', 'pecb', ACADEMY_CARD_TYPES.CERT_CONT) }}>Continuity, Resilience, Recovery</FooterText>
                    <FooterText onClick={() => { navigateToSectionAndCard('academy', 'pecb', ACADEMY_CARD_TYPES.CERT_GOV) }}>Governance, Risk and Compliance</FooterText>
                    {/* <FooterText style={{ textDecoration: 'underline' }}>About PECB</FooterText> */}
                </div>
                <div style={{ marginTop: '1.9vw' }}>
                    <FooterSectiontitle to='labs'>LABS</FooterSectiontitle>
                </div>
                <div style={{ marginTop: '1.9vw' }}>
                    <FooterSectiontitle to='resources'>RESOURCES</FooterSectiontitle>
                    <FooterHr />
                    <div>
                        <Footertitle onClick={() => { navigateToSection('resources', 'knowledge') }}>KNOWLEDGE BASE</Footertitle>
                        <FooterText>Research & Publications</FooterText>
                        <FooterText>Datasheets</FooterText>
                        {/* <FooterText>Blog Posts</FooterText> */}
                    </div>
                    {/* <div style={{ marginTop: '1.5vw' }}>
                        <Footertitle onClick={() => { navigateToSection('resources', 'media') }}>MEDIA</Footertitle>
                        <FooterText>News</FooterText>
                        <FooterText>Events</FooterText>
                    </div> */}
                </div>
            </FooterCol>
        </FooterRow>
        <FooterBottomRow>
            <FooterCol style={{ width: '100%' }}>
                <FooterBottomText>
                <FooterSectiontitle to="about-us">ABOUT US</FooterSectiontitle> | <FooterSectiontitle to="careers">CAREERS</FooterSectiontitle></FooterBottomText>
            <FooterHr style={{width: '100%'}} />

                <FooterBottomTextRow>
                    <FooterU to="privacy_notice">
                        PRIVACY NOTICE
                    </FooterU>
                    <FooterU to="cookie_policy">
                        COOKIE POLICY
                    </FooterU>
                </FooterBottomTextRow>
                <FooterBottomTextRow>
                    <u>Copyright © Potech and its group of companies.</u>
                </FooterBottomTextRow>
            </FooterCol>
        </FooterBottomRow>
    </Footer>
}

export default FooterComponent;