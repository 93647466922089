import { useRef } from 'react';
import arrowDown from '../../assets/logos/arrowDown.svg';
import arrowUp from '../../assets/logos/arrowUp.svg';
import { useState } from 'react';
import { AcademyCardMobileContainer, AcademyEyeIconMobile, CardBottom, ProductCol, ProductTitle } from './AcademyCardMobileElements';
import { browserName } from 'react-device-detect';

const AcademyCardMobile = (props) => {
    const topCard = useRef(null);
    const [isOpen, setOpen] = useState(null);

    return <AcademyCardMobileContainer onClick={props.clickHandler} active={props.active}>
        <AcademyEyeIconMobile ref={props.refA} src={props.logo} />
        <ProductCol>
            <ProductTitle>
                {props.title}
            </ProductTitle>
            {isOpen && props.items}
        </ProductCol>
        <CardBottom onClick={() => {
            if (isOpen) {
                if (browserName === "Chrome") {
                    const yOffset = -100; 
                    const y = props.refA?.current.getBoundingClientRect().top + window.scrollY + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                } else {
                    const yOffset = -100; 
                    const y = props.refA?.current.getBoundingClientRect().top + window.scrollY + yOffset;
                    window.scrollTo({top: y});
                }
            }
            setOpen(prev => !prev);
        }}>
            <img src={isOpen ? arrowUp : arrowDown} alt="React Logo" width={20} />
        </CardBottom>
    </AcademyCardMobileContainer>
}

export default AcademyCardMobile;