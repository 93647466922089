import styled from "styled-components";
import { NavLink as BaseNavLink } from "react-router-dom";

export const CartBody = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 0.8vw;
  width: 85%;
  height: 20vh;
  line-height: 150%;
  padding: 5%;
`;

export const Footer = styled.div`
/* Ocean Gradient */
  /* height: 140vh; */
  background: linear-gradient(90deg, #00ADBB 0%, #002F87 100%);
  color: white;
  padding-inline: 4%;
  padding-bottom: 8vh;
  /* font-family: 'Helvetica Light'; */
	font-family: "HelveticaLt", Arial, sans-serif;
`;

export const ContactUsButton = styled.div`
/* Ocean Gradient */
  border: 2px solid white;
  background: transparent;
  color: white;
  text-align: center;
  border-radius: 10px;
  padding: 0.6vh;
  font-size: 1vw;
  text-transform: uppercase;
  margin-bottom: 10%;
  cursor: pointer;
  @media screen and (max-width: 800px) {
    font-size: 20px;
  }
`;

export const FooterRow = styled.div`
  display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: start;
	align-content: stretch;
  padding-top: 40px;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    padding-top: unset;
    justify-content: center;
    align-items: center;
    width: 70vw;
    margin: auto;
  }
`;

export const FooterBottomTextRow = styled.div`
  display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: start;
	align-content: stretch;
  padding-top: 1%;
  font-size: 0.9vw;
  width: 100%;
  justify-content: center; 
  @media screen and (max-width: 800px) {
    flex-direction: column;
    padding-top: unset;
    /* justify-content: center;
    align-items: center; */
    margin: auto;
    font-size: 14px;
    text-align: center;
    align-items: center;
  }
`;

export const FooterHeadRow = styled.div`
  display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: start;
	align-content: stretch;
  padding-top: 9vh;
  margin-right: 6%;
  margin-bottom: 3%;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    margin-right: unset;
    margin-bottom: unset;
    justify-content: center;
    align-items: center;
    margin-bottom: 3vh;
  }
`;

export const FooterCol = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: start;
    align-content: start;
    margin-left: ${props => props.marginLeft ?? 'unset'};
    margin-right: ${props => props.marginRight ?? 'unset'};
    @media screen and (max-width: 800px) {
      margin-left: unset;
      margin-right: unset;
      width: 60vw;
    }
`;

export const ContactUsForm = styled.div`
  background: #FFFFFF;
  border-radius: 15px;
`;

export const FooterText = styled.div`
    color: white;
    text-decoration: none;
    display:block;
    font-size: 0.8vw;
    padding: 1%;
    padding-inline: 0%;
    font-weight: 100;
    cursor: pointer;
    @media screen and (max-width: 800px) {
        font-size: 14px;
    }
`;

export const ProductFooterLink =  styled(BaseNavLink)`
  font-size: 0.8vw;
  padding: 1%;
  padding-inline: 0%;
  font-weight: 100;
  color: white;
  text-decoration: none;
  cursor: pointer;
  @media screen and (max-width: 800px) {
    font-size: 14px;
  }
`;

export const FooterBottomText = styled.div`
  font-size: 1vw;
  padding: 1%;
  padding-inline: 0%;
  font-weight: 100;
  align-self: center;
  padding: 0.5%;
  cursor: pointer;
  @media screen and (max-width: 800px) {
    font-size: 14px;
  }
`;

export const Footertitle = styled.div`
  color: white;
  text-decoration: none;
  font-size: 0.8vw;
  font-weight: bold;
  margin-bottom: 5px;
  font-family: "HelveticaLt", Arial, sans-serif;
  cursor: pointer;
  @media screen and (max-width: 800px) {
    font-size: 15px;
    margin-top: 2vh;
  }
`;

export const FooterSectiontitle = styled(BaseNavLink)`
  font-size: 1vw;
  color: white;
  text-decoration: none;
  cursor: pointer;
  @media screen and (max-width: 800px) {
    font-size: 20px;
    margin-top: 5vh;
  }
`;

export const SocialLogo = styled.img`
  width: 2.5vw;
  padding: 1%;
  cursor: pointer;
  @media screen and (max-width: 800px) {
    width: 10vw;
  }
`;

export const SocialDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  @media screen and (min-width: 801px) {
    margin-top: 2vh;
  }
`;

export const FooterLogoImage = styled.img`
  width: 15vw;
  @media screen and (max-width: 800px) {
    width: 60vw;
    margin-bottom: 5vh;
    margin-top: 2vh;
  }
`;

export const ContactUsContainer = styled.div`
  text-align: center;
  width: 15vw;
  margin-left: 3%;
  @media screen and (max-width: 800px) {
    width: 60vw;
    margin-bottom: 5vh;
    margin-left: unset;
  }
`;

export const FooterHr = styled.hr`
  margin-right: ${props => props.marginRight ?? 'unset'};
  width: 15vw;
  margin-top: 1vh;
  margin-bottom: 2vh;
  color: white;
  border: none;
  border-top: 2px white solid;
  height: 2px;
  @media screen and (max-width: 800px) {
    width: 60vw;
    margin-left: 0px;
  }
`;

export const FooterBottomRow = styled.div`
  justify-content: center;
  width: 100%;
  padding-top: 0px;
  @media screen and (max-width: 800px) {
    width: 70vw;
    margin: auto;
    margin-top: 5vh;
  }
`;

export const FooterU = styled(BaseNavLink)`
  margin-right: 1%;
  color: white;
  cursor: pointer;
  @media screen and (max-width: 800px) {
    margin-right: unset;
    margin-bottom: 1vh;
    width: 100%;
    text-align: center;
  }
`;