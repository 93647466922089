import { useRef } from 'react';
import arrowDown from '../../assets/logos/arrowDown.svg';
import arrowUp from '../../assets/logos/arrowUp.svg';
import { useState } from 'react';
import { CardBottom, ManagedServicesCardMobileContainer, ManagedServicesEyeIconMobile, ProductCol, ProductTitle } from '../ManagedServicesCardMobile/ManagedServicesCardMobileElements';

const ManagedServicesCardMobile = (props) => {
    const topCard = useRef(null);
    const [isOpen, setOpen] = useState(false);

    return <ManagedServicesCardMobileContainer onClick={props.clickHandler} active={props.active}>
        <ManagedServicesEyeIconMobile ref={props.refA} src={props.logo} />
        <ProductCol>
            <ProductTitle>
                {props.title}
            </ProductTitle>
            {isOpen && props.items}
        </ProductCol>
        <CardBottom onClick={() => {
            if (isOpen)
                props.refA.current.scrollIntoView({ behavior: 'smooth' });
            setOpen(prev => !prev);
        }}>
            <img src={isOpen ? arrowUp : arrowDown} alt="React Logo" width={20} />
        </CardBottom>
    </ManagedServicesCardMobileContainer>
}

export default ManagedServicesCardMobile;