import styled from "styled-components";

export const FeatureCardMobileContainer = styled.div`
    border-radius: 12px;
    background: var(--white, #FFF);
    /* Drop Shadow Style */
    color: var(--academy-medium-orange, #E65300);
    text-align: center;
    /* Expandable Sub Title */
    font-style: normal;
    line-height: normal;
    /* width: 276px; */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 5vh;
    cursor: pointer;
    width: ${props => props.width ? props.width : '100%'};
    align-self: center;
    box-shadow: 15px 15px 30px 5px rgba(0, 0, 0, 0.15);
    padding-top: ${props => props.isOpen ? '0vh' : '5vh'};
    height: 100%;
    @media screen and (min-width: 801px ) {
        display: none;
    }
`;

export const ProductCol = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    margin-bottom: 4vh;
    margin-top: 1vh;
`;

export const ProductTitle = styled.div`
    /* width: 50vw; */
    padding-inline: 9vw;
    color: var(--Potech-Dark-Blue, #002F87);
    text-align: center;
    /* Resp-Sub-title */
	font-family: "HelveticaLt", Arial, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
`;

export const ProductDesc = styled.div`
    margin-top: 3vh;
    /* width: 45vw; */
    padding-inline: 16%;
    color: var(--Black, #000);
	text-align: center;
	font-family: "HelveticaLt", Arial, sans-serif;
	font-size: 4vw;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
`;

export const CardBottom = styled.div`
    height: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 100%;
    background-color: #f0f0f0;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    z-index: 0;
`;