import arrowDown from '../../assets/logos/arrowDown.svg';
import arrowUp from '../../assets/logos/arrowUp.svg';

import { ModuleBorderWrap, TrainingContainer } from './TrainingCardElements';

const TrainingCard = (props) => {
    return <ModuleBorderWrap active={props.active} isCertSection={props.isCertSection}>
        <TrainingContainer onClick={props.clickHandler} active={props.active}>
            <h3 style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', alignItems: 'center', marginTop: 0, marginBottom: 0, fontWeight: 'normal', fontSize: '1.3vw' }}>
                {props.text}
            </h3>
            <div style={{ height: 35, display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', alignItems: 'center', backgroundColor: '#f0f0f0', borderBottomLeftRadius: '13px', borderBottomRightRadius: '13px' }}>
                <img src={props.active ? arrowUp : arrowDown} alt="React Logo" width={20} />
            </div>
        </TrainingContainer>
    </ModuleBorderWrap>
}

export default TrainingCard;