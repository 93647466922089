import styled from "styled-components";

export const TrainingContainer = styled.div`
    border-radius: 12px;
    background: var(--white, #FFF);
    /* Drop Shadow Style */
    color: var(--academy-medium-orange, #E65300);
    text-align: center;
    /* Expandable Sub Title */
    font-size: 1.3vw;
    font-style: normal;
    line-height: normal;
    text-transform: uppercase;
    /* width: 276px; */
    height: ${(props) => props.active ? '30vh' : '26vh'};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    cursor: pointer;
`;

export const ModuleBorderWrap = styled.div`
    position: relative;
    background: ${props => props.active ? 'linear-gradient(to right, #9E3223, #E65300)' : 'none'} ;
    padding: ${props => props.active ? '3px' : '0px'};
    border-radius: 15px;
    /* Drop Shadow Style */
    box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.20);
    text-align: center;
    /* Expandable Sub Title */
    font-size: 1.5vw;
    font-style: normal;
    line-height: normal;
    text-transform: uppercase;
    /* width: 276px; */
    width: ${props => props.isCertSection ? '13vw' : '10.7vw'};
    height: ${(props) => props.active ? '26vh' : '22vh'};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    cursor: pointer;
`;