import styled from "styled-components";

export const FormDiv = styled.div`
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: start;
	align-content: start;

    background-color: white;
    border-radius: 15px;
    width: 37.7vw;
    height: 13vh;
    padding: 2vh;
    box-sizing: border-box;
    @media screen and (max-width: 800px) {
        width: 70vw;
        height: auto;
    }
`;

export const FormRow = styled.div`
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: start;
	align-content: stretch;
    color: #002F87;
    @media screen and (max-width: 800px) {
        flex-direction: column;
        align-items: center;
        margin: auto;
    }
`;

export const FormContainer = styled.div`
    width: 40vw;
    @media screen and (max-width: 800px) {
        width: unset;
    }
`;

export const FormContactBottomRow = styled.div`
	align-items: start;
	align-content: stretch;
    color: var(--Dark-Grey, #565656);
    font-size: 0.68vw;
    @media screen and (max-width: 800px) {
        flex-direction: column;
        font-size: 14px;
        padding: 3%;
        display: block;
    }
`;

export const SpanPolicy = styled.a`
    cursor: pointer;
    font-style: italic;
`;

export const SignUpButton = styled.button`
    /* Dark & Light Orange Gradient */

    background: linear-gradient(90deg, #F1AF5E 38.85%, #E88A0D 71.15%);
    background-blend-mode: multiply;
    /* Drop Shadow Style */
    border: none;
    box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.2);
    border-radius: 6px;

    color: white;
    padding: 0.3vw;
    padding-inline: 0.5vw;
    margin-right: 1vw;
    font-size: 0.7vw;
    width: 7vw;
    margin-left: 1vw;
    text-align: center;
    cursor: pointer;
    &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }
    &:active {
        opacity: 0.6;
    }
    @media screen and (max-width: 800px) {
        font-size: 14px;
        width: 100%;
        padding: 3%;
        margin: auto;
        margin-top: 3vh;
        margin-bottom: 1vh;
    }
`;

export const TextDesc = styled.div`
    font-size: 0.9vw;
    @media screen and (max-width: 800px) {
        font-size: 15px;
        margin-top: 3vh;
        margin-bottom: 3vh;
        text-align: center;
    }
`;