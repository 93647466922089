import { AdvisoryButton, FooterBackground, FooterTitle, PotechHistoryFooterAdvisory } from "../advisory/AdvisoryElement";
import { ChatIcon, ScrollTop } from "../home/HomeElements";
import { OctivoreBackground, OctivoreButton, OctivoreHeadLogo, OctivoreImg, OctivoreLogo, OctivoreTechBackground, OctivoreTechBody, OctivoreTechContainer, OctivoreTechDesc, OctivoreTechDiv, OctivoreTechHead, OctivoreTechLogo, OctivoreTechTitle, OctivoreText, OctivoreTextTitle, OctivoreTitle } from "./OctivoreElements";

import scrollTopIcon from "../../assets/images/ScrolltoTopIcon.png";
import chatIcon from "../../assets/images/chaticon.png";
import OctivoreWhiteLogo from "../../assets/logos/OctivoreWhiteLogo.png";
import { DarkivoreFooterImage, DarkivoreFooterNewLine, DarkivoreHistoryFooter, FooterDarkivoreBackground, FooterDarkivoreTitle, HuntingBackground, HuntingHead, HuntingHeadLogo, HuntingText, HuntingTitle, HuntingeBody } from "../darkivore/DarkivoreElements";
import sirpTech from '../../assets/logos/sirpTech.png'
import octiLogo1 from '../../assets/logos/octiLogo1.png'
import octiLogo2 from '../../assets/logos/octiLogo2.png'
import octiLogo3 from '../../assets/logos/octiLogo3.png'

import thidesoftLogo from '../../assets/logos/Thidesoft Logo Horizontal White.png';
import FooterDivider from "../../components/FooterDivider";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

const Octivore = ({ isScrolled, setIsBlack, setIsProductactive }) => {

    const targetSection = useSelector((state) => state.scroll.targetSection);

    useEffect(() => {
        setIsBlack(true);
        setIsProductactive(true);
        if (targetSection === null) {
            window.scrollTo(0, 0);
        }
        return () => {
            setIsBlack(false);
            setIsProductactive(false);
        }
    }, [])
    const discoverJump = useRef(null);

    return <>
        <Helmet>
            <title>Octivore: The Latest in SIRP Technology</title>
            <meta name="description" content="A scalable platform with OMNI-reach to handle a barrage of security incidents needing immediate attention." />
        </Helmet>
        <OctivoreBackground>
            <OctivoreImg />
            <OctivoreTitle>
                <OctivoreLogo src={OctivoreWhiteLogo} />
                <OctivoreTextTitle>
                    SECURITY INCIDENT RESPONSE PLATFORM
                </OctivoreTextTitle>
                <OctivoreButton onClick={() => { discoverJump.current.scrollIntoView({ behavior: 'smooth' }) }} >DISCOVER</OctivoreButton>
                <ScrollTop isScrolled={isScrolled} src={scrollTopIcon} onClick={() => { window.scrollTo(0, 0) }} />
                <ChatIcon src={chatIcon} />
            </OctivoreTitle>
        </OctivoreBackground>
        <OctivoreTechBackground ref={discoverJump}>
            <OctivoreImg />
            <OctivoreTechTitle>
                <OctivoreTechBody>
                    <OctivoreHeadLogo src={sirpTech} />
                    <OctivoreTechHead>
                        the latest in SIRP technology
                    </OctivoreTechHead>
                    <OctivoreText>
                        A scalable platform with the OMNI-reach needed to deal with a barrage of security incidents that require immediate attention.
                    </OctivoreText>

                    <OctivoreTechContainer>
                        <OctivoreTechDiv>
                            <OctivoreTechLogo src={octiLogo1} />
                            <OctivoreTechDesc>
                                Alerts Aggregation from Various<br /> Threat Feeds
                            </OctivoreTechDesc>
                        </OctivoreTechDiv>
                        <OctivoreTechDiv>
                            <OctivoreTechLogo src={octiLogo2} />
                            <OctivoreTechDesc>
                                Support for any SIEM
                            </OctivoreTechDesc>
                        </OctivoreTechDiv>
                        <OctivoreTechDiv>
                            <OctivoreTechLogo src={octiLogo3} />
                            <OctivoreTechDesc>
                                Centralized Ticket Management
                            </OctivoreTechDesc>
                        </OctivoreTechDiv>
                    </OctivoreTechContainer>
                </OctivoreTechBody>
            </OctivoreTechTitle>
        </OctivoreTechBackground>
        <FooterDarkivoreBackground>
            <FooterDarkivoreTitle>
                <DarkivoreHistoryFooter>
                    <DarkivoreFooterImage src={thidesoftLogo} />
                    <DarkivoreFooterNewLine>Member of Potech group, Thidesoft is a Cybersecurity Technology Vendor headquartered in Paris - France, with the mission to provide an arsenal of 360° cyber defense tools.</DarkivoreFooterNewLine>
                    <DarkivoreFooterNewLine>Among others, Thidesoft offers TACIVOAR, DARKIVORE and OCTIVORE, three engineering masterpieces that harness AI, Machine Learning, and cutting-edge technology to protect internal & cloud assets, hunt the web for potential threats targeted at businesses, and respond to security incidents.</DarkivoreFooterNewLine>
                </DarkivoreHistoryFooter>
            </FooterDarkivoreTitle>
        </FooterDarkivoreBackground>
        <FooterDivider />
    </>
}

export default Octivore;