import React, { useState } from 'react';
import { InfoDiv, InfoSpan } from './InfoElements';
import infoLogoIcon from '../../assets/logos/infoLogo.svg';

const HoverIcon = ({ tooltip, text }) => {
    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            <span>
                {text}
            </span>
            <span style={{ position: 'relative', marginLeft: '0.5vw' }}>
                <InfoDiv
                    src={infoLogoIcon} alt=""
                />
                <InfoSpan isHovered={isHovered}>{tooltip}</InfoSpan>
            </span>
        </div>
    );
};

export default HoverIcon;