import { useState } from "react";
import { FormContactBottomRow, FormContainer, FormDiv, FormRow, SignUpButton, SpanPolicy, TextDesc } from "./EmailFormElements";
import { Input } from '@mui/material';
import CaptchaDialog from '../../common/captchaDialoge'
import ThanksDialog from "../../common/thanksDialog";
import agent from "../../utils";
import { toast } from "react-toastify";
const EmailForm = ({ setReshowDialog }) => {
    const [email, setEmail] = useState('');
    const [captchaOpen, setCaptchaOpen] = useState(false);
    const [thanksOpen, setThanksOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [captchaKey, setCaptchaKey] = useState(null);

    var emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const signUpNewsLetter = () => {
        setLoading(true);
        agent.News.postEmail({
            "email": email,
            "g-recaptcha-response": captchaKey
        })
        .then(() => {
            setThanksOpen(true);
        })
        .catch((err) => {
            if(err?.response?.status === 400) {
                toast.error("Bad Request");
            }
            if(err?.response?.status === 500) {
                toast.error("Internal Server Error");
            }
        })
        .finally(() => {
            setLoading(false);
            setCaptchaOpen(false);
        })
    }

    const handleSubmit = () => {
        if (localStorage.getItem('cookie') !== '1')
            setReshowDialog(true);
        else {
            setCaptchaOpen(true);
        }
    }
    var tooltipButtonMessage = "";
    const isEmailValid = emailRegex.test(email);

    if (!isEmailValid) {
        tooltipButtonMessage += " Email invalid "
    }

    const message = <>
        Now You’ll Receive the Latest News and Security Updates! <br />For More, You Can Follow Us on Social Media too:
    </>
    return <FormContainer>
        <CaptchaDialog
            setCaptchaKey={setCaptchaKey} 
            loading={loading} 
            open={captchaOpen}
            setOpen={setCaptchaOpen} 
            submitHandler={signUpNewsLetter} 
            btnText={'SIGN UP'} 
        />
        <ThanksDialog open={thanksOpen} setOpen={setThanksOpen} title={'Thank You For Signing Up!'} message={message} />
        <FormDiv>
            <FormRow className="contact">
                <TextDesc>RECEIVE THE LATEST NEWS AND SECURITY UPDATES</TextDesc>
                <Input placeholder="Your Email" label="Your Email" variant="standard"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    sx={{
                        '& label.Mui-focused': {
                            color: 'black',
                        },
                        '& .MuiInput-underline:after': {
                            borderBottomColor: '#002F87',
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#002F87',
                            },
                            '&:hover fieldset': {
                                borderColor: '#002F87',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#002F87',
                            },
                        },
                        '& .MuiInputBase-input': {
                            width: '14vw',
                            marginTop: '-7px',
                            fontSize: '0.8vw'
                        }
                    }} />
                <SignUpButton
                    title={tooltipButtonMessage}
                    disabled={email === '' || !isEmailValid}
                    onClick={handleSubmit}
                >SIGN UP</SignUpButton>
            </FormRow>
            <FormContactBottomRow>By clicking on “I agree”, you hereby consent to the processing of your personal data provided through this website and declare your agreement to our <SpanPolicy target="_blank" href="/privacy_notice">Privacy Policy</SpanPolicy> available on our site.</FormContactBottomRow>
        </FormDiv>
    </FormContainer>
}

export default EmailForm;