import styled from "styled-components";

export const TacivoarRow = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
    justify-content: space-evenly;
	align-items: center;
    width: 43%;
	align-content: stretch;
	@media screen and (max-width: 800px) {
		flex-direction: column;
		width: 80%;
	}
`;