import styled from "styled-components";
import TacivoarBack from '../../assets/images/TacivoarBack.png';
import SiemSolBack from '../../assets/images/SiemSolBack.png';
import problemBack from '../../assets/images/problemBack.png';
import advancedBack from '../../assets/images/AdvancedBack.png';
import NumBack from '../../assets/images/TaceNumBack.jpg';
import TaceFeatureBack from '../../assets/images/TaceFeatureBack.png';

export const TacivoarBackground = styled.nav`
  width: 100%;
  height: 117vh;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  @media screen and (max-width: 800px) {
    height: 110vh;
  }
`;

export const TacivoarNumBackground = styled.nav`
  width: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 800px) {
    height: auto;
    display: block;
    align-items: unset;
    justify-content: unset;
  }
`;

export const TacivoarFeatureBackground = styled.nav`
  width: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 120vh;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 800px) {
    height: auto;
    display: block;
    align-items: unset;
    justify-content: unset;
  }
`;

export const TacivoarImg = styled.div`
  width: 100%;
  height: 120.5vh;
  position: absolute;
  background-image: url(${TacivoarBack});
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
`;

export const TacivoarTitle = styled.div`
  color: white;
  height: 110vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  /* font-family: 'Helvetica Light'; */
	font-family: "HelveticaLt", Arial, sans-serif;
  /* @media screen and (max-width: 800px) {
    height: 110vh;
  } */
`;

export const TacivoarFeatureTitle = styled.div`
  color: white;
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  /* font-family: 'Helvetica Light'; */
  font-family: "HelveticaLt", Arial, sans-serif;
  @media screen and (max-width: 800px) {
    height: auto;
  }
`;

export const TacivoarFeatureHeadText = styled.h2`
  font-size: 1.8vw;
  margin-bottom: 3vh;
  margin-top: 0;
  font-weight: normal;
  @media screen and (max-width: 800px) {
    height: auto;
    font-size: 20px;
    margin-top: 10vh;
    margin-bottom: 0vh;
  }
`;

export const TacivoarNumTitle = styled.div`
  color: white;
  height: 80vh;
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  /* font-family: 'Helvetica Light'; */
  font-family: "HelveticaLt", Arial, sans-serif;
  @media screen and (max-width: 800px) {
    height: auto;
  }
`;

export const TacivoarNumHeadText = styled.h1`
  font-size: 1.8vw;
  margin-top: 0;
  font-weight: normal;
  margin-bottom: 0;
  @media screen and (max-width: 800px) {
    height: auto;
    font-size: 20px;
    margin-top: 10vh;
  }
`;

export const TacivoarNumContainer = styled.div`
  width: 60vw;
  justify-content: space-between;
  display: flex;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const SwiperTaceMobile = styled.div`
  margin-top: 5vh;
  position: relative;
  @media screen and (min-width: 801px){
    display: none;
  }
`;

export const TacivoarAdvantageBackground = styled.nav`
  width: 100%;
  height: ${props => props.height ?? '180vh'};
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  @media screen and (max-width: 800px) {
    height: auto;
  }
`;

export const TacivoarAdvantageTitle = styled.nav`
  color: white;
  height: ${props => props.height ?? '180vh'};
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  /* font-family: 'Helvetica Light'; */
  font-family: "HelveticaLt", Arial, sans-serif;
  @media screen and (max-width: 800px) {
    height: auto;
  }
`;

export const TacivoarBodyBackground = styled.nav`
  width: 100%;
  height: ${props => props.height ?? '120vh'};
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  @media screen and (max-width: 800px) {
    height: auto;
  }
`;

export const TacivoarBodyTitle = styled.div`
  color: white;
  height: ${props => props.height ?? '120vh'};
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  /* font-family: 'Helvetica Light'; */
  font-family: "HelveticaLt", Arial, sans-serif;
  @media screen and (max-width: 800px) {
    height: auto;
  }
`;

export const TacivoarLogo = styled.img`
  width: 10vw;
  margin-top: 3vh;
  @media screen and (max-width: 800px) {
    width: 110px;
  }
`;

export const TacivoarTextTitle = styled.h1`
  margin-top: 5vh;
  font-size: 1.5vw;
  font-weight: 400;
  margin-bottom: 5vh;
  text-align: center;
  @media screen and (max-width: 800px) {
    font-size: 20px;
    padding-inline: 10vw;
    margin-bottom: 2vh;
    line-height: 140%;
    margin-top: 6vh;
  }
`;

export const TacivoarButton = styled.a`
text-decoration: none;
border-radius: 5px;
background-blend-mode: multiply;
/* Drop Shadow Style */
cursor: pointer;
box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.20);
text-align: center;
font-weight: 400;
width: ${props => props.width ?? '7vw'};
padding: ${props => props.padding ?? '0.5%'};
font-size: 1vw;
font-family: 'Helvetica';
background: white;
color: #1F3B71;
@media screen and (max-width: 800px) {
  width: auto;
    font-size: 14px;
    padding: 2%;
    padding-inline: 5%;
    margin-top: 6vh;
  }
`;

export const SiemBody = styled.div`
  position: relative;
  color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  background-color: white;
  width: 75vw;
  height: 95vh;
  margin-top: 0vh;
  margin: auto;
  color: var(--darkivore-dark-blue, #1F3B71);
  border-radius: 15px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  @media screen and (max-width: 800px) {
    height: auto;
    width: 80vw;
    margin-top: 20vh;
    margin-bottom: 10vh;
  }
`;

export const SiemHead = styled.h2`
  color: var(--darkivore-dark-blue, #1F3B71);
  text-align: center;
  /* Heading H1 */
  font-weight: normal;
  font-size: 1.4vw;
  text-transform: uppercase;
  margin-top: 17vh;
  font-family: "HelveticaLt", Arial, sans-serif;
  z-index: 1;
  @media screen and (max-width: 800px) {
    font-size: 20px;
    margin-top: 18vh;
    margin-bottom: 5vh;
  }
`;

export const TacivorDemoContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 61vw;
  justify-content: space-between;
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

export const TacivoarDemoImage = styled.img`
  width: 23vw;
  margin-right: 20px;
  z-index: 1;
  @media screen and (max-width: 800px) {
    margin-right: unset;
    width: 100%;
    min-height: 30vh;
  }
`;

export const TacivorDemoDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-content: start;
  @media screen and (max-width: 800px) {
    margin-bottom: 5vh;
  }
`;

export const TacivoreDemoHead = styled.h3`
  color: var(--darkivore-dark-blue, #1F3B71);
  text-align: start;
  font-weight: normal;
  /* Heading H1 */
  font-size: 1.4vw;
  margin-top: 0vh;
  margin-left: 0.8vw;
  font-family: Arial, Helvetica, sans-serif;
  z-index: 1;
  @media screen and (max-width: 800px) {
    font-size: 20px;
    font-family: "HelveticaLt", Arial, sans-serif;
    text-align: start;
    margin-top: 5vh;
  }
`;

export const TacivoarProblemHead = styled.h1`
  color: var(--darkivore-dark-blue, #1F3B71);
  text-align: center;
  /* Heading H1 */
  font-size: 1.8vw;
  text-transform: uppercase;
  margin-bottom: 0;
  font-weight: normal;
  margin-top: 5vh;
  font-family: Arial, Helvetica, sans-serif;
  z-index: 1;
  @media screen and (max-width: 800px) {
    font-size: 20px;
    font-family: "HelveticaLt", Arial, sans-serif;
    margin-top: 12vh;
  }
`;

export const TacivoarDivHeaderItemsMobile = styled.div`

  color: ${props => props.color ?? 'var(--Consulting-Light-Red, #E20D1A)'};
  /* Resp-Title Copy-Internal Page */
  font-family: "HelveticaLt", Arial, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-bottom: 1px #CECECE solid;
  text-align: center;
  padding: 7%;
  padding-top: 20%;
  padding-inline: 9%;
`;

export const TacivoarDivBodyItemsMobile = styled.div`

  color: var(--Black, #000);
  /* Resp-Body */
  font-family: "HelveticaLt", Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
  padding-top: 2vh;
  padding-inline: 9%;
  padding-bottom: 2vh;
  text-align: center;
`;

export const TacivoarTextBody = styled.div`
  font-size: 1.3vw;
  font-weight: 400;
`;

export const SolImg = styled.div`
  width: 100%;
  height: 170.5vh;
  position: absolute;
  background-image: url(${SiemSolBack});
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  z-index: -1;
  @media screen and (max-width: 800px) {
    height: 100.5%;
  }
`;

export const ProblemImg = styled.div`
  width: 100vw;
  height: 100.5%;
  position: absolute;
  background-image: url(${problemBack});
  color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
  @media screen and (max-width: 800px) {
    height: 100.5%;
  }
`;

export const ProblemBody = styled.div`
  position: relative;
  color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  background-color: white;
  height: 80vh;
  width: 75vw;
  color: var(--darkivore-dark-blue, #1F3B71);
  border-radius: 15px;
  margin-top: 7vh;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  @media screen and (max-width: 800px) {
    margin-top: 20vh;
    height: auto;
    margin-bottom: 10vh;
  }
`;

export const TacivoarAdvantageBody = styled.div`
  position: relative;
  color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  background-color: white;
  height: ${props => props.height};
  width: 75vw;
  color: var(--darkivore-dark-blue, #1F3B71);
  border-radius: 15px;
  margin-top: 7vh;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  @media screen and (max-width: 800px) {
    margin-top: 22vh;
    height: auto;
    margin-bottom: 5vh;
  }
`;

export const TacivoarAdvantageContainerDesk = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 90%;
`;

export const TacivoarAdvantageBottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 75vw;
  margin-top: 5vh;
  @media screen and (max-width: 800px) {
   flex-direction: column;
   width: 100%;
   text-align: center;
  }
`;

export const TacivoarAdvantageDemoImage = styled.img`
  width: 35vw;
  margin-right: 50px;
  min-height: 40vh;
  z-index: 1;
  @media screen and (max-width: 800px) {
    margin-right: unset;
    align-self: center;
    width: 76%;
  }
`;

export const TacivoarAdvantageDemoText = styled.div`
  color: white;
  font-size: 2vw;
  font-family: Helvetica;
  @media screen and (max-width: 800px) {
    font-size: 18px;
    width: 76%;
    margin: auto;
    margin-top: 5vh;
    margin-bottom: 10vh;
  }
`;

export const TacivoarAdvantageHead = styled.h2`
  color: var(--darkivore-dark-blue, #1F3B71);
  text-align: center;
  /* Heading H1 */
  font-size: 1.5vw;
  text-transform: uppercase;
  margin-top: ${props => props.marginTop};
  margin-bottom: 0;
  font-weight: normal;
  font-family: "HelveticaLt", Arial, sans-serif;
  z-index: 1;
  @media screen and (max-width: 800px) {
    font-size: 20px;
    font-family: "HelveticaLt", Arial, sans-serif;
    margin-top: 12vh;
    margin-bottom: 2vh;
  }
`;

export const AdvantageDivItemsMobile = styled.div`

  border-radius: 15px;
  background: var(--White, #FFF);
  margin: auto;
  position: relative;
  /* Drop Shadow Style */
  box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.20);
  width: 80%;
  margin-top: 10vh;
`;

export const ProblemContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 62vw;
  justify-content: space-between;
  font-family: "HelveticaLt", Arial, sans-serif;
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

export const ProblemContainerTextHead = styled.div`
  width: 38vw;
  font-size: 0.95vw;
  color: black;
  font-family: "HelveticaLt", Arial, sans-serif;
  @media screen and (max-width: 800px) {
    font-size: 18px;
    width: 100%;
    text-align: center;
    margin-top: 5vh;
  }
`;

export const ProblemContainerTextBody = styled.div`
  width: 23vw;
  color: var(--darkivore-dark-blue, #1F3B71);
  font-size: 1.25vw;
  line-height: 130%;
  @media screen and (max-width: 800px) {
    font-size: 18px;
    width: 100%;
    margin-top: 5vh;
    text-align: center;
  }
`;

export const ProblemContainerTextBottom = styled.div`
  width: 62vw;
  font-size: 0.95vw;
  color: black;
  font-family: "HelveticaLt", Arial, sans-serif;
  @media screen and (max-width: 800px) {
    font-size: 18px;
    width: 80%;
    text-align: center;
    margin-top: 5vh;
  }
`;

export const ProblemDiv = styled.div`
    width: 58vw;
    border-radius: 15px;
    background: var(--white, #FFF);
    /* Testimonials Box Shadow */
    box-shadow: 15px 15px 30px 5px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: var(--darkivore-dark-blue, #1F3B71);
    text-align: right;
    font-size: 1.3vw;
    padding: 3%;
    margin-bottom: 2vh;
    @media screen and (max-width: 800px) {
      /* margin-top: 5vh; */
      margin-bottom: 5vh;
      flex-direction: column;
      font-size: 18px;
    }
`;

export const Desktop = styled.div`
    @media screen and (max-width: 800px) {
      display: none;
    }
`;

export const Mobile = styled.div`
    @media screen and (min-width: 801px) {
      display: none;
    }
`;

export const ProblemDivSection1 = styled.div`
  width: 28%;
  text-align: end;
  margin-right: 10px;
  margin-left: 10%;
  @media screen and (max-width: 800px) {
    width: 100%;
    text-align: start;
    margin-right: unset;
    margin-left: unset;
  }
`;

export const ProblemDivSection2 = styled.div`
  width: 38%;
  text-align: start;
  margin-left: 10px;
  @media screen and (max-width: 800px) {
    width: 100%;
    margin-left: unset;
  }
`;

export const AdvancedImg = styled.div`
  width: 100vw;
  height: 100.5%;
  position: absolute;
  background-image: url(${advancedBack});
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  z-index: -1;
  @media screen and (max-width: 800px) {
    width: 101vw;
    height: 100.5%;
  }
`;

export const NumImg = styled.div`
  width: 100%;
  height: 100.5%;
  position: absolute;
  background-image: url(${NumBack});
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
  @media screen and (max-width: 800px) {
    height: 100.5%;
  }
`;

export const TaceNumDiv = styled.div`
  position: relative;
  border-radius: 15px;
  background: var(--white, #FFF);
  width: 14vw;
  height: 18vh;
  /* Testimonials Box Shadow */
  box-shadow: 15px 15px 30px 5px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  padding-top: 6.5vh;
  box-sizing: border-box;
  @media screen and (max-width: 800px) {
    box-shadow: none;
    width: 100%;
  }
`;

export const TaceNumLogo = styled.img`
  position: absolute;
  left: 50%;
  width: 120px;
  transform: translateX(-50%);
  top: -60px;
  @media screen and (max-width: 1600px) {
    left: 50%;
    width: 100px;
    transform: translateX(-50%);
    top: -45px;
  }
`;

export const TaceFeatureLogo = styled.img`
  position: absolute;
  left: 50%;
  width: 100px;
  transform: translateX(-50%);
  top: -50px;
  @media screen and (max-width: 1600px) {
    left: 50%;
    width: 80px;
    transform: translateX(-50%);
    top: -40px;
  }
  @media screen and (max-width: 800px) {
    left: 50%;
    width: 70px;
    transform: translateX(-50%);
    top: -35px;
  }
`;

export const TaceNum = styled.div`
  text-align: center;
  font-family: Helvetica;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  background: var(--tacivoar-gradient, linear-gradient(311deg, #8EC0E8 0%, #2D2151 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 2%;
  @media screen and (max-width: 800px) {
    font-size: 20px;
  }
`;

export const TaceNumDesc = styled.h3`
  color: var(--potech-dark-blue, #002F87);
  text-align: center;
  /* Body 02 */
  margin-top: 0;
  margin-bottom: 0;
  font-family: "HelveticaLt", Arial, sans-serif;
  font-size: 0.9vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media screen and (max-width: 800px) {
    font-size: 14px;
  }
`;

export const TaceNumBottomDiv = styled.div`
  background: linear-gradient(311deg, #8EC0E8 0%, #2D2151 100%);
  filter: drop-shadow(15px 15px 30px rgba(0, 0, 0, 0.15));
  width: 60vw;
  border-radius: 12px;
  box-sizing: border-box;
  padding-inline: 1.5vw;
  @media screen and (max-width: 800px) {
    width: 80vw;
    margin-bottom: 5vh;
  }
`;

export const TaceNumBottomText = styled.pre`
  color: var(--white, #fff);
  font-family: "HelveticaLt", Arial, sans-serif;
  font-size: 1vw;
  margin: 0;
  line-height: 150%;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const TaceNumBottomTextMobile = styled.div`
  color: var(--white, #fff);
  font-family: "HelveticaLt", Arial, sans-serif;
  font-size: 14px;
  padding: 5%;
  @media screen and (min-width: 801px) {
    display: none;
  }
`;


export const TaceFeatureImg = styled.div`
  width: 100%;
  height: 100.5%;
  position: absolute;
  background-image: url(${TaceFeatureBack});
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  z-index: -1;
`;

export const FeatureCard = styled.div`
  border-radius: 17px;
  background: var(--white, #FFF);

  /* Testimonials Box Shadow */
  box-shadow: 15px 15px 30px 5px rgba(0, 0, 0, 0.15);
  width: 24vw;
  height: 29vh;
  position: relative;
  @media screen and (max-width: 800px) {
    width: 100%;
    box-shadow: none;
    height: 35vh;
  }
`;

export const FeatureRow = styled.div`
  width: 75vw;
  display: flex;
  justify-content: space-between;
  margin-top: 10vh;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const FeatureTitle = styled.h3`
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background: var(--tacivoar-gradient, linear-gradient(311deg, #2D2151 0%, #8EC0E8 100%));
  margin-top: 0;
  margin-bottom: 0;
  font-weight: normal;
  color: white;
  font-family: Helvetica;
  font-size: 1.2vw;
  padding: 3%;
  height: 8.5vh;
  align-items: end;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 800px) {
    font-size: 18px;
  }
`;

export const FeatureDesc = styled.div`
  color: var(--potech-dark-blue, #002F87);
  /* Body 3 */
  font-family: Helvetica;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  margin-top: 1vh;
  @media screen and (max-width: 800px) {
    font-size: 13px;
    /* margin-top: unset; */
    /* padding: 0%; */
  }

  @media screen and (max-height: 640px) {
    font-size: 13px;
  }
`;

export const UlTaceSwiper = styled.ul`
  color: black;
  font-size: 1vw;
  text-align: start;
  width: 35vw;
  margin-top: ${props => props.marginTop ?? ''};
  @media screen and (max-width: 800px) {
    font-family: "HelveticaLt", Arial, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 142.857%;
    padding-left: 9%;
    box-sizing: border-box;
    width: 100%;
  }
`;